class Auth {
  constructor() {
    this.authenticated = false;
  }

  login(cb) {
    this.authenticated = true;
    cb();
  }

  logout(cb) {
    this.authenticated = false;
    cb();
  }

  isAuthenticated() {
    if (JSON.parse(sessionStorage.getItem("AuthData"))) {
      this.authenticated = true;
    } else {
      this.authenticated = false;
    }
    return this.authenticated;
  }

  getUserID() {
    return JSON.parse(sessionStorage.getItem("userDataSet")).id;
  }

  getUserData() {
    return JSON.parse(sessionStorage.getItem("userDataSet"));
  }
}

export default new Auth();
