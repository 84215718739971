import Button from "@material-ui/core/Button";
import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { GetData } from "../../ApiService/GetData";
import imgIcon from "../../Assets/img/crediti.png";
import { apis } from "../../properties";

let packId = 0;
function BuyCredits() {
  // const history = useHistory();
  const { addToast } = useToasts();

  const [selectedPack, setSelectedPack] = useState({});
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    getCreditsPacks();
    // eslint-disable-next-line
  }, []);

  const getCreditsPacks = () => {
    GetData(apis.GET_CREDITS_PACKS).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        setPackages(responseJson.data);
      } else {
        addToast(responseJson.message, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    });
  };

  const selectPackage = (data) => {
    packId = data.id;
    setSelectedPack(data);
  };

  const buyCredits = () => {
    if (packId === 0) {
      addToast("Please select the any credits package and try again!", {
        appearance: "error",
        autoDismiss: true,
      });
    } else {
      window.location.href = `${apis.GET_PACKAGE_BEFORE}?amount=${selectedPack.netprice}&pay_type=cleaninger`;
    }
  };

  return (
    <div>
      <div>
        <p className="small-txt">
          Buy our coins packages and get more coins for free!
          <br />
          coins can be used anytime, for any purchase on cleaninger.in
        </p>
      </div>
      <div className="credit-title">
        <div className="credit-icon">
          <img src={imgIcon} alt="icon" />
        </div>
        <div className="credit-txt">
          <p>COINS PACKAGES</p>
        </div>
      </div>
      <div className="credit-list">
        <ul>
          {packages.map((option) => (
            <li
              className={
                packId === option.id ? "credit-box active-style" : "credit-box"
              }
              key={option.id}
              onClick={() => selectPackage(option)}
            >
              <div className="name">
                <p>{option.packs} Coins</p>
              </div>
              <div className="options">
                <p>{option.option_txt === "" ? "" : option.option_txt}</p>
              </div>
              <div className="discount">
                {option.discount === 0 ? (
                  ""
                ) : (
                  <p className="discounts">{option.discount}% DISCOUNT</p>
                )}
              </div>
              <div className="price">
                {option.price === 0 ? (
                  ""
                ) : (
                  <span>
                    <del>Rs {option.price.toFixed(2)}</del>
                  </span>
                )}
                <p>Rs {option.netprice.toFixed(2)}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="credit-btn">
        <Button
          variant="contained"
          className="primary__btn"
          color="secondary"
          onClick={buyCredits}
        >
          BUY COINS
        </Button>
      </div>
    </div>
  );
}

export default BuyCredits;
