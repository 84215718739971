/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from "react";
import "./../Assets/css/Comman.css";
import "./../Assets/css/Results.css";
import Filter from "../Header/Filter";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import Slider from "react-slick";
import { apis, BASE_S3_URL, sliderSettings } from "../properties";
import { GetData } from "../ApiService/GetData";
import InboxIcon from "@material-ui/icons/Inbox";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import HomeIcon from "@material-ui/icons/Home";
import Spinner from "../Spinner";
import FooterText from "../Components/FooterText";
import Helmet from "react-helmet";

let regex = /<[^>]+>/g;

function Results(props) {
  console.log(props);
  let text = "";

  let categorySlug = props.match.params.category;
  let regionSlug = props.match.params.region;
  let citySlug = props.match.params.city;

  const [filteredDate, setFilteredDate] = useState([]);
  const [pagination, setPagination] = useState({});
  const [otherData, setOtherData] = useState({});
  const [rowData, setRowData] = useState({});
  const [spinner, setSpinner] = useState(true);
  const seoData = {
    title: "Best Deep Cleaning Services Near Me - cleaninger.in",
    description:
      "Cleaninger offers you to post home cleaning, office cleaning, pest control services ads. Get contact details of Cleaning Service companies in all over india.",
    url: window.location.href,
    keywords:
      "house cleaning services near me,deep cleaning services near me, cleaning services near me, bathroom cleaning services, sanitization services near me, sofa cleaning services near me, bathroom cleaning services near me, office cleaning services near me, pest control services near me, cockroach pest control service",
  };

  useEffect(() => {
    getResultData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData]);

  const getResultData = (pageNumber) => {
    let getURL = "";
    if (pageNumber) {
      getURL = `${apis.GET_RESULT_DATA}?category=${categorySlug}&region=${regionSlug}&city=${citySlug}&text=${text}&page=${pageNumber}`;
    } else {
      getURL = `${apis.GET_RESULT_DATA}?category=${categorySlug}&region=${regionSlug}&city=${citySlug}&text=${text}`;
    }
    GetData(getURL).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        setFilteredDate(responseJson.data.DataSet);
        setPagination(responseJson.data.Pagi);
        setOtherData(responseJson.data.other);
        setSpinner(false);
      } else {
      }
    });
  };

  const renderTop = () => {
    let emptyy = {
      fulName: "bhanuka",
    };
    setRowData(emptyy);
  };

  function convertToSlug(Text) {
    return Text.toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  }

  const handlePageChange = (pageNumber) => {
    window.scrollTo(0, 0);
    getResultData(pageNumber);
  };

  return (
    <div>
      {seoData.title && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>{seoData.title}</title>
          <meta name="description" content={seoData.description} />
          <meta name="keywords" content={seoData.keywords} />
          <meta property="og:title" content={seoData.title} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={seoData.url} />
          <link rel="canonical" href={seoData.url} />
        </Helmet>
      )}

      <Spinner spinner={spinner} />
      <div className="Home_Banner">
        <p>Best Cleaning Services in your city</p>
        <span>
          Verified Experts | Pocket-Friendly Rates | 100% Safe & Secure
        </span>
      </div>
      <div className="mb-none">
        <Filter />
      </div>
      <div className="bread-rwo container">
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/" className="bread_tag">
            <HomeIcon className="bread_tag_icon" />
          </Link>
          {categorySlug && (
            <Link
              to={{
                pathname: `/ads/${categorySlug}`,
              }}
              className="bread_tag"
            >
              <span onClick={renderTop}>
                {otherData.category && otherData.category.name}
              </span>
            </Link>
          )}
          {regionSlug && (
            <Link
              to={{
                pathname: `/ads/${categorySlug}/${regionSlug}`,
              }}
              className="bread_tag"
            >
              <span onClick={renderTop}>
                {otherData.district && otherData.district.name}
              </span>
            </Link>
          )}
          {citySlug && (
            <Link
              to={{
                pathname: `/ads/${categorySlug}/${regionSlug}/${citySlug}`,
              }}
              className="bread_tag"
            >
              <span onClick={renderTop}>
                {otherData.city && otherData.city.name}
              </span>
            </Link>
          )}
        </Breadcrumbs>
      </div>
      <div className="result_container container">
        {filteredDate.length < 1 && (
          <div className="empty_area">
            <InboxIcon />
            <p>- No Results Found -</p>
          </div>
        )}
        {filteredDate.map((ads) => (
          <div className="result_row">
            <div className="result_img">
              <Link
                className="link"
                to={{
                  pathname: `/ads/${ads.categorySlug}/${ads.regionsSlug}/${
                    ads.citySlug
                  }/${ads.adsData.id}/${convertToSlug(ads.adsData.ad_title)}`,
                }}
              >
                <Slider {...sliderSettings}>
                  {ads.adaImageList.map(
                    (image) =>
                      image.type === "TOP" && (
                        <div>
                          <img
                            src={`${BASE_S3_URL}${image.url}`}
                            alt="Image1"
                          />
                        </div>
                      )
                  )}
                </Slider>
              </Link>
            </div>
            <div className="result_content">
              <div className="result_data">
                <Link
                  className="link"
                  to={{
                    pathname: `/ads/${ads.categorySlug}/${ads.regionsSlug}/${
                      ads.citySlug
                    }/${ads.adsData.id}/${convertToSlug(ads.adsData.ad_title)}`,
                  }}
                >
                  <div className="title">{ads.adsData.ad_title}</div>
                </Link>
                {/* <div className='service_list_tag'>
                  {ads.service_list && ads.service_list.map((service) => (
                    <span>{service}</span>
                  ))}
                </div> */}
                <div className="discription">
                  {ads.adsData.ad_discription.replace(regex, "")}
                </div>
                <div className="tags">
                  {ads.category} | {ads.regions} | {ads.city}
                </div>
                <div className="btnss">
                  {ads.adsData.whatsapp === 1 && (
                    <a
                      href={`https://wa.me/+91${
                        ads.adsData.contact_no
                      }?text=Hi%0D%0A%0D%0AI+saw+your+ad+on+CLEANINGER%0D%0A%0D%0A${
                        window.location.href
                      }/${ads.regionsSlug}/${ads.citySlug}/${
                        ads.adsData.id
                      }/${convertToSlug(ads.adsData.ad_title)}`}
                      target="_blank"
                      className="whatsUp__btn_result rd_btn"
                    >
                      <i className="fa fa-whatsapp icon-bt"></i>
                      &nbsp;&nbsp;WHATSAPP
                    </a>
                  )}
                  &nbsp;&nbsp;
                  {ads.adsData.contact_no !== null && (
                    <a
                      href={`tel:+91${ads.adsData.contact_no}`}
                      className="phone__btn_result rd_btn"
                    >
                      <i className="fa fa-phone"></i>&nbsp;&nbsp;TELEPHONE
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
        <div
          className={pagination.total > 0 ? "paginate_row" : "paginate_empty"}
        >
          <Pagination
            activePage={pagination.current_page}
            itemsCountPerPage={pagination.per_page}
            totalItemsCount={pagination.total}
            pageRangeDisplayed={5}
            onChange={handlePageChange.bind(this)}
            itemClass="page-item"
            linkClass="page-link"
          />
        </div>

        <div className="footer_text_section">
          <FooterText />
        </div>
      </div>
      {/* <!-- ======= Pagination Request ======= --> */}
    </div>
  );
}

export default Results;
