import React from "react";
import ImageUploader from "react-images-upload";
import { useToasts } from "react-toast-notifications";

function ImageUploaderApp(props) {
  const { addToast } = useToasts();

  const onDrop = (picture) => {
    props.upImageList(this, picture)
    if (picture.length > props.limit) {
      addToast("Maximum image count is"+ props.limit, {
        appearance: "error",
        autoDismiss: true,
      });
    } 
  };

  return (
    <div className="imageUpload">
      <ImageUploader
        withIcon={true}
        buttonText="Choose your images"
        onChange={onDrop}
        imgExtension={[".jpg",".jpeg", ".png"]}
        maxFileSize={5242880}
        withPreview={true}
        label='Max file size: 5MB, accepted: jpg or png'
      />
    </div>
  );
}

export default ImageUploaderApp;
