import React, { useEffect, useState } from "react";
import "./../../Assets/css/PostAds.css";
import "./../../Assets/css/Comman.css";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import ImageUploader from "./ImageUploaderApp";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { GetData } from "../../ApiService/GetData";
import { PostDataAuth } from "../../ApiService/PostData";
import { apis, S3config, BASE_S3_URL } from "../../properties";
import { useToasts } from "react-toast-notifications";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import S3 from "react-aws-s3";
import FormHelperText from "@material-ui/core/FormHelperText";
import Auth from "../../Auth";
import ScaleLoader from "react-spinners/ScaleLoader";
import CKEditor from "ckeditor4-react";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Spinner from "../../Spinner";
import InputAdornment from "@material-ui/core/InputAdornment";
import Helmet from "react-helmet";

let imageList = [];
let upImgesList = [];
let uploadType = "";

function PostAdd(props) {
  const useEditData = props.location.state.adsData;
  const adsID = useEditData.adsData.id;
  const ReactS3Client = new S3(S3config);
  const { addToast } = useToasts();
  const history = useHistory();
  const seoData = {
    title: "Edit Ads",
    description: "Edit Ads",
    url: window.location.href,
  };

  const [loading, setLoading] = useState(false);
  const [spinner, setSpinner] = useState(true);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [deleteStatusPro, setDeleteStatusPro] = useState(false);
  const [postData, setPostData] = useState({
    id: "",
    category_id: "",
    city_id: "",
    state_id: "",
    zip: "",
    area: "",
    address: "",
    ad_title: "",
    ad_discription: "",
    imageList: [],
    imageListTop: [],
    age: "",
    contact_no: "",
    whatsapp: false,
  });
  const [postImages] = useState({
    imageList: [],
    type: "",
    id: adsID,
  });
  const [postDataError, setPostDataError] = useState({});

  const [selectedCategory, setSelectedCategory] = useState(
    useEditData.adsData.category_id
  );
  const [regionsList, setRegionsListItem] = useState([]);
  const [categoryList, setCategoryListItem] = useState([]);
  const [selectedCity, setSelectedCity] = useState(useEditData.adsData.city_id);
  const [selectedState, setSelectedState] = useState(
    useEditData.adsData.regions_id
  );
  const [CityList, setCityListItem] = useState([]);
  const [uploadedImageList, setUploadedImageList] = useState([]);
  const [uploadedImageListTOP, setUploadedImageListTOP] = useState([]);
  const [checkBox] = React.useState(
    <div>
      <p className="whatsapp-icon">WHATSAPP </p>
    </div>
  );

  useEffect(() => {
    setCategoryList();
    setRegionsList();
    setTimeout(() => {
      setValues();
    }, 3000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // GET CATEGORY DATA IN BACKEND
  const setCategoryList = async () => {
    GetData(apis.GET_CATEGORY_LIST).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        setCategoryListItem(responseJson.data);
      } else {
        addToast(responseJson.message, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    });
  };

  const setRegionsList = async () => {
    GetData(apis.GET_REGIONS_LIST).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        setRegionsListItem(responseJson.data);
      }
    });
  };

  const setCityList = async (id) => {
    GetData(`${apis.GET_CITIES_LIST}/${id}`).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        setCityListItem(responseJson.data);
      } else {
        addToast(responseJson.message, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    });
  };

  // FILL ALL ADS DATA
  const setValues = () => {
    let setDataAds = {};
    setDataAds.id = useEditData.adsData.id;
    setDataAds.ad_title = useEditData.adsData.ad_title;
    setDataAds.zip = useEditData.adsData.zip;
    setDataAds.area = useEditData.adsData.area;
    setDataAds.address = useEditData.adsData.address;
    setDataAds.ad_discription = useEditData.adsData.ad_discription;
    setDataAds.category_id = useEditData.adsData.category_id;
    setDataAds.city_id = useEditData.adsData.city_id;
    setDataAds.age = useEditData.adsData.age;
    setDataAds.contact_no = useEditData.adsData.contact_no;
    setDataAds.imageList = useEditData.adaImageList;
    setDataAds.imageListTop = useEditData.adaImageListTOP;
    if (useEditData.adsData.whatsapp === 1) {
      setDataAds.whatsapp = true;
    } else {
      setDataAds.whatsapp = false;
    }
    setCityList(useEditData.adsData.regions_id);
    setPostData(setDataAds);
    setUploadedImageList(useEditData.adaImageList);
    setUploadedImageListTOP(useEditData.adaImageListTOP);
    setSpinner(false);
  };

  // CHANGE THE VALUE IF USER SELCTE CATEGORY
  const categoryChange = (event) => {
    setSelectedCategory(event.target.value);
    if (event.target.value !== "") {
      postData.category_id = event.target.value;
    }
  };

  const stateChange = (event) => {
    setSelectedState(event.target.value);
    if (event.target.value !== "") {
      postData.state_id = event.target.value;
      setCityList(event.target.value);
    }
  };
  // CHANGE THE VALUE IF USER SELECTE CITY
  const cityChange = (event) => {
    setSelectedCity(event.target.value);
    if (event.target.value !== "") {
      postData.city_id = event.target.value;
    }
  };

  // GET UPIMAGE DATE IN IMAGE UPLOAD COMPONENT
  const upImageList = (e, responce) => {
    imageList = responce;
  };

  // NEXT BUTTON FUNTION
  const updateAdsBtn = () => {
    if (Auth.isAuthenticated()) {
      const isValid = formValidation();
      if (isValid) {
        setLoading(true);
        if (imageList.length === 0) {
          setImages();
        }
        updateAds();
      }
    } else {
      addToast(
        "You should log into your account. Please log in to your account and try again.",
        {
          appearance: "info",
          autoDismiss: true,
        }
      );
      history.push("/login");
    }
  };

  // UPDATE ADS DATA - BACKEND CALL
  const updateAds = () => {
    PostDataAuth(apis.UPDATE_ADS, postData).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        history.push("/user-ads");
        setLoading(false);
        addToast("Successfully updated", {
          appearance: "success",
          autoDismiss: true,
        });
      } else {
        addToast(responseJson.message, {
          appearance: "error",
          autoDismiss: true,
        });
        setLoading(false);
      }
    });
  };

  const updateAdsImages = () => {
    PostDataAuth(apis.UPDATE_ADS_IMAGES, postImages).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        postImages.imageList = [];
        upImgesList = [];
        history.push("/user-ads");
        setLoading1(false);
        setLoading2(false);
        addToast("Successfully updated", {
          appearance: "success",
          autoDismiss: true,
        });
      } else {
        addToast(responseJson.message, {
          appearance: "error",
          autoDismiss: true,
        });
        setLoading(false);
      }
    });
  };

  // POST DATA VALIDATION
  const formValidation = () => {
    let DataError = {};
    let isValid = true;
    if (postData.category_id === "") {
      DataError.category_id = "The category cannot be empty";
      isValid = false;
    }
    if (postData.state_id === "") {
      DataError.state_id = "The state cannot be empty";
      isValid = false;
    }
    if (postData.city_id === "") {
      DataError.city_id = "The city cannot be empty";
      isValid = false;
    }
    if (postData.ad_title === "") {
      DataError.ad_title = "The ads title cannot be empty";
      isValid = false;
    }
    if (postData.ad_discription === "") {
      addToast("The ads description cannot be empty", {
        appearance: "error",
        autoDismiss: true,
      });
      isValid = false;
    }
    if (imageList.length > 10) {
      addToast("Maximum image count is 10", {
        appearance: "error",
        autoDismiss: true,
      });
      isValid = false;
    }
    setPostDataError(DataError);
    return isValid;
  };

  // SET UPLOAD IMAGE URL AND SET PRIMARY IMAGE
  const setImages = () => {
    postImages.imageList = upImgesList;
    postImages.type = uploadType;
    setTimeout(() => {
      updateAdsImages();
      console.log("Cool!");
    }, 5000);
  };

  // UPLOAD IMAGES ONE BY ONE IN TO S3 BUCKET - AWS
  const uploadImageS3 = (file, x) => {
    ReactS3Client.uploadFile(file)
      .then((data) => {
        upImgesList.push(data.key);
        if (x === imageList.length - 1) {
          setImages();
        }
      })
      .catch((err) => {
        addToast("Image Upload error", {
          appearance: "error",
          autoDismiss: true,
        });
        console.log(err);
      });
  };

  // GET DATA IN EDITOR CANVES
  const onEditorChange = (evt) => {
    postData.ad_discription = evt.editor.getData();
  };

  const deleteImages = (type) => {
    if (type === "PRESENTATION") {
      setDeleteStatusPro(true);
      setUploadedImageListTOP([]);
      postData.imageListTop = [];
    } else {
      setDeleteStatus(true);
      postData.imageList = [];
      setUploadedImageList([]);
    }
  };

  const uploadImagesNow = (type) => {
    if (imageList.length > 0) {
      uploadType = type;
      if (type === "PRESENTATION") {
        if (imageList.length <= 3) {
          setLoading1(true);
          for (let x = 0; x < imageList.length; x++) {
            uploadImageS3(imageList[x], x);
          }
        } else {
          addToast(
            "You can choose only a 3 presentation images. Please check again!",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
        }
      } else {
        if (imageList.length <= 10) {
          setLoading2(true);
          for (let x = 0; x < imageList.length; x++) {
            uploadImageS3(imageList[x], x);
          }
        } else {
          addToast("You can choose only a 10 images. Please check again!", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      }
    } else {
      addToast(
        "There is no image to upload. Please select the images and try again",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  };

  return (
    <div className="container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{seoData.title} | Cleaninger</title>
        <meta name="description" content={seoData.description} />
        <meta property="og:title" content={seoData.title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seoData.url} />
        <link rel="canonical" href={seoData.url} />
      </Helmet>
      <Spinner spinner={spinner} />
      <div className="post-ads-section">
        <div onClick={() => history.goBack()} className="back_page">
          <ArrowBackIcon />
          <p>Back</p>
        </div>
        <div className="steps_one_section">
          <p className="txt">EDIT YOUR AD</p>
          <div className="fild_sp">
            <FormControl
              variant="outlined"
              size="small"
              className="full-with"
              {...(postDataError.category_id && {
                error: true,
              })}
            >
              <InputLabel id="selct-category">Select Category</InputLabel>
              <Select
                labelId="selct-category"
                value={selectedCategory}
                onChange={categoryChange}
                label="Select category"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {categoryList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{postDataError.category_id}</FormHelperText>
            </FormControl>
          </div>
          <div className="fild_sp">
            <FormControl
              variant="outlined"
              size="small"
              className="full-with"
              {...(postDataError.state_id && {
                error: true,
              })}
            >
              <InputLabel id="city-selct">Select State</InputLabel>
              <Select
                labelId="city-selct"
                value={selectedState}
                onChange={stateChange}
                label="Select city"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {regionsList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{postDataError.state_id}</FormHelperText>
            </FormControl>
          </div>
          <div className="fild_sp">
            <FormControl
              variant="outlined"
              size="small"
              className="full-with"
              {...(postDataError.city_id && {
                error: true,
              })}
            >
              <InputLabel id="city-selct">Select City</InputLabel>
              <Select
                labelId="city-selct"
                value={selectedCity}
                onChange={cityChange}
                label="Select city"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {CityList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{postDataError.city_id}</FormHelperText>
            </FormControl>
          </div>
          <div className="fild_sp cal-2">
            <TextField
              label="ZIP"
              className="full-with cal-2-1-with"
              variant="outlined"
              size="small"
              value={postData.zip}
              onChange={(event) =>
                setPostData({ ...postData, zip: event.target.value })
              }
            />
            <TextField
              label="AREA / DISTRICT / NEIGHBOURHOOD"
              className="full-with cal-2-2-with"
              variant="outlined"
              size="small"
              value={postData.area}
              onChange={(event) =>
                setPostData({ ...postData, area: event.target.value })
              }
            />
          </div>
          <div className="fild_sp">
            <TextField
              label="Address"
              className="full-with"
              variant="outlined"
              size="small"
              value={postData.address}
              onChange={(event) =>
                setPostData({ ...postData, address: event.target.value })
              }
            />
          </div>
          <div className="fild_sp">
            <TextField
              label="Title"
              className="full-with"
              variant="outlined"
              size="small"
              multiline
              rowsMax={10}
              value={postData.ad_title}
              onChange={(event) =>
                setPostData({ ...postData, ad_title: event.target.value })
              }
              {...(postDataError.ad_title && {
                error: true,
                helperText: postDataError.ad_title,
              })}
            />
          </div>
          <div className="fild_sp">
            <CKEditor
              data={postData.ad_discription}
              onChange={onEditorChange}
              config={{
                toolbar: [
                  [
                    "Cut",
                    "Copy",
                    "Paste",
                    "PasteText",
                    "PasteFromWord",
                    "-",
                    "Undo",
                    "Redo",
                    "Bold",
                    "Italic",
                    "NumberedList",
                    "BulletedList",
                  ],
                ],
              }}
            />
          </div>
        </div>

        {/* ADS IMAGES DISPLAY AND CAN ADD MORE IMAGES */}
        <div className="steps_one_section">
          <p className="txt">PRESENTATION IMAGES</p>
          <div className="up-img-list">
            {deleteStatusPro ? (
              <div>
                <ImageUploader upImageList={upImageList} limit={3} />
                <div className="up-image-dis">
                  <p>Please press the upload button.</p>
                  <div className="dis_fl">
                    <ScaleLoader
                      height={30}
                      color={"#ae3e64"}
                      loading={loading1}
                    />
                    &emsp;
                    <Button
                      className="primary__btn"
                      onClick={() => uploadImagesNow("PRESENTATION")}
                    >
                      UPLOAD NEW IMAGES
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="up-image-dis">
                <p>
                  If you want to change the presentation images then remove all
                  and upload new Images
                </p>
                <Button
                  className="remove__btn"
                  onClick={() => deleteImages("PRESENTATION")}
                >
                  Remove
                </Button>
              </div>
            )}
            <ul>
              {uploadedImageListTOP.map((image) => (
                <li key={image.id}>
                  <div className="pr-box">
                    <img src={`${BASE_S3_URL}${image.url}`} alt="ads-img" />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="steps_one_section">
          <p className="txt">PROFILE IMAGES</p>
          <div className="up-img-list">
            {deleteStatus ? (
              <div>
                <ImageUploader upImageList={upImageList} limit={10} />
                <div className="up-image-dis">
                  <p>Please press the upload button.</p>
                  <div className="dis_fl">
                    <ScaleLoader
                      height={30}
                      color={"#ae3e64"}
                      loading={loading2}
                    />
                    &emsp;
                    <Button
                      className="primary__btn"
                      onClick={() => uploadImagesNow("PROFILE")}
                    >
                      UPLOAD NEW IMAGES
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="up-image-dis">
                <p>
                  If you want to change the profile images then remove all and
                  upload new Images
                </p>
                <Button
                  className="remove__btn"
                  onClick={() => deleteImages("PROFILE")}
                >
                  Remove
                </Button>
              </div>
            )}
            <ul>
              {uploadedImageList.map((image) => (
                <li key={image.id}>
                  <div className="pr-box">
                    <img src={`${BASE_S3_URL}${image.url}`} alt="ads-img" />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* FILL USER DATA */}
        <div className="steps_one_section">
          <p className="txt">YOUR CONTACTS</p>
          <div className="fild_sp">
            <TextField
              label="Contact Number"
              className="full-with"
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+91</InputAdornment>
                ),
              }}
              value={postData.contact_no}
              onChange={(event) =>
                setPostData({
                  ...postData,
                  contact_no: event.target.value,
                })
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={postData.whatsapp}
                  onChange={(event) =>
                    setPostData({
                      ...postData,
                      whatsapp: event.target.checked,
                    })
                  }
                  color="primary"
                />
              }
              label={checkBox}
            />
          </div>
        </div>
        <div className="btn-line">
          <Button className="primary__btn" onClick={updateAdsBtn}>
            UPDATE
          </Button>{" "}
          &emsp;
          <ScaleLoader height={30} color={"#ae3e64"} loading={loading} />
        </div>
      </div>
    </div>
  );
}

export default PostAdd;
