import React, { useState, useEffect } from "react";
import "./../Assets/css/Filter.css";
import "./../Assets/css/Comman.css";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { GetData } from "../ApiService/GetData";
import { apis } from "../properties";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";

function Filter(props) {
  let categoryID = props.categoryID ? props.categoryID : "";
  let regionID = props.regionID ? props.regionID : "";
  let cityID = props.cityID ? props.cityID : "";
  let text = props.text ? props.text : "";
  const history = useHistory();

  const [categoryList, setCategoryListItem] = useState([]);
  const [CityList, setCityListItem] = useState([]);
  const [regionsList, setRegionsListItem] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(categoryID);
  const [selectedRegion, setSelectedRegion] = useState(regionID);
  const [selectedCity, setSelectedCity] = useState(cityID);
  const [searchText, setSearchText] = useState(text);

  useEffect(() => {
    setCategoryList();
    setRegionsList();
    setCityList(0);
  }, []);

  const setCategoryList = () => {
    GetData(apis.GET_CATEGORY_LIST).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        setCategoryListItem(responseJson.data);
      }
    });
  };

  const setRegionsList = async () => {
    GetData(apis.GET_REGIONS_LIST).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        setRegionsListItem(responseJson.data);
      }
    });
  };

  const setCityList = (id) => {
    GetData(`${apis.GET_CITIES_LIST}/${id}`).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        setCityListItem(responseJson.data);
      }
    });
  };

  const categoryChange = (event) => {
    setSelectedCategory(event.target.value);
    if (event.target.value === "") {
      setSelectedCategory(event.target.value);
    }
  };
  const cityChange = (event) => {
    setSelectedCity(event.target.value);
    if (event.target.value !== "") {
    }
  };
  const regionChange = (event) => {
    setSelectedRegion(event.target.value);
    if (event.target.value !== "") {
      setCityList(event.target.value);
    } else {
      setCityList(0);
    }
  };

  const searchData = (pageNumber) => {
    console.log(selectedCategory);
    if (selectedCategory) {
      categoryID = selectedCategory;
    } else {
      categoryID = "";
    }
    if (selectedRegion) {
      regionID = selectedRegion;
    } else {
      regionID = "";
    }
    if (selectedCity) {
      cityID = selectedCity;
    } else {
      cityID = "";
    }
    if (searchText) {
      text = searchText;
    } else {
      text = "";
    }
    let getURL = "";
    if (pageNumber) {
      getURL = `${apis.GET_SEARCH_DATA}?category=${categoryID}&region=${regionID}&city=${cityID}&text=${text}&page=${pageNumber}`;
    } else {
      getURL = `${apis.GET_SEARCH_DATA}?category=${categoryID}&region=${regionID}&city=${cityID}&text=${text}`;
    }
    GetData(getURL).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        history.push({
          pathname: `/filter`,
          state: {
            DataSet: responseJson.data.DataSet,
            Pagi: responseJson.data.Pagi,
            categoryID: categoryID,
            regionID: regionID,
            cityID: cityID,
            text: text,
          },
        });
      } else {
      }
    });
  };

  return (
    <div className="container">
      <div className="filter__container">
        <div className="filter_row1">
          <div className="filter__inputs">
            <FormControl
              variant="outlined"
              size="small"
              className="filter__select"
            >
              <InputLabel id="select-category">Select Category</InputLabel>
              <Select
                labelId="select-category"
                value={selectedCategory}
                onChange={categoryChange}
                label="Select Category"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {categoryList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="filter__inputs">
            <FormControl
              variant="outlined"
              size="small"
              className="filter__select"
            >
              <InputLabel id="regions-select">All the regions</InputLabel>
              <Select
                labelId="regions-select"
                value={selectedRegion}
                onChange={regionChange}
                label="Select Category"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {regionsList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="filter__inputs">
            <FormControl
              variant="outlined"
              size="small"
              className="filter__select"
            >
              <InputLabel id="cities-selct">All the cities</InputLabel>
              <Select
                labelId="cities-selct"
                value={selectedCity}
                // disabled={disableCity}
                onChange={cityChange}
                label="Select Category"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {CityList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="filter_row2">
          <div className="filter__select">
            <TextField
              id="outlined-basic"
              label="Search here..."
              className="full-with"
              variant="outlined"
              size="small"
              value={searchText}
              onChange={(event) => setSearchText(event.target.value)}
            />
          </div>
          <div className="mg-search">
            <Button
              variant="contained"
              className="primary__btn btn-size"
              color="secondary"
              onClick={searchData}
            >
              <SearchIcon /> &nbsp;Search
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Filter;
