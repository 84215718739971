/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { GetDataAuth } from "../../ApiService/GetData";
import { apis, BASE_S3_URL, sliderSettings } from "../../properties";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
// import Tooltip from "@material-ui/core/Tooltip";
// import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import UpdateIcon from "@material-ui/icons/Update";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import Swal from "sweetalert2";
import Slider from "react-slick";
import Pagination from "react-js-pagination";
import InboxIcon from "@material-ui/icons/Inbox";
import FileCopyIcon from "@material-ui/icons/FileCopy";

let pagi_status = false;
let currentDate = moment().format();
// const useStylesBootstrap = makeStyles((theme) => ({
//   arrow: {
//     color: theme.palette.common.black,
//   },
//   tooltip: {
//     backgroundColor: theme.palette.common.black,
//   },
// }));

// function BootstrapTooltip(props) {
//   const classes = useStylesBootstrap();

//   return <Tooltip arrow classes={classes} {...props} />;
// }

function ActiveAds() {
  const history = useHistory();
  const { addToast } = useToasts();

  const [emptyMSG, setEmptyMSG] = useState(true);
  const [adsList, setAdsList] = useState([]);
  const [pagination, setPagination] = useState({});

  useEffect(() => {
    getAdsDataList();
  }, []);

  const getAdsDataList = (pageNumber) => {
    let getURL = "";
    if (pageNumber) {
      getURL = `${apis.USER_ADS_LIST}/0?status=ACTIVE&page=${pageNumber}`;
    } else {
      getURL = `${apis.USER_ADS_LIST}/0?status=ACTIVE`;
    }
    GetDataAuth(getURL).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        pagi_status = true;
        setAdsList(responseJson.data.DataSet);
        setPagination(responseJson.data.Pagi);
        if (responseJson.data.DataSet.length === 0) {
          pagi_status = false;
          setAdsList([]);
          setEmptyMSG(true);
        } else {
          setEmptyMSG(false);
        }
      } else {
        pagi_status = false;
        setAdsList([]);
        setEmptyMSG(true);
      }
    });
  };

  const deleteAds = (adsID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "If you delete this ad, it does not appear on our platform anymore. You want to delete this ad",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2778c4",
      cancelButtonColor: "#666666",
      cancelButtonText: "No, Cancel",
      confirmButtonText: "Yes, Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        deletAdsDBUpdate(adsID);
        Swal.fire("Deleted!", "Your ab have been deleted.", "success");
      }
    });
  };

  const copyAds = (adsID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to duplicate this ad",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2778c4",
      cancelButtonColor: "#666666",
      cancelButtonText: "No, Cancel",
      confirmButtonText: "Yes, Duplicate",
    }).then((result) => {
      if (result.isConfirmed) {
        GetDataAuth(`${apis.ACTION_ADS}/duplicate/${adsID}`).then((result) => {
          let responseJson = result;
          if (responseJson.status === "success") {
            getAdsDataList();
            Swal.fire(
              "Duplicated!",
              "Your ab have been duplicated. You should have to pay and publish your ad.",
              "success"
            );
          } else {
            addToast(responseJson.message, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        });
      }
    });
  };

  const editAds = (data) => {
    history.push({
      pathname: `/edit-ads`,
      state: {
        adsData: data,
      },
    });
  };

  const unPublish = (adsID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "If you unpublish this ad, it does not appear on our platform anymore. You want to unpublish this ad.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#be206b",
      cancelButtonColor: "#666666",
      cancelButtonText: "No, Cancel",
      confirmButtonText: "Yes, Unpublish!",
    }).then((result) => {
      if (result.isConfirmed) {
        unPublishDBUpdate(adsID);
        Swal.fire("Unpublish!", "Your ab have been Unpublish.", "success");
      }
    });
  };

  const unPublishDBUpdate = (adsID) => {
    GetDataAuth(`${apis.ACTION_ADS}/unpublish/${adsID}`).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        getAdsDataList();
      } else {
        addToast(responseJson.message, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    });
  };

  const deletAdsDBUpdate = (adsID) => {
    GetDataAuth(`${apis.ACTION_ADS}/delete/${adsID}`).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        getAdsDataList();
      } else {
        addToast(responseJson.message, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    });
  };

  const updatePromotion = (adsID) => {
    history.push({
      pathname: `/update-promote-post/${adsID}`,
      state: {
        page_type: "CHANGE",
      },
    });
  };

  function convertToSlug(Text) {
    return Text.toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  }

  const handlePageChange = (pageNumber) => {
    window.scrollTo(0, 0);
    getAdsDataList(pageNumber);
  };
  return (
    <div>
      {emptyMSG && (
        <div className="empty_area">
          <InboxIcon />
          <p>- No data -</p>
        </div>
      )}
      {adsList.map((ads) => (
        <div className="pro-result_row">
          <div className="pro-result_img">
            <Link
              className="link"
              to={{
                pathname: `/ads/${ads.categorySlug}/${ads.regionsSlug}/${
                  ads.citySlug
                }/${ads.adsData.id}/${convertToSlug(ads.adsData.ad_title)}`,
              }}
            >
              <Slider {...sliderSettings}>
                {ads.adaImageListTOP.map((image) => (
                  <div>
                    <img src={`${BASE_S3_URL}${image.url}`} alt="Image1" />
                  </div>
                ))}
              </Slider>
            </Link>
          </div>
          <div className="pro-result_content">
            <div className="pro-result_data">
              <Link
                className="link"
                to={{
                  pathname: `/ads/${ads.categorySlug}/${ads.regionsSlug}/${
                    ads.citySlug
                  }/${ads.adsData.id}/${convertToSlug(ads.adsData.ad_title)}`,
                }}
              >
                <div className="pro-titledata">{ads.adsData.ad_title}</div>
              </Link>
              {/* <div className="pro-discription">
                {ads.adsData.ad_discription.replace(regex, "")}
              </div> */}
              <div className="pro-exdate">
                <p>
                  Ads created date:{" "}
                  {moment(ads.adsData.created_date).format("DD-MM-YYYY")}
                </p>
              </div>
              <div className="pro-exdate">
                <p>
                  Ad expiring date:{" "}
                  {moment(ads.adsData.ex_date).format("DD-MM-YYYY")}
                  &nbsp; (
                  {moment
                    .duration(
                      moment(ads.adsData.ex_date, "YYYY-MM-DD").diff(
                        moment(currentDate, "YYYY-MM-DD")
                      )
                    )
                    .asDays()}{" "}
                  More)
                </p>
              </div>
              <div className="pro-exdate">
                <p
                  className={
                    ads.promo_ex_status
                      ? "promo time-Inactive"
                      : "promo time-active"
                  }
                >
                  <UpdateIcon className="time-icon" /> PROMOTION EXPIRY DATE :{" "}
                  {moment(ads.promoteData.ex_date).format("DD-MM-YYYY")}
                </p>
              </div>
              {/* <div className="pro-tags">
                {ads.adsData.age} years | {ads.category} |{" "}
                {ads.regions} | {ads.city}
              </div> */}
              <div className="ads_ft_bar">
                <div
                  className="ads_ft_btn"
                  onClick={() => {
                    deleteAds(ads.adsData.id);
                  }}
                >
                  <DeleteIcon fontSize="small" />
                  <p>Delete</p>
                </div>
                <div
                  className="ads_ft_btn"
                  onClick={() => {
                    unPublish(ads.adsData.id);
                  }}
                >
                  <VisibilityOffIcon fontSize="small" />
                  <p>Unpublish</p>
                </div>
                <div
                  className="ads_ft_btn"
                  onClick={() => {
                    editAds(ads);
                  }}
                >
                  <CreateIcon fontSize="small" />
                  <p>Edit Ads</p>
                </div>
                <div
                  className="ads_ft_btn"
                  onClick={() => {
                    copyAds(ads.adsData.id);
                  }}
                >
                  <FileCopyIcon fontSize="small" />
                  <p>Duplicate Ads</p>
                </div>

                {ads.promo_ex_status && (
                  <div className="mb-none">
                    <Button
                      className="primary__btn mg_rm"
                      onClick={() => {
                        updatePromotion(ads.adsData.id);
                      }}
                    >
                      Renew Promotion
                    </Button>
                  </div>
                )}
              </div>
              {ads.promo_ex_status && (
                  <div className="d-none">
                    <Button
                      className="primary__btn rw_ads_btn"
                      onClick={() => {
                        updatePromotion(ads.adsData.id);
                      }}
                    >
                      Renew Promotion
                    </Button>
                  </div>
                )}
            </div>
          </div>
        </div>
      ))}
      {/* <!-- ======= Pagination Request ======= --> */}

      <div className={pagination.total > 0 ? "paginate_row" : "paginate_empty"}>
        <Pagination
          activePage={pagination.current_page}
          itemsCountPerPage={pagination.per_page}
          totalItemsCount={pagination.total}
          pageRangeDisplayed={5}
          onChange={handlePageChange.bind(this)}
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>
    </div>
  );
}

export default ActiveAds;
