import { IconButton } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import DehazeIcon from '@material-ui/icons/Dehaze';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import React from "react";
import { Link } from "react-router-dom";
import "./../Assets/css/Comman.css";
import "./../Assets/css/Header.css";
import BookmarksOutlinedIcon from '@material-ui/icons/BookmarksOutlined';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import BookmarksIcon from '@mui/icons-material/Bookmarks';

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

function HeaderOptionsTwo() {
  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const handleClickProfile = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl2(null);
  };


  return (
    <div>
      <div
        aria-controls="fade-menu"
        aria-haspopup="true"
        onClick={handleClickProfile}
      >
        <IconButton>
          <DehazeIcon />
        </IconButton>
      </div>
      <StyledMenu
        id="fade-menu"
        anchorEl={anchorEl2}
        keepMounted
        open={Boolean(anchorEl2)}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <Link to="/packages" className="link">
          <StyledMenuItem onClick={handleClose} className="header_option">
            <ListItemIcon>
              <BookmarksOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText className="mn-txt" primary="PACKAGES" />
          </StyledMenuItem>
        </Link>
        {/* <Link to="/coins" className="link">
          <StyledMenuItem onClick={handleClose} className="header_option">
            <ListItemIcon>
              <MonetizationOnOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText className="mn-txt" primary="COINS" />
          </StyledMenuItem>
        </Link> */}
        <Link to="/refund-policy" className="link">
          <StyledMenuItem onClick={handleClose} className="header_option">
            <ListItemIcon>
              <PaymentOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText className="mn-txt" primary="REFUND POLICY" />
          </StyledMenuItem>
        </Link>
        <Link to="/login" className="link">
          <StyledMenuItem onClick={handleClose} className="header_option">
            <ListItemIcon>
              <LockOpenIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText className="mn-txt" primary="LOGIN" />
          </StyledMenuItem>
        </Link>
        <Link to="/register" className="link">
          <StyledMenuItem onClick={handleClose} className="header_option">
            <ListItemIcon>
              <GroupAddIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText className="mn-txt" primary="SIGN UP" />
          </StyledMenuItem>
        </Link>
        <Link to="/booking" className="link">
          <StyledMenuItem onClick={handleClose} className="post_ads_menu_book">
            <ListItemIcon>
              <BookmarksIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText className="mn-txt2" primary="BOOK NOW" />
          </StyledMenuItem>
        </Link>
        <Link to="/post-ads" className="link">
          <StyledMenuItem onClick={handleClose} className="post_ads_menu">
            <ListItemIcon>
              <AddToPhotosIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText className="mn-txt2" primary="POST ADS" />
          </StyledMenuItem>
        </Link>
      </StyledMenu>
    </div>
  );
}

export default HeaderOptionsTwo;
