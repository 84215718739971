import React, { useEffect, useState } from "react";
import "../../Assets/css/Comman.css";
import "../../Assets/css/Profile.css";
// import { useHistory } from "react-router-dom";
import { GetDataAuth } from "../../ApiService/GetData";
import { apis, BASE_S3_URL } from "../../properties";
import { useToasts } from "react-toast-notifications";
import moment from "moment";
import { Switch, Route } from "react-router-dom";
import DashboardChart from "./DashboardChart";
import Ads from "./Ads";
import Credits from "./Credits";
import Profile from "./Profile";
import Helmet from "react-helmet";

function Dashboard() {
  // const history = useHistory();
  const { addToast } = useToasts();
  const seoData = {
    title: "Profile Dashboard",
    description: "Profile Dashboard",
    url: window.location.href,
  };
  const [countData, setCountData] = useState({});
  const [userData, setUserData] = useState({});

  useEffect(() => {
    getAdsCounts();
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAdsCounts = () => {
    GetDataAuth(apis.ADS_COUNT).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        setCountData(responseJson.data);
      } else {
        addToast(responseJson.message, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    });
  };

  const getUserData = () => {
    GetDataAuth(apis.USER_DATA).then((result) => {
      let responseJson = result;
      setUserData(responseJson);
    });
  };

  // const postAds = () => {
  //   history.push("/post-ads");
  // };

  // const buyCredit = () => {
  //   history.push("/user-credits");
  // };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{seoData.title} | Cleaninger</title>
        <meta name="description" content={seoData.description} />
        <meta property="og:title" content={seoData.title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seoData.url} />
        <link rel="canonical" href={seoData.url} />
      </Helmet>
      <div className="dashboard_bg container">
        <div className="dashboard_banner">
          <div className="dashboard_bannerLeft">
            <div className="pro-body">
              <div className="pro-img">
                <img src={`${BASE_S3_URL}${userData.img_url}`} alt="img1" />
              </div>
              <div className="pro-data">
                <p className="pro_name">
                  {userData ? userData.user_name : "-"}
                </p>
                <p className="pro_other">
                  {userData ? userData.tell_number : "-"}
                </p>
                <p className="pro_other">{userData ? userData.email : "-"}</p>
                <p className="pro_other">
                  Join Date :{" "}
                  {userData
                    ? moment(userData.created_at).format("YYYY-MM-DD")
                    : "-"}
                </p>
              </div>
            </div>
          </div>
          <div className="dashboard_bannerRight">
            <div className="dashboard_creditBox">
              <p>{countData ? countData.checkCredits : "0"}</p>
              <span>Coins</span>
            </div>
            <div className="dashboard_creditBox">
              <p>{countData ? countData.checkActive : "00"}</p>
              <span>Ads</span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Switch>
          <Route exact path="/dashboard" component={DashboardChart} />
          <Route exact path="/user-ads" component={Ads} />
          <Route exact path="/user-credits" component={Credits} />
          <Route exact path="/user-profile" component={Profile} />
        </Switch>
      </div>
    </div>
  );
}

export default Dashboard;
