import React from "react";
import "../Assets/css/DashboardOption.css";
import { useHistory } from "react-router-dom";

function DashboardOption({ Icon, title, url, action }) {
  const history = useHistory();
  return (
    <div
      className={action ? "dashboardOption dash-active" : "dashboardOption"}
      onClick={() => history.push(url)}
    >
      <Icon />
      <div className="mb-none">
        <p>{title}</p>
      </div>
    </div>
  );
}

export default DashboardOption;
