import React, { useEffect, useState } from "react";
import "../../Assets/css/DashboardChart.css";
import DashboardOption from "../../Components/DashboardOption";
import AirplayIcon from "@material-ui/icons/Airplay";
import FavoriteIcon from "@material-ui/icons/Favorite";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import SettingsIcon from "@material-ui/icons/Settings";
import { Line } from "react-chartjs-2";
import { apis } from "../../properties";
import { GetDataAuth } from "../../ApiService/GetData";
import { useToasts } from "react-toast-notifications";

function DashboardChart() {
  const [views, setViews] = useState([]);
  const [dates, setDates] = useState([]);
  const { addToast } = useToasts();
  useEffect(() => {
    getViewChart();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getViewChart = () => {
    GetDataAuth(apis.GET_CHART_DATA).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        setViews(responseJson.data.views)
        setDates(responseJson.data.date)
      } else {
        addToast(responseJson.message, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    });
  };

  const data = {
    labels: dates,
    datasets: [
      {
        label: "# of Views",
        data: views,
        fill: false,
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "rgba(255, 99, 132, 0.2)",
      },
    ],
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };
  return (
    <div>
      <div className="dashboard_menuBar container">
        <DashboardOption
          Icon={AirplayIcon}
          title="Dashboard"
          url="/dashboard"
          action={true}
        />
        <DashboardOption
          Icon={FavoriteIcon}
          title="Your Ads"
          url="/user-ads"
          action={false}
        />
        <DashboardOption
          Icon={AccountBalanceWalletIcon}
          title="Coins"
          url="/user-credits"
          action={false}
        />
        <DashboardOption
          Icon={SettingsIcon}
          title="Setting"
          url="/user-profile"
          action={false}
        />
      </div>
      <div className="container chat_style">
        <p>Total User views</p>
        <span>Number of user views your ads</span>
        <Line data={data} options={options} height={100} />
      </div>
    </div>
  );
}

export default DashboardChart;
