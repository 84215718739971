import React, { useState } from "react";
import "./../Assets/css/Comman.css";
import "./../Assets/css/Login.css";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import { apis, site_settings } from "./../properties";
import { useHistory, Link } from "react-router-dom";
import { PostData } from "./../ApiService/PostData";
import { useToasts } from "react-toast-notifications";
import ScaleLoader from "react-spinners/ScaleLoader";
import Auth from "../Auth";
import FormHelperText from "@material-ui/core/FormHelperText";
import Helmet from "react-helmet";

function Login() {
  const history = useHistory();
  const { addToast } = useToasts();
  const seoData = {
    title: "Login",
    description: "Login",
    url: window.location.href,
  };
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
    showPassword: false,
  });
  const [loginDataError, setLoginDataError] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  const userLogin = (event) => {
    event.preventDefault();
    const isValid = formValidation();
    if (isValid) {
      setLoading(true);
      PostData(apis.USER_LOGIN, loginData).then((result) => {
        let responseJson = result;
        if (responseJson.errors) {
          let errorArr = responseJson.errors;
          for (let x = 0; x < errorArr.length; x++) {
            addToast(errorArr[x].detail, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        } else {
          if (responseJson.status === "success") {
            let AuthUser = true;
            sessionStorage.setItem("AuthData", JSON.stringify(AuthUser));
            sessionStorage.setItem("userData", JSON.stringify(responseJson));
            sessionStorage.setItem(
              "userDataSet",
              JSON.stringify(responseJson.user)
            );
            Auth.login(() => {
              history.push("/");
            });
            window.location.reload();
          } else if (responseJson.status === "error") {
            addToast(responseJson.message, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        }
        setLoading(false);
      });
    }
  };

  const formValidation = () => {
    let loginError = {};
    let isValid = true;
    if (loginData.email === "") {
      loginError.email = "The email can not be empty";
      isValid = false;
    }
    if (loginData.password === "") {
      loginError.password = "The password can not be empty";
      isValid = false;
    }
    setLoginDataError(loginError);

    return isValid;
  };

  const handleClickShowPassword = () => {
    setLoginData({ ...loginData, showPassword: !loginData.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="login__container container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{seoData.title} | Cleaninger</title>
        <meta name="description" content={seoData.description} />
        <meta property="og:title" content={seoData.title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seoData.url} />
        <link rel="canonical" href={seoData.url} />
      </Helmet>
      <div className="login_box">
        <div className="login_title">
          <h2>Login</h2>
          <p className="back_url">
            or{" "}
            <span>
              <Link className="link" to="/register">
                create your account
              </Link>
            </span>
          </p>
        </div>
        <form>
          <div className="login__fields">
            <TextField
              id="outlined-basic"
              label="Enter your email address"
              className="full-with"
              variant="outlined"
              value={loginData.email}
              onChange={(event) =>
                setLoginData({ ...loginData, email: event.target.value })
              }
              {...(loginDataError.email && {
                error: true,
                helperText: loginDataError.email,
              })}
            />
          </div>
          <div className="login__fields">
            <FormControl
              variant="outlined"
              className="full-with"
              {...(loginDataError.password && {
                error: true,
                helperText: loginDataError.password,
              })}
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={loginData.showPassword ? "text" : "password"}
                value={loginData.password}
                onChange={(event) =>
                  setLoginData({
                    ...loginData,
                    password: event.target.value,
                  })
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {loginData.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
              <FormHelperText>{loginDataError.password}</FormHelperText>
            </FormControl>
            <div className="forget__pw">
              <Link className="link" to="/forgot-password">
                Forgot your password?
              </Link>
            </div>
          </div>
          <div>
            <ScaleLoader
              height={30}
              color={site_settings.LoaderColor}
              loading={loading}
            />
            <Button
              type="submit"
              variant="contained"
              className="primary__btn lg-btn"
              onClick={userLogin}
            >
              LOGIN
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
