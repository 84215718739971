/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import Filter from "../Header/Filter";
import Pagination from "react-js-pagination";
import Slider from "react-slick";
import { BASE_S3_URL, sliderSettings } from "../properties";
import { Link } from "react-router-dom";
import FooterText from "../Components/FooterText";
import InboxIcon from "@material-ui/icons/Inbox";
import Helmet from "react-helmet";

let regex = /<[^>]+>/g;
function SearchPage(props) {
  console.log(props);
  let DataSet = [];
  let Pagi = {};
  let categoryID = 0;
  let regionID = 0;
  let cityID = 0;
  let text = 0;
  if (props.location.state) {
    DataSet = props.location.state.DataSet;
    Pagi = props.location.state.Pagi;
    categoryID = props.location.state.categoryID;
    regionID = props.location.state.regionID;
    cityID = props.location.state.cityID;
    text = props.location.state.text;
  } else {
  }

  const seoData = {
    title: "Result Ads",
    description: "Result Ads",
    url: window.location.href,
  };

  const handlePageChange = (pageNumber) => {
    window.scrollTo(0, 0);
    // getResultData(pageNumber);
  };
  function convertToSlug(Text) {
    return Text.toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  }
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{seoData.title} | Cleaninger</title>
        <meta name="description" content={seoData.description} />
        <meta property="og:title" content={seoData.title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seoData.url} />
        <link rel="canonical" href={seoData.url} />
      </Helmet>
      <div className="Home_Banner">
        <p>Best Cleaning Services in your city</p>
        <span>
          Verified Experts | Pocket-Friendly Rates | 100% Safe & Secure
        </span>
      </div>
      <Filter
        categoryID={categoryID}
        regionID={regionID}
        cityID={cityID}
        text={text}
      />
      <div className="result_container container">
        {DataSet.length < 1 && (
          <div className="empty_area">
            <InboxIcon />
            <p>- No Results Found -</p>
          </div>
        )}
        {DataSet.map((ads) => (
          <div className="result_row">
            <div className="result_img">
              <Link
                className="link"
                to={{
                  pathname: `/ads/${ads.categorySlug}/${ads.regionsSlug}/${
                    ads.citySlug
                  }/${ads.adsData.id}/${convertToSlug(ads.adsData.ad_title)}`,
                }}
              >
                <Slider {...sliderSettings}>
                  {ads.adaImageList.map(
                    (image) =>
                      image.type === "TOP" && (
                        <div>
                          <img
                            src={`${BASE_S3_URL}${image.url}`}
                            alt="Image1"
                          />
                        </div>
                      )
                  )}
                </Slider>
              </Link>
            </div>
            <div className="result_content">
              <div className="result_data">
                <Link
                  className="link"
                  to={{
                    pathname: `/ads/${ads.categorySlug}/${ads.regionsSlug}/${
                      ads.citySlug
                    }/${ads.adsData.id}/${convertToSlug(ads.adsData.ad_title)}`,
                  }}
                >
                  <div className="title">{ads.adsData.ad_title}</div>
                </Link>
                <div className="discription">
                  {ads.adsData.ad_discription.replace(regex, "")}
                </div>
                <div className="tags">
                  {ads.category} | {ads.regions} | {ads.city}
                </div>
                <div className="btnss">
                  {ads.adsData.whatsapp === 1 && (
                    <a
                      href={`https://wa.me/+91${
                        ads.adsData.contact_no
                      }?text=Hi%0D%0A%0D%0AI+saw+your+ad+on+CLEANINGER%0D%0A%0D%0Ahttps://cleaninger.in/ads/${
                        ads.categorySlug
                      }/${ads.regionsSlug}/${ads.citySlug}/${
                        ads.adsData.id
                      }/${convertToSlug(ads.adsData.ad_title)}`}
                      target="_blank"
                      className="whatsUp__btn_result rd_btn"
                    >
                      <i className="fa fa-whatsapp icon-bt"></i>
                      &nbsp;&nbsp;WHATSAPP
                    </a>
                  )}
                  &nbsp;&nbsp;
                  {ads.adsData.contact_no !== null && (
                    <a
                      href={`tel:+91${ads.adsData.contact_no}`}
                      className="phone__btn_result rd_btn"
                    >
                      <i className="fa fa-phone"></i>&nbsp;&nbsp;TELEPHONE
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className={Pagi.total > 0 ? "paginate_row" : "paginate_empty"}>
          <Pagination
            activePage={Pagi.current_page}
            itemsCountPerPage={Pagi.per_page}
            totalItemsCount={Pagi.total}
            pageRangeDisplayed={5}
            onChange={handlePageChange.bind(this)}
            itemClass="page-item"
            linkClass="page-link"
          />
        </div>

        <div className="footer_text_section">
          <FooterText />
        </div>
      </div>
    </div>
  );
}

export default SearchPage;
