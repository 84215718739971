import React, { useEffect, useState } from "react";
import "./../Assets/css/Comman.css";
import "./../Assets/css/Home.css";
import { Link } from "react-router-dom";
import Filter from "../Header/Filter";
import { GetData } from "../ApiService/GetData";
import { apis } from "../properties";
import Helmet from "react-helmet";
import { useSettingStore } from "../store";
import FooterText from "../Components/FooterText";

function Home() {
  const filterBar = useSettingStore((state) => state.filterBar);

  const [homeData, setHomeData] = useState([]);
  const seoData = {
    title: "Home",
    description: "Home",
    url: window.location.href,
  };

  useEffect(() => {
    getHomeData();
  }, []);

  const getHomeData = () => {
    GetData(apis.HOME_DATA).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        setHomeData(responseJson.data);
      } else {
      }
    });
  };
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Best Deep Cleaning Services Near Me - cleaninger.in</title>
        <meta
          name="description"
          content="Cleaninger offers you to post home cleaning, office cleaning, pest control services ads. Get contact details of Cleaning Service companies in all over india."
        />
        <meta
          name="keywords"
          content="house cleaning services near me,deep cleaning services near me, cleaning services near me, bathroom cleaning services, sanitization services near me, sofa cleaning services near me, bathroom cleaning services near me, office cleaning services near me, pest control services near me, cockroach pest control service"
        />
        <meta
          property="og:title"
          content="Best Deep Cleaning Services Near Me - cleaninger.in"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seoData.url} />
        <link rel="canonical" href={seoData.url} />
      </Helmet>
      <div className="Home_Banner">
        <p>Best Cleaning Services in your city</p>
        <span>
          Verified Experts | Pocket-Friendly Rates | 100% Safe & Secure
        </span>
      </div>
      {/* <div className="d-none"><Filter /></div> */}
      <div>{filterBar && <Filter />}</div>
      <div className="home_container container">
        <div className="home_categories">
          {homeData.map((item) => (
            <div className="cate__box">
              <Link className="link" to={`/ads/${item.slug}`}>
                <div className={item.style}>
                  <p className="bb_txt">{item.type_name}</p>
                </div>
              </Link>
              {/* <div className="cate__discription">
                <p>{item.description}</p>
              </div> */}
              {/* <div className="cate__listItem">
                <ul className="list-unstyled">
                  {item.fav_url.map((item) => (
                    <li className="list-unstyled">
                      <Link className="link" to={item.url}>
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div> */}
            </div>
          ))}
        </div>
        <div className="footer_text_section">
          <FooterText />
        </div>
      </div>
    </div>
  );
}

export default Home;
