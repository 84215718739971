import React from "react";

function NotFount() {
  return (
    <div className="notfound">
      <h1>404</h1>
      <p>Page Not Found</p>
    </div>
  );
}

export default NotFount;
