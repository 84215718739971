/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import "./../Assets/css/Comman.css";
import "./../Assets/css/Login.css";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { apis, site_settings } from "./../properties";
import { useHistory, Link } from "react-router-dom";
import { PostData } from "./../ApiService/PostData";
import { useToasts } from "react-toast-notifications";
import ScaleLoader from "react-spinners/ScaleLoader";
import Helmet from "react-helmet";

function SignUp() {
  const history = useHistory();
  const { addToast } = useToasts();
  const seoData = {
    title: `Sign Up`,
    description: `Sign Up`,
    url: window.location.href,
  };
  const [state] = useState(
    <div>
      <p className="pv_mtxt">
        Terms and Conditions of use and Privacy Policy <br />
        <span className="pv_txt">
          I have read the Terms and Conditions of use and Privacy Policy and I
          consent the processing of my personal data for the purposes related to
          the provision of the web service.
        </span>
      </p>
    </div>
  );

  const [userData, setUserData] = useState({
    uname: "",
    email: "",
    contact: "",
    password: "",
    password_confirmation: "",
    privercy_tearm: false,
  });
  const [userDataErr, setUserDataErr] = useState({
    uname: "",
    email: "",
    contact: "",
    password: "",
    password_confirmation: "",
    privercy_tearm: false,
  });
  const [loading, setLoading] = useState(false);

  const userRegister = (event) => {
    setLoading(true);
    event.preventDefault();
    const isValid = formValidation();
    if (isValid) {
      PostData(apis.USER_REGISTER, userData).then((result) => {
        let responseJson = result;
        console.log(responseJson);
        if (responseJson.errors) {
          let errorArr = responseJson.errors;
          for (let x = 0; x < errorArr.length; x++) {
            addToast(errorArr[x].detail, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        } else {
          if (responseJson.status === "success") {
            addToast(responseJson.message, {
              appearance: "success",
              autoDismiss: true,
            });
            history.push("/login");
            setLoading(false);
          } else if (responseJson.status === "error") {
            addToast(responseJson.message, {
              appearance: "error",
              autoDismiss: true,
            });
            setLoading(false);
          }
        }
        console.log(responseJson);
      });
    }
  };

  const formValidation = () => {
    let userError = {};
    let isValid = true;
    var pattern = new RegExp(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    if (userData.uname === "") {
      userError.uname = "The user name cannot be empty";
      isValid = false;
    }
    if (userData.email === "") {
      userError.email = "The email cannot be empty";
      isValid = false;
    }
    if (!pattern.test(userData.email)) {
      userError.email = "You have entered an invalid email address!";
      isValid = false;
    }
    if (userData.password === "") {
      userError.password = "The password cannot be empty";
      isValid = false;
    }
    if (userData.password_confirmation === "") {
      userError.password_confirmation = "The password confirm cannot be empty";
      isValid = false;
    }

    if (!userData.privercy_tearm) {
      addToast("Please agree our terms and conditions", {
        appearance: "error",
        autoDismiss: true,
      });
      isValid = false;
    }
    setUserDataErr(userError);

    return isValid;
  };
  return (
    <div className="login__container container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{seoData.title} | Cleaninger</title>
        <meta name="description" content={seoData.description} />
        <meta property="og:title" content={seoData.title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seoData.url} />
        <link rel="canonical" href={seoData.url} />
      </Helmet>
      <div className="login_box">
        <div className="login_title">
          <h2>SIGN UP</h2>
          <p className="back_url">
            or{" "}
            <span>
              <Link className="link" to="/login">
                login to your account
              </Link>
            </span>
          </p>
        </div>
        <form>
          <div className="login__fields">
            <TextField
              id="outlined-basic"
              type="text"
              label="User Name"
              className="full-with"
              variant="outlined"
              value={userData.uname}
              onChange={(event) =>
                setUserData({ ...userData, uname: event.target.value })
              }
              {...(userDataErr.uname && {
                error: true,
                helperText: userDataErr.uname,
              })}
            />
          </div>
          <div className="login__fields">
            <TextField
              id="outlined-basic"
              type="email"
              label="Enter your email address"
              className="full-with"
              variant="outlined"
              value={userData.email}
              onChange={(event) =>
                setUserData({ ...userData, email: event.target.value })
              }
              {...(userDataErr.email && {
                error: true,
                helperText: userDataErr.email,
              })}
            />
          </div>
          <div className="login__fields">
            <TextField
              id="outlined-basic"
              type="number"
              label="Contact Number"
              className="full-with"
              variant="outlined"
              value={userData.contact}
              onChange={(event) =>
                setUserData({ ...userData, contact: event.target.value })
              }
              {...(userDataErr.contact && {
                error: true,
                helperText: userDataErr.contact,
              })}
            />
          </div>
          <div className="login__fields">
            <TextField
              id="outlined-basic"
              type="password"
              label="Password"
              className="full-with"
              variant="outlined"
              value={userData.password}
              onChange={(event) =>
                setUserData({ ...userData, password: event.target.value })
              }
              {...(userDataErr.password && {
                error: true,
                helperText: userDataErr.password,
              })}
            />
          </div>
          <div className="login__fields">
            <TextField
              id="outlined-basic"
              type="password"
              label="Confirm your password"
              className="full-with"
              variant="outlined"
              value={userData.password_confirmation}
              onChange={(event) =>
                setUserData({
                  ...userData,
                  password_confirmation: event.target.value,
                })
              }
              {...(userDataErr.password_confirmation && {
                error: true,
                helperText: userDataErr.password_confirmation,
              })}
            />
          </div>
          <div>
            <FormControlLabel
              className="pv_check"
              control={
                <Checkbox
                  checked={userData.privercy_tearm}
                  onChange={(event) =>
                    setUserData({
                      ...userData,
                      privercy_tearm: event.target.checked,
                    })
                  }
                  color="primary"
                />
              }
              label={state}
            />
          </div>
          <div>
            <ScaleLoader
              height={30}
              color={site_settings.LoaderColor}
              loading={loading}
            />
            <Button
              type="submit"
              variant="contained"
              className="primary__btn lg-btn"
              onClick={userRegister}
            >
              SIGN UP
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SignUp;
