import React from "react";

function ContactUs() {
  return (
    <div className="container">
      <div className="term-page">
        <h2 className="term-title">Contact us</h2>
        <p>Legal name : CLEANINGER PRIVATE LIMITED</p>
        <div className="contact_us">
          <div className="box">
            <h3>Address</h3>
            <p>DOOR NO : 34/1A2 , BEHIND SEASONS HOTEL , KONAPPANA AGRAHARA , ELECTRONIC CITY PHASE-2 , BANGALORE-560100</p>
          </div>

          <div className="box">
            <h3>Email</h3>
            <p>Cleaningerprivatelimited@gmail.com</p>
          </div>

          <div className="box">
            <h3>Phone</h3>
            <p>+91 8431842873</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
