import React from "react";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";

function FooterText() {
  return (
    <div className="footer_text">
      <div className="home_bt_section">
        <h2>3 Easy Steps</h2>
        <p>To Hire Cleaning Services Professionals</p>
        <div className="easy_row">
          <div className="easy_box">
            <div className="no">1</div>
            <p className="title">Share your requirements with Cleaninger</p>
            <p className="description">
              Mention the cleaning needs for your house or commercial property.
            </p>
          </div>
          <div className="easy_box">
            <p className="no">2</p>
            <p className="title">Get free verified service quotes</p>
            <p className="description">
              Get multiple free quotes from top cleaning service providers and
              companies.
            </p>
          </div>
          <div className="easy_box">
            <p className="no">3</p>
            <p className="title">
              Compare, hire, and experience hassle-free service
            </p>
            <p className="description">
              Compare quotes and hire cleaning service professionals that suit
              your requirement and budget.
            </p>
          </div>
        </div>
      </div>
      <div className="home_bt_section">
        <h2>Why Cleaninger</h2>
        <p>
          Cleaninger is India's most efficient and affordable premier listing
          service. When you choose Cleaninger, you get the best offers available
          in the market and negotiate your terms with the top service providers.
          Our specialists guarantees 100% customer satisfaction while delivering
          the service on time.
        </p>
        <div className="why_bg">
          <div className="why_clean_list">
            <div className="item_row">
              <CheckCircleOutlineOutlinedIcon />
              <p>Assured Service Quality</p>
            </div>
            <div className="item_row">
              <CheckCircleOutlineOutlinedIcon />
              <p>5-star Rated Cleaning Experts</p>
            </div>
            <div className="item_row">
              <CheckCircleOutlineOutlinedIcon />
              <p>Eco-friendly Products</p>
            </div>
          </div>

          <div className="why_clean_list">
            <div className="item_row">
              <CheckCircleOutlineOutlinedIcon />
              <p>Free Quotations</p>
            </div>
            <div className="item_row">
              <CheckCircleOutlineOutlinedIcon />
              <p>Affordable Prices</p>
            </div>
            <div className="item_row">
              <CheckCircleOutlineOutlinedIcon />
              <p>On-Time services</p>
            </div>
          </div>
        </div>
      </div>
      <div className="home_bt_section2">
        <h2>Professional Cleaning Services in Bangalore</h2>
        <p>
          Keeping up with the rising demand is a tiresome task and hence
          cleaning service professionals in Bangalore have upgraded their
          approach to keep up with the city's cleaning needs. The professionals
          and service experts hired by verified companies are dexterous when it
          comes to undertaking various cleaning tasks and make sure they provide
          a great experience to the customers.
        </p>
        <h3>Reasons to Hire Professional Cleaning Services</h3>
        <p>
          Cleaning the house can be a daunting task for many, and the reason
          being so many spaces that require special techniques and tools to get
          the work done effectively. Cleaning service comes in handy for people
          who are living on their own, house makers, and those who live away
          from home. Sit back and relax while your house gets rid of all the
          clutter, dust, and Gump from all corners of the house, and the
          cleaning products used by many of them are of good quality that never
          causes any health issue or damage to the property that is being
          cleaned.
        </p>
        <h3>When to Hire Professional Cleaning Services</h3>
        <p>
          Home cleaning is often overlooked despite its importance and relevance
          in our day-to-day life. If you have skipped your deep cleaning routine
          for more than 6 months now, it is high time to get your home or
          commercial space treated with deep cleaning service. The recent
          guidelines for corporates and business places require you to disinfect
          your premises every 2 months to ensure complete extermination of
          bacteria or viruses and avoid any breakouts.
        </p>
        <h3>Why Cleaninger Cleaning Services?</h3>
        <p>
          Cleaninger is an online platform that connects you with the best
          professional cleaning service providers. These professional service
          providers are experienced in carrying out various home cleaning
          services in Bangalore and all the handpicked cleaning service
          providers listed on our website are verified through a strict
          background check. Our house cleaning services in Bangalore are
          designed to fulfill all your cleaning requirements under the most
          affordable quotations. All executives are highly trained and
          customer-centric to ensure they deliver good service to their clients.
        </p>
        <h2>How to Hire Professional Cleaning Service in Cleaninger?</h2>
        <p>
          To find reputed and top cleaning service providers in Bangalore, head
          to cleaninger.in or download our app and get all your needs fulfilled
          under one roof. You can search Cleaning Services in Bangalore on the
          basis of their popularity, ratings & reviews. Once you have provided
          your basic requirement and location, we will select the most suitable
          service provider that ticks all the boxes from your requirement. The
          businesses will contact you through phone or text with their best
          offers, which you can further negotiate with them. Get hundreds of
          businesses to choose from with multiple quotes within seconds when you
          search from the Cleaninger website or app that is available on the
          PlayStore and AppStore. Be it everyday household cleaning or deep
          cleaning chores, our professionals are trained to efficiently provide
          you with the best services.
        </p>
        <h3>Types of Cleaning Services offered</h3>
        <p>
          The cleaning services offered in Bangalore include specialized
          cleaning for water tanks, septic tanks, bathrooms, kitchens, sofas,
          carpets, mattresses, and anything else you might need a cleaning
          service for. From regular cleaning services that include washing of
          utensils, sweeping, swabbing, cleaning of bathrooms, dusting, deep
          cleaning alternative, and special equipment cleaning, everything is
          available and customizable for you to make your premises squeaky clean
          and disinfected. In addition to all of this, cleaning service experts
          also offer you the choice to have your upholstery, sofas, carpets, and
          mattresses cleaned by them.
        </p>
        <h3>Equipment’s/Chemicals Used By Experts</h3>
        <p>
          You dont need to stock up cleaning products when you hire cleaning
          experts. All cleaning companies provide essential cleaning products
          with their staff. Furniture polish, Cleaning brushes, Glass cleaner,
          Multipurpose cleaner, Disinfectant Mildew cleaner, Descaler,
          Degreaser, Floor cleaner, Bleach, Sanitiser, Toilet cleaner, Washing
          up liquid, Oven cleaner, Laundry detergent, Dishwasher chemicals are
          some of the products used by the professionals to clean your premises.
          All the products and chemicals used by cleaning service providers are
          harmless and safe for kids and pets.
        </p>
        <h3>The cleaning Service cost in Cleaninger</h3>
        <p>
          We have listed the approximate cost of the most commonly availed
          services you can refer to. The service charges may vary depending upon
          the size and environment of the room. .
        </p>
      </div>
      <div className="home_bt_section2">
        <h2 className="hm_main_title">
          Basic Cleaning service price range by property wise
        </h2>
        <table className="table_cost">
          <thead>
            <tr>
              <th>Basic Cleaning</th>
              <th>Cost (INR)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1 BHK </td>
              <td>Rs.1,000-2,000</td>
            </tr>
            <tr>
              <td>2 BHK </td>
              <td>Rs.2,500-4,000</td>
            </tr>
            <tr>
              <td>3 BHK </td>
              <td>Rs.4,000-5,800</td>
            </tr>
            <tr>
              <td>4 BHK </td>
              <td>Rs.6,000-7,000</td>
            </tr>
          </tbody>
        </table>
        <h2 className="hm_main_title">
          Deep Cleaning service price range by property wise
        </h2>
        <table className="table_cost">
          <thead>
            <tr>
              <th>Deep Cleaning</th>
              <th>Cost (INR)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1 BHK </td>
              <td>Rs.3,600-5,500</td>
            </tr>
            <tr>
              <td>2 BHK </td>
              <td>Rs.6,000-6,500</td>
            </tr>
            <tr>
              <td>3 BHK </td>
              <td>Rs.6,900-7,400</td>
            </tr>
            <tr>
              <td>4 BHK </td>
              <td>Rs.7,800-8,600</td>
            </tr>
          </tbody>
        </table>
        <h2 className="hm_main_title">Shampooing price range</h2>
        <table className="table_cost">
          <thead>
            <tr>
              <th>Shampooing</th>
              <th>Cost (INR)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Fabric Sofa Shampooing</td>
              <td>Rs.900-1,200</td>
            </tr>
            <tr>
              <td>Carpet Shampooing</td>
              <td>Rs.1,000-1,400</td>
            </tr>
            <tr>
              <td>Mattress Shampooing</td>
              <td>Rs.500-900</td>
            </tr>
            <tr>
              <td>Dining Chair Shampooing </td>
              <td>Rs.950-1,200</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="home_bt_section2">
        <h3>Do’s & Don’t while hiring cleaning Experts</h3>
        <p>
          Never give access to your house to an unknown person or fraud company.
          Always select verified companies from Cleaninger, where they make sure
          to background check every specialist and train them to give an
          unparalleled experience. As deep cleaning involves exclusive cleaning
          rituals, many a time, people choose cheap alternative assistance to
          get there house and office cleaned and end up paying extra afterward
          when they find out incomplete and inconsistent cleaning was done. We
          suggest you opt for the best deep cleaning services for your need,
          where all the equipment to be used, treatment, and schedule are
          predetermined, and you can get total peace of mind.
        </p>
      </div>
      <div className="home_bt_section2">
        <h2>Frequently Asked Questions</h2>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="tab__title">
              What is the cost of a home cleaning service?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <p className="faq_txt">
                Cleaning is a tedious and time-consuming job for us but the
                professionals can do it in a matter of a few hours. Home
                Cleaning services start from Rs. 2,500 and can range up to Rs.
                10,000 depending on the size of your house and the number of
                bedrooms it has.
              </p>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="tab__title">
              Do I have to subscribe to the service for a longer duration or can
              I book your service for just once?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <p className="faq_txt">
                We have a lot of flexibility in offering services. You can book
                your housecleaning service for cleaning just one time. If you
                want the service frequently for a longer duration, you can
                contact our service provider and get the quotes for the same
              </p>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="tab__title">
              What are the areas of cleaning covered in your service?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <p className="faq_txt">
                Our service providers offer basic cleaning services and deep
                cleaning service both the services can be opted when required or
                for long term needs. While basic service requires just basic
                cleaning of the house life dusting and mopping the whole area
                with similar kind of cleaning in major portions of the house.
                However, in Deep Cleaning not an inch of the house is left
                un-cleaned.
              </p>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="tab__title">
              Do you give all the pieces of equipment and things required for
              cleaning?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <p className="faq_txt">
                Yes. The service provider will come with all its equipment
                required for the cleaning job
              </p>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="tab__title">
              How do I prepare my house before cleaning services visit us?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <p className="faq_txt">
                The best way you can help the service provider is by separating
                things from places. Like toys, crockery, clothes, etc. This
                makes easy for the service provider to clean your house as well
                as save some time
              </p>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="tab__title">
              Do I have to be available at home while cleaning services visit
              for cleaning?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <p className="faq_txt">
                Not really. This is a personal choice. Some of our users prefer
                to call cleaning service providers to their home while they are
                away for work while some users prefer their presence when the
                cleaning takes places. Whatever the case is you are likely to
                receive superior cleaning of your house
              </p>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="tab__title">
              Can I reschedule an appointment?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <p className="faq_txt">
                Yes certainly. If you feel there is something that you need to
                take care of before cleaning, you can always reschedule an
                appointment with the cleaning service provider. You will not be
                charged extra for that.
              </p>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="tab__title">
              What if I am not satisfied with the cleaning service?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <p className="faq_txt">
                Customer satisfaction is our motto. If you are not satisfied
                with the cleaning service you can call the service provider and
                request for another cleaning.
              </p>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="home_bt_section2">
        <h2>About Cleaninger Pest Control Service in Bangalore</h2>
        <p>
          Find and connect with 1300+ experienced pest control experts for
          eco-friendly and organic pest services at competent prices. In 2021,
          about 18% of users requested for cockroach pest services, 13% for
          lizards, 13% for snake catching followed by termites, ants, rodents
          and bed bugs pest services. Cleaninger offers a list of services that
          can help you get rid of harmful pests like Ants, Bed Bugs, Bee Hive
          Removal, Birds, Cockroaches, Flies, Lizards, Mosquitoes, Rodents,
          Snakes, Termites, and other insects. Also offers the best equipment
          dealers like Electric Mosquito Killer Machine, Fogging Machine, and
          Rat Trap Cage. Our experts have successfully treated 800+ premises for
          different types of pests in the last 30 days. It is necessary for
          every residential/commercial owner to protect their properties from
          harmful & poisonous pests. Get quotes from professional service
          providers to deal with infestations present at your place.
        </p>
        <h3>Our Professional's follow strict Safety Measures</h3>
        <ul className="ul_ff">
          <li>Low contact & social distancing</li>
          <li>Mandatory daily temperature checks</li>
          <li>Wear masks & gloves</li>
          <li>Hand sanitize regularly</li>
          <li>Aarogaya Setu App compliant</li>
        </ul>
      </div>
      <div className="home_bt_section2">
        <h2>Say Goodbye to your Last Bugs!</h2>
        <p>
          Pest attacks in your personal space can be annoying and harmful to
          your health. Pests multiply in number very quickly, making it chaos to
          get rid of. The very first signs of pests in your home must alert you
          to book a professional pest control expert at the earliest. Simple DIY
          pest treatments using various chemicals may provide you temporary
          relief but not a permanent solution. To find the root cause of the
          infestation and to tackle it timely, it is essential to get a
          professional expert on board.
        </p>
        <h3>5 main reasons that you should always hire an expert</h3>
        <ul className="ul_ff">
          <li>
            <b>Knowledgeable:</b> the experts have been trained to use chemicals
            that may be hazardous to the consumers
          </li>
          <li>
            <b>Effectiveness:</b> Regular schedules maintenance helps reduce
            pest problems
          </li>
          <li>
            <b>Cost factor:</b> AMC seems to be expensive, but the cost to
            repair furniture or termite damage will cost more
          </li>
          <li>
            <b>Safety:</b> Exterminators help in safely removing pest without
            causing harm to homeowners
          </li>
          <li>
            <b>Professional pesticides:</b> provide protection ranging from 6
            weeks to 2 years, depending on the type of pesticide.
          </li>
        </ul>
        <h3>How to choose the right service expert on Cleaninger</h3>
        <p>At Cleaninger, we have made it easy just follow these steps</p>
        <ul className="ul_ff">
          <li>
            Fill the form and we will connect you with the top shifting
            providers near you.
          </li>
          <li>You will receive calls/quotes from them</li>
          <li>
            Look at their reviews, compare prices before booking one of the
            service experts.
          </li>
          <li>
            We recommend that you check the combination of price and reviews
            before you decide on the service provider.
          </li>
        </ul>
        <h3>Different Type of Pest Problems in Bangalore</h3>
        <ul className="ul_ff">
          <li>
            <b>Cockroaches Pest Control:</b> Cockroaches can survive in a wide
            range of environments. They carry many disease-causing germs on
            their bodies. As their natural habitat include rubbish, they readily
            pick up disease-causing germs from these areas. They are commonly
            found in the kitchen and transfer germs to the people through food
            items.
          </li>
          <li>
            <b>Bed Bugs Control:</b> Bedbugs measure around 4-6 mm in length and
            are often brought into homes by people who might have traveled
            interstate. Bed bugs can easily attach themselves in the luggage,
            bedding, furniture, etc. They often bite while an individual is
            sleeping. Bed bugs can infest almost all areas of a house,
            particularly your bedrooms.
          </li>
          <li>
            <b>Rodents Control:</b> They are one of the most common house pests.
            They can contaminate your food, carry and transmit many diseases.
            They can also chew your electric cables which can cause a fire
            hazard. Rodents are known to be rapid breeders, and hence, rodent
            infestation is one of the biggest problems faced by the house
            owners.
          </li>
          <li>
            <b>Ants Pest Control:</b> Ant infestation is difficult to control as
            they often come out in large numbers. Some of the ants' bites can
            cause irritation; however, if you are prone to allergies from insect
            bites, then you must take some measures to control them.
          </li>
          <li>
            <b>Mosquitoes Control:</b> Mosquitoes are prevalent in places with
            less hygiene and stranded water. Mosquito repellants, mosquito bats,
            and coils are not 100 % effective. These small insects can hamper
            your health with some significant health diseases such as malaria,
            dengue, and other diseases.
          </li>
          <li>
            <b>Termites Pest Control:</b> Termites can eat into every structure
            of your home, including the support beams, posts, ceiling joists,
            floor joists, and wall studs. If termites get into the structures
            which support your home, they will cause serious damage until expert
            professionals treat the infestation.
          </li>
          <li>
            <b>Flies Pest Control:</b> Flies are carriers of life-threatening
            diseases including typhoid, cholera, anthrax, and tuberculosis.
            Although they don’t bite, they transmit disease through
            contamination of food.
          </li>
        </ul>
        <h3>Methods Used By Insects Control Services</h3>
        <h4>Natural methods of pest control:</h4>
        <p>
          Now, these are the methods that are used for eliminating infestations
          by employing natural means in order to carry out the job without
          harming humans, plants, pets, etc. One of the most effective and
          commonest ways of natural pest control is baiting. In this method,
          organic substances are used to attract the pests to the set traps. In
          some cases, the poison is introduced in the bait, which is carried by
          the pests to their colonies, and hence extraordinary results are
          achieved. The main advantage of such methods is that the environment
          isn’t harmed in any manner. Another great thing is that these methods
          are quite cost-effective. The only shortfall is that a considerable
          amount of time is involved in getting the desired results. Ex:
          Fumigation, Traps and baits, Repellants, General pest control method,
          Heat treatment, Insect Growth Regulators, Disinfectants, Herbicides.
        </p>
        <h4>Chemical methods of pest control:</h4>
        <p>
          Such methods have been used for a long time to tackle pest control
          issues within the household as well as in agricultural settings. The
          methods often involve the use of harsh chemicals to get rid of the
          undesired pests. It is important to know that such methods might have
          some serious residual effects that can harm humans as well as other
          organisms in that area.
        </p>
        <h3>Benefits of Hiring Professional Pest Control Services</h3>
        <ul className="ul_ff">
          <li>
            <b>Trained and expert professionals:</b> The pest control teams are
            trained, and they are expert in treating general pests along with
            handling poisonous chemicals which are used to kill the pests.
          </li>
          <li>
            <b>Reasonably priced service:</b> Pest control services know
            precisely what to look for, and the price of their services is
            reasonable as compared to the repairing due to damage caused by
            prolonged pest infestation.
          </li>
          <li>
            <b>Prevent inconvenience:</b> Pests can spread dreadful diseases,
            cause severe allergies all over the body, and destroy your valuable
            possessions. But, if you hire a reliable pest control service to
            eradicate them, then you can prevent all this inconvenience.
          </li>
          <li>
            <b>Effective and timely treatment:</b> Pest infestation takes time,
            and using pest control chemicals strategically on time is the key to
            successful treatment. If you are planning to do it on your own, you
            may easily forget the right time to retreat from your home.
            Professional pest control service providers can monitor and mark
            every time they do the treatment. They also know when to repeat the
            treatment before the chemical stops working.
          </li>
          <li>
            <b>Safe for family and pets:</b> Professional exterminators know how
            to deal with potential infestations and the risks associated with
            the treatment. Plus, you can be assured that your family members and
            pets will be completely safe from harmful pest treatment chemicals.
          </li>
        </ul>
        <h3>Pest Service Cost in Bangalore Cleaninger</h3>
        <p>
          (Note: Prices may vary depending on the number of sessions, products
          used, level of infestation, type of property, etc. For more clarity,
          get in touch with professional experts)
        </p>
        <table className="table_cost">
          <thead>
            <tr>
              <th>Type of pest </th>
              <th>1 BHK </th>
              <th>2 BHK </th>
              <th>3 BHK </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Ants</td>
              <td>Rs. 600 - 800</td>
              <td>Rs. 950 - 1,150</td>
              <td>Rs. 1,200 - 1,500</td>
            </tr>
            <tr>
              <td>Bed Bugs</td>
              <td>Rs. 550 - 750</td>
              <td>Rs. 750 - 900</td>
              <td>Rs. 950 - 1,000</td>
            </tr>
            <tr>
              <td>Bee Hive Removal</td>
              <td>Rs. 900 - 1,100</td>
              <td>Rs. 1,100 - 1,300</td>
              <td>Rs. 1,200 - 1,400</td>
            </tr>
            <tr>
              <td>Birds Netting</td>
              <td>Rs. 1,100 - 1,400</td>
              <td>Rs. 1,500 - 1,800</td>
              <td>Rs. 1,850 - 2,000</td>
            </tr>
            <tr>
              <td>Cockroaches</td>
              <td>Rs. 450 - 650</td>
              <td>Rs. 650 - 800</td>
              <td>Rs. 1,850 - 2,000</td>
            </tr>
            <tr>
              <td>Flies</td>
              <td>Rs. 400 - 600</td>
              <td>Rs. 600 - 800</td>
              <td>Rs. 800 - 950</td>
            </tr>
            <tr>
              <td>Lizards</td>
              <td>Rs. 450 - 650</td>
              <td>Rs. 650 - 800</td>
              <td>Rs. 1,850 - 2,000</td>
            </tr>
            <tr>
              <td>Mosquitoes</td>
              <td>Rs. 600 - 800</td>
              <td>Rs. 950 - 1,150</td>
              <td>Rs. 1,200 - 1,500</td>
            </tr>
            <tr>
              <td>Rodents</td>
              <td>Rs. 450 - 650</td>
              <td>Rs. 650 - 800</td>
              <td>Rs. 1,850 - 2,000</td>
            </tr>
            <tr>
              <td>Termites</td>
              <td>Rs. 900 - 1,100</td>
              <td>Rs. 1,100 - 1,300</td>
              <td>Rs. 1,200 - 1,400</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="home_bt_section2">
        <h2>Frequently Asked Questions</h2>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="tab__title">
              How much does pest control cost in Bangalore?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <p className="faq_txt">
                The rates are mostly dependent on factors such as the size of
                the property and the range of services you require. General pest
                control services may start from Rs. 800 and may go upwards of
                Rs. 2000 for larger properties. Based on pests & property size,
              </p>
              <p className="faq_txt">
                <b>Approx price range in Bangalore:</b>
              </p>
              <ul className="ul_ff">
                <li>Ants Pest Control Service: Rs. 600 - 1,500</li>
                <li>Bed Bugs Control Service: Rs.550 - 1,000</li>
                <li>Bee Hive Removal: Rs.900 - 1,400</li>
                <li>Cockroaches Control Service: Rs.450 - 2,000</li>
                <li>Flies Pest Control Service: Rs.400 - 950</li>
                <li>Lizards Pest Control Service: Rs.450 - 2,000</li>
                <li>Mosquitoes Control Service: Rs.600 - 1,500</li>
                <li>Rodents Pest Control Service: Rs.450 - 2,000</li>
                <li>Termites Pest Control Service: Rs.900 - 1,400</li>
              </ul>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="tab__title">
              Why I choose Cleaninger for pest control service?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <p className="faq_txt">
                Cleaninger offers a list of best pest control services providers in
                Bangalore, we can help you get rid of harmful pests like Ants,
                Bed Bugs, Bee Hive Removal, Cockroaches, Flies, Mosquitoes,
                Rodents, etc. You can select based on reviews, Cleaninger score &
                get addresses, contact details. To get instant Cleaninger expert
                assistance by simply filling the form.
              </p>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="tab__title">
              What are the best methods of pest control?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <p className="faq_txt">
                There are many types of methods handling in pest control
                service. Here the common 6 types.
              </p>
              <ul className="ul_ff">
                <li>Hygienic Method</li>
                <li>Biological Method</li>
                <li>Chemical Method</li>
                <li>Physical Method</li>
                <li>Fumigation / Fogging</li>
                <li>Heat treatment</li>
              </ul>
              <p className="faq_txt">
                The type of method is determined by the type of pest, level of
                infestation and access to the affected area. In many cases, more
                than one type of method may be used.
              </p>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="tab__title">
              What kinds of pests should I be worried about?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <ul className="ul_ff">
                <li>
                  Mosquitoes: Mosquitoes thrive in places with serious water
                  stagnation issues. These flying creatures don't just bite and
                  sting; they can also cause serious diseases such as dengue and
                  malaria.
                </li>
                <li>
                  Termites: Termites can give you severe allergies. These pests
                  also feed on wood and may cause a lot of damage to your
                  furniture.
                </li>
                <li>
                  Cockroaches: Cockroaches can cause some serious ailments like
                  dysentery and typhoid.
                </li>
                <li>
                  Bedbugs: While bed bugs don't cause severe diseases like
                  mosquitoes, termites, and cockroaches, they can bring about
                  severe allergic reactions.
                </li>
              </ul>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="tab__title">
              Sign indicates need for pest control services?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <ul className="ul_ff">
                <li>
                  Signs of Structural Damage: When you see the structure of your
                  residence or your workplace change, and not for the better,
                  you can be sure that it is the work of some of the other pest
                  at play.
                </li>
                <li>
                  Strange Smells: A pest infestation usually results in strange
                  smells. Oily and musty smells for no reason can be a sure-fire
                  sign of pests in the vicinity.
                </li>
                <li>
                  Hives and Nests: When you can see hives and nests in and
                  around your house, it means that there are a lot of pests
                  doing their dirty work. In such a circumstance, you should not
                  wait a moment longer, and call pest control in Bangalore to
                  help you.
                </li>
              </ul>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="tab__title">
              How many times pest control should be done?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <p className="faq_txt">
                Usually, pest control treatments are varied based on pests.
                Normal infestations 6 months once is enough. But in more serious
                cases, a monthly treatment may be required to get the situation
                better under control.
              </p>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="tab__title">
              How often should you spray your house for bugs?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <p className="faq_txt">
                We recommend applying a liquid insecticide around the perimeter
                of your home or structure at least once every 90 days
              </p>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="tab__title">
              What is the best time to spray for bugs?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <p className="faq_txt">
                The best time to have your home sprayed is in early spring.
              </p>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="tab__title">
              Where should I spray for bug spray in my house?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <p className="faq_txt">
                Apply to corners, storage areas, around water pipes, doors and
                windows, behind and under refrigerators, cabinets, sinks,
                stoves, under shelves, baseboards, drawers and similar areas.
              </p>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="tab__title">
              Can kids and pets be present during residential pest control
              treatment?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <p className="faq_txt">
                Kids and pets can stay at home during the pest control
                treatment; however, they need to be away from that area which is
                being treated.
              </p>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}

export default FooterText;
