import React, { useState } from "react";
import "./../Assets/css/Comman.css";
import "./../Assets/css/Login.css";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import { apis, site_settings } from "./../properties";
import { useHistory, Link } from "react-router-dom";
import { PostData } from "./../ApiService/PostData";
import { useToasts } from "react-toast-notifications";
import ScaleLoader from "react-spinners/ScaleLoader";
import FormHelperText from "@material-ui/core/FormHelperText";
import Helmet from "react-helmet";

function ResetPassword(prams) {
  const history = useHistory();
  const { addToast } = useToasts();
  const seoData = {
    title: "Reset Password",
    description: "Reset Password",
    url: window.location.href,
  };
  const [loginData, setLoginData] = useState({
    password: "",
    confirmPassword: "",
    code: prams.match.params.id,
    showPassword1: false,
    showPassword2: false,
  });
  const [loginDataError, setLoginDataError] = useState({
    password: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);

  const userLogin = (event) => {
    event.preventDefault();
    const isValid = formValidation();
    if (isValid) {
      setLoading(true);
      PostData(apis.RESET_PASSWORD, loginData).then((result) => {
        let responseJson = result;
        if (responseJson.errors) {
          let errorArr = responseJson.errors;
          for (let x = 0; x < errorArr.length; x++) {
            addToast(errorArr[x].detail, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        } else {
          if (responseJson.status === "success") {
            addToast("Success! Your password has been reset", {
              appearance: "success",
              autoDismiss: true,
            });
            history.push("/login");
          } else if (responseJson.status === "error") {
            addToast("Error! This link is not working. Try again", {
              appearance: "error",
              autoDismiss: true,
            });
          }
        }
        setLoading(false);
      });
    }
  };

  const formValidation = () => {
    let loginError = {};
    let isValid = true;
    if (loginData.password === "") {
      loginError.password = "The New password can not be empty";
      isValid = false;
    }
    if (loginData.confirmPassword === "") {
      loginError.confirmPassword = "The confirm password can not be empty";
      isValid = false;
    }

    if (loginData.password !== loginData.confirmPassword) {
      loginError.confirmPassword = "The confirm password not match";
      isValid = false;
    }
    setLoginDataError(loginError);

    return isValid;
  };

  const handleClickShowPassword = () => {
    setLoginData({ ...loginData, showPassword1: !loginData.showPassword1 });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword2 = () => {
    setLoginData({ ...loginData, showPassword2: !loginData.showPassword2 });
  };

  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };

  return (
    <div className="login__container container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{seoData.title} | Cleaninger</title>
        <meta name="description" content={seoData.description} />
        <meta property="og:title" content={seoData.title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seoData.url} />
        <link rel="canonical" href={seoData.url} />
      </Helmet>
      <div className="login_box">
        <div className="login_title">
          <h2>Change your Password</h2>
          <p className="back_url">
            or{" "}
            <span>
              <Link className="link" to="/login">
                login to your account
              </Link>
            </span>
          </p>
        </div>
        <form>
          <div className="login__fields">
            <FormControl
              variant="outlined"
              className="full-with"
              {...(loginDataError.password && {
                error: true,
                helperText: loginDataError.password,
              })}
            >
              <InputLabel htmlFor="outlined-adornment-password">
                New Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                label="New Password"
                type={loginData.showPassword1 ? "text" : "password"}
                value={loginData.password}
                onChange={(event) =>
                  setLoginData({
                    ...loginData,
                    password: event.target.value,
                  })
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {loginData.showPassword1 ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
              <FormHelperText>{loginDataError.password}</FormHelperText>
            </FormControl>
          </div>
          <div className="login__fields">
            <FormControl
              variant="outlined"
              className="full-with"
              {...(loginDataError.confirmPassword && {
                error: true,
                helperText: loginDataError.confirmPassword,
              })}
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Confirm Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                label="Confirm Password"
                type={loginData.showPassword2 ? "text" : "password"}
                value={loginData.confirmPassword}
                onChange={(event) =>
                  setLoginData({
                    ...loginData,
                    confirmPassword: event.target.value,
                  })
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword2}
                      onMouseDown={handleMouseDownPassword2}
                      edge="end"
                    >
                      {loginData.showPassword2 ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
              <FormHelperText>{loginDataError.confirmPassword}</FormHelperText>
            </FormControl>
          </div>
          <div>
            <ScaleLoader
              height={30}
              color={site_settings.LoaderColor}
              loading={loading}
            />
            <Button
              type="submit"
              variant="contained"
              className="primary__btn lg-btn"
              onClick={userLogin}
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
