import React, { useEffect } from "react";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { apis } from "../../properties";

const $ = require("jquery");
$.DataTable = require("datatables.net-dt");

function PurchaseRecord() {
  useEffect(() => {
    initDatable();
  }, []);
  const initDatable = () => {
    $(document).ready(function () {
      $("#customerTable").DataTable({
        language: {
          zeroRecords: "Nothing found - Sorry",
          infoEmpty: "No records available",
          infoFiltered: "(filtered from _MAX_ total records)",
        },
        order: [[0, "desc"]],
        deferRender: true,
        ajax: {
          url: apis.GET_PURCHASED_CREDITS,
          type: "get",
          contentType: "application/json; charset=UTF-8",
          dataSrc: "data",
          headers: {
            Authorization:
              "Bearer " +
              JSON.parse(sessionStorage.getItem("userData")).access_token,
          },
        },
        columns: [
          {
            data: "id",
            visible: false
          }, 
          {
            data: "txnid",
          },
          { data: "user_name", className: "dt-center" },
          { data: "status", className: "dt-center" },
          {
            render: function (data, type, full, meta) {
              return new Date(full.updated_at).toLocaleString();
            },
            className: "dt-center",
          },
          { data: "packs", className: "dt-center" },
          {
            render: function (data, type, full, meta) {
              return full.amount.toFixed(2);
            },
            className: "dt-right",
          },
        ],
      });
    });
  };
  return (
    <div className="purchase-recodes">
      <table id="customerTable" className="display" width="100%">
        <thead>
          <tr>
            <th>ID</th>
            <th>Transaction ID</th>
            <th>User Name</th>
            <th>Status</th>
            <th>Pay Date</th>
            <th>Coins Count</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
  );
}

export default PurchaseRecord;
