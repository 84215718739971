import React from "react";
import "./../Assets/css/Header.css";
import "./../Assets/css/Comman.css";
import FaceIcon from "./faceIcon";
import Fade from "@material-ui/core/Fade";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import { useHistory, Link } from "react-router-dom";
import Auth from "../Auth";

import DashboardIcon from "@material-ui/icons/Dashboard";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import FavoriteIcon from "@material-ui/icons/Favorite";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import AssessmentIcon from "@material-ui/icons/Assessment";
import LockIcon from "@material-ui/icons/Lock";
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import BookmarksIcon from '@mui/icons-material/Bookmarks';

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

function HeaderOptions() {
  const history = useHistory();
  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const handleClickProfile = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl2(null);
  };

  const logOut = () => {
    sessionStorage.removeItem("AuthData");
    sessionStorage.removeItem("userData");
    Auth.logout(() => {
      history.push("/login");
    });
    window.location.reload();
    setAnchorEl2(null);
  };

  return (
    <div>
      <div
        aria-controls="fade-menu"
        aria-haspopup="true"
        onClick={handleClickProfile}
      >
        <FaceIcon />
      </div>
      <StyledMenu
        id="fade-menu"
        anchorEl={anchorEl2}
        keepMounted
        open={Boolean(anchorEl2)}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <Link to="/dashboard" className="link">
          <StyledMenuItem onClick={handleClose} className="header_option">
            <ListItemIcon>
              <DashboardIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText className="mn-txt" primary="DASHBOARD" />
          </StyledMenuItem>
        </Link>
        <Link to="/user-ads" className="link">
          <StyledMenuItem onClick={handleClose} className="header_option">
            <ListItemIcon>
              <FavoriteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText className="mn-txt" primary="YOUR ADS" />
          </StyledMenuItem>
        </Link>
        <Link to="/user-credits" className="link">
          <StyledMenuItem onClick={handleClose} className="header_option">
            <ListItemIcon>
              <AccountBalanceWalletIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText className="mn-txt" primary="COINS" />
          </StyledMenuItem>
        </Link>
        <Link to="/packages" className="link">
          <StyledMenuItem onClick={handleClose} className="header_option">
            <ListItemIcon>
              <AssessmentIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText className="mn-txt" primary="PACKAGES" />
          </StyledMenuItem>
        </Link>
        <Link to="/user-profile" className="link">
          <StyledMenuItem onClick={handleClose} className="header_option">
            <ListItemIcon>
              <AccountCircleIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText className="mn-txt" primary="PROFILE" />
          </StyledMenuItem>
        </Link>
        <StyledMenuItem onClick={logOut} className="logout header_option">
          <ListItemIcon>
            <LockIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText className="mn-txt" primary="LOGOUT" />
        </StyledMenuItem>
        <Link to="/booking" className="link">
          <StyledMenuItem onClick={handleClose} className="post_ads_menu_book">
            <ListItemIcon>
              <BookmarksIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText className="mn-txt2" primary="BOOK NOW" />
          </StyledMenuItem>
        </Link>
        <Link to="/post-ads" className="link">
          <StyledMenuItem onClick={handleClose} className="post_ads_menu">
            <ListItemIcon>
              <AddToPhotosIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText className="mn-txt2" primary="POST ADS" />
          </StyledMenuItem>
        </Link>
      </StyledMenu>
    </div>
  );
}

export default HeaderOptions;
