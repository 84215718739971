import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CKEditor from "ckeditor4-react";
import React, { useEffect, useState } from "react";
import S3 from "react-aws-s3";
import { useHistory } from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useToasts } from "react-toast-notifications";
import { GetData } from "../../ApiService/GetData";
import { PostDataAuth } from "../../ApiService/PostData";
import Auth from "../../Auth";
import { apis, S3config, site_settings } from "../../properties";
import "./../../Assets/css/Comman.css";
import "./../../Assets/css/PostAds.css";
import ImageUploader from "./ImageUploaderApp";
import StepsBar from "./StepsBar";
import InputAdornment from "@material-ui/core/InputAdornment";
import Helmet from "react-helmet";

let imgeList = [];
let upImgesList = [];
function PostAdd() {
  const ReactS3Client = new S3(S3config);
  const { addToast } = useToasts();
  const history = useHistory();
  const seoData = {
    title: "Post Ads",
    description: "Post Ads",
    url: window.location.href,
  };
  const [loading, setLoading] = useState(false);
  const [disableCity, setDisableCity] = useState(true);
  const [postData, setPostData] = useState({
    category_id: "",
    city_id: "",
    state_id: "",
    zip: "",
    area: "",
    address: "",
    ad_title: "",
    ad_discription: "",
    imageList: [],
    supCateLists: [],
    age: "",
    contact_no: "",
    email: "",
    whatsapp: false,
  });
  const [postDataError, setPostDataError] = useState({});
  const [categoryList, setCategoryListItem] = useState([]);
  const [CityList, setCityListItem] = useState([]);
  const [regionsList, setRegionsListItem] = useState([]);
  const [supCateList, setSupCateList] = useState([]);
  const [supCateSelected, setSupCateSelected] = useState([]);
  const [checkBox] = React.useState(
    <div>
      <p className="whatsapp-icon">WHATSAPP </p>
    </div>
  );
  useEffect(() => {
    setCategoryList();

    setRegionsList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setCategoryList = async () => {
    GetData(apis.GET_CATEGORY_LIST).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        setCategoryListItem(responseJson.data);
      } else {
        addToast(responseJson.message, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    });
  };

  const setRegionsList = async () => {
    GetData(apis.GET_REGIONS_LIST).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        setRegionsListItem(responseJson.data);
      }
    });
  };

  const setCityList = async (id) => {
    GetData(`${apis.GET_CITIES_LIST}/${id}`).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        setCityListItem(responseJson.data);
      } else {
        addToast(responseJson.message, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    });
  };

  const setSubCateData = async (id) => {
    GetData(`${apis.GET_SUB_CATEGORY}/${id}`).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        setSupCateList(responseJson.data);
        setSupCateSelected([]);
      }
    });
  };

  const categoryChange = (event) => {
    if (event !== null || event) {
      postData.category_id = event.id;
      setSubCateData(event.id);
    } else {
      postData.category_id = "";
    }
  };

  const stateChange = (event) => {
    if (event !== null || event) {
      postData.state_id = event.id;
      setCityList(event.id);
      setDisableCity(false);
    } else {
      postData.state_id = "";
      postData.city_id = "";
      setDisableCity(true);
    }
  };
  const cityChange = (event) => {
    if (event !== null || event) {
      postData.city_id = event.id;
    } else {
      postData.city_id = "";
    }
  };

  const upImageList = (e, responce) => {
    imgeList = responce;
  };

  const nextStep = () => {
    if (Auth.isAuthenticated()) {
      const isValid = formValidation();
      if (isValid) {
        setLoading(true);

        if (imgeList.length > 0) {
          for (let x = 0; x < imgeList.length; x++) {
            uploadImgeS3(imgeList[x], x);
          }
        } else {
          setImages();
        }
      }
    } else {
      addToast(
        "You should log into your account. Please log in to your account and try again.",
        {
          appearance: "info",
          autoDismiss: true,
        }
      );
      history.push("/login");
    }
  };

  const insertAdsData = () => {
    if (supCateSelected.length > 0) {
      postData.supCateLists = supCateSelected;
      PostDataAuth(apis.INSERT_POST, postData).then((result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          setLoading(false);
          imgeList = [];
          upImgesList = [];
          history.push({
            pathname: `/promote-post/${responseJson.data.id}`,
          });
          clearForm();
        } else {
          addToast(responseJson.message, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
    } else {
      addToast("Please select a service", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const clearForm = () => {
    postData.category_id = "";
    postData.city_id = "";
    postData.state_id = "";
    postData.zip = "";
    postData.area = "";
    postData.address = "";
    postData.ad_title = "";
    postData.ad_discription = "";
    postData.imageList = [];
    postData.age = "";
    postData.contact_no = "";
    postData.whatsapp = false;
  };

  const formValidation = () => {
    let DataError = {};
    let isValid = true;
    var pattern = new RegExp(/^\d{10}$/);
    if (postData.category_id === "") {
      DataError.category_id = "The category cannot be empty";
      isValid = false;
    }
    if (postData.state_id === "") {
      DataError.state_id = "The state cannot be empty";
      isValid = false;
    }
    if (postData.city_id === "") {
      DataError.city_id = "The city cannot be empty";
      isValid = false;
    }
    if (postData.contact_no === "") {
      DataError.contact_no = "The contact number cannot be empty";
      isValid = false;
    }
    if (!pattern.test(postData.contact_no)) {
      DataError.contact_no = "The contact number undefined";
      isValid = false;
    }
    if (postData.ad_title === "") {
      DataError.ad_title = "The ads title cannot be empty";
      isValid = false;
    }
    if (postData.ad_discription === "") {
      addToast("The ads description cannot be empty", {
        appearance: "error",
        autoDismiss: true,
      });
      isValid = false;
    }

    if (imgeList.length > 10) {
      addToast("Maximum image count is 10", {
        appearance: "error",
        autoDismiss: true,
      });
      isValid = false;
    }
    setPostDataError(DataError);
    return isValid;
  };

  const setImages = () => {
    if (imgeList.length > 0) {
      postData.imageList = upImgesList;
    }
    insertAdsData();
  };

  const uploadImgeS3 = (file, x) => {
    ReactS3Client.uploadFile(file)
      .then((data) => {
        upImgesList.push(data.key);
        if (x === imgeList.length - 1) {
          setImages();
        }
      })
      .catch((err) => {
        addToast("Image Upload error", {
          appearance: "error",
          autoDismiss: true,
        });
        console.log(err);
      });
  };
  const onEditorChange = (evt) => {
    postData.ad_discription = evt.editor.getData();
  };

  const handleChange = (event) => {
    var object = {};
    object.type = event.target.checked;
    object.id = parseInt(event.target.name);
    object.name = event.target.value;
    var b = true;
    if (supCateSelected.length > 0) {
      for (let x = 0; x < supCateSelected.length; x++) {
        if (parseInt(event.target.name) === supCateSelected[x].id) {
          console.log(supCateSelected[x].id);
          supCateSelected[x].type = event.target.checked;
          b = false;
        }
      }
    }

    if (b) {
      setSupCateSelected([...supCateSelected, object]);
    }
  };

  return (
    <div className="container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{seoData.title} | Cleaninger</title>
        <meta name="description" content={seoData.description} />
        <meta property="og:title" content={seoData.title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seoData.url} />
        <link rel="canonical" href={seoData.url} />
      </Helmet>
      <div className="post-ads-section">
        {!Auth.isAuthenticated() && (
          <div className="alert_row">
            <Alert severity="warning">
              Hey, You should login to the Cleaninger. Then you can post your
              ads.
            </Alert>
          </div>
        )}

        <StepsBar step={0} />
        <div className="steps_one_section">
          <p className="txt">INSERT YOUR AD</p>
          <div className="fild_sp">
            <FormControl
              variant="outlined"
              size="small"
              className="full-with"
              {...(postDataError.category_id && {
                error: true,
              })}
            >
              <Autocomplete
                required
                size="small"
                className="full-with"
                options={categoryList}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                  categoryChange(newValue);
                }}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select category"
                    variant="outlined"
                  />
                )}
              />
              <FormHelperText>{postDataError.category_id}</FormHelperText>
            </FormControl>
          </div>
          <div className="fild_sp">
            {supCateList.map((item, index) => (
              <div key={index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name={item.id}
                      value={item.name}
                      onChange={handleChange}
                    />
                  }
                  label={item.name}
                />
              </div>
            ))}
          </div>
          <div className="fild_sp">
            <FormControl
              variant="outlined"
              size="small"
              required
              className="full-with"
              {...(postDataError.state_id && {
                error: true,
              })}
            >
              <Autocomplete
                required
                size="small"
                className="full-with"
                options={regionsList}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                  stateChange(newValue);
                }}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select State"
                    variant="outlined"
                  />
                )}
              />
              <FormHelperText>{postDataError.state_id}</FormHelperText>
            </FormControl>
          </div>
          <div className="fild_sp">
            <FormControl
              variant="outlined"
              size="small"
              required
              className="full-with"
              {...(postDataError.city_id && {
                error: true,
              })}
            >
              <Autocomplete
                required
                size="small"
                className="full-with"
                disabled={disableCity}
                options={CityList}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                  cityChange(newValue);
                }}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select City"
                    variant="outlined"
                  />
                )}
              />
              <FormHelperText>{postDataError.city_id}</FormHelperText>
            </FormControl>
          </div>
          <div className="fild_sp cal-2">
            <TextField
              label="ZIP"
              className="full-with cal-2-1-with"
              variant="outlined"
              size="small"
              value={postData.zip}
              onChange={(event) =>
                setPostData({ ...postData, zip: event.target.value })
              }
            />
            <TextField
              label="AREA / DISTRICT / NEIGHBOURHOOD"
              className="full-with cal-2-2-with"
              variant="outlined"
              size="small"
              value={postData.area}
              onChange={(event) =>
                setPostData({ ...postData, area: event.target.value })
              }
            />
          </div>
          <div className="fild_sp">
            <TextField
              label="Address"
              className="full-with"
              variant="outlined"
              size="small"
              value={postData.address}
              onChange={(event) =>
                setPostData({ ...postData, address: event.target.value })
              }
            />
          </div>
          <div className="fild_sp">
            <TextField
              id="outlined-multiline-static"
              label="Title"
              className="full-with"
              variant="outlined"
              size="small"
              multiline
              required
              rowsMax={10}
              value={postData.ad_title}
              onChange={(event) =>
                setPostData({ ...postData, ad_title: event.target.value })
              }
              {...(postDataError.ad_title && {
                error: true,
                helperText: postDataError.ad_title,
              })}
            />
          </div>
          <div className="fild_sp">
            <p className="des_txt">Description *</p>
            <CKEditor
              data={postData.ad_discription}
              config={{
                toolbar: [
                  [
                    "Cut",
                    "Copy",
                    "Paste",
                    "PasteText",
                    "PasteFromWord",
                    "-",
                    "Undo",
                    "Redo",
                    "Bold",
                    "Italic",
                    "NumberedList",
                    "BulletedList",
                  ],
                ],
              }}
              onChange={onEditorChange}
            />
          </div>
        </div>
        <div className="steps_one_section">
          <p className="txt">IMAGES</p>
          <p className="sub-dis">
            You can upload your 10 pictures
            <br />
            <b>100% guaranteed by Cleaninger</b>
          </p>
          <ImageUploader upImageList={upImageList} limit={10} />
        </div>

        <div className="steps_one_section">
          <p className="txt">YOUR CONTACTS</p>
          <div className="fild_sp">
            <TextField
              label="Contact Number"
              className="full-with"
              variant="outlined"
              size="small"
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+91</InputAdornment>
                ),
              }}
              value={postData.contact_no}
              onChange={(event) =>
                setPostData({
                  ...postData,
                  contact_no: event.target.value,
                })
              }
              {...(postDataError.contact_no && {
                error: true,
                helperText: postDataError.contact_no,
              })}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={postData.whatsapp}
                  onChange={(event) =>
                    setPostData({
                      ...postData,
                      whatsapp: event.target.checked,
                    })
                  }
                  color="primary"
                />
              }
              label={checkBox}
            />
          </div>
          <div className="fild_sp">
            <TextField
              label="Email"
              className="full-with"
              variant="outlined"
              size="small"
              required
              value={postData.email}
              onChange={(event) =>
                setPostData({
                  ...postData,
                  email: event.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="btn-line">
          <Button className="primary__btn" onClick={nextStep}>
            CONTINUE
          </Button>{" "}
          &emsp;
          <ScaleLoader
            height={30}
            color={site_settings.LoaderColor}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
}

export default PostAdd;
