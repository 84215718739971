import create from "zustand";
import { devtools, persist } from "zustand/middleware";

let settingStore = (set) => ({
  filterBar: true,
  toggleFilterBar: () => set((state) => ({ filterBar: !state.filterBar })),
});

settingStore = devtools(settingStore);
settingStore = persist(settingStore, { name: "Setting" });


export const useSettingStore = create(settingStore)
