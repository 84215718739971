import React from "react";
import BarLoader from "react-spinners/BarLoader";

function Spinner({spinner}) {
  let widthFull = '100%';
  return (
    <div className="full-with">
      <BarLoader height={4} width={widthFull} className="full-with" color={"#398564"} loading={spinner} />
    </div>
  );
}

export default Spinner;
