import Button from "@material-ui/core/Button";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import React from "react";
import FooterText from "../Components/FooterText";
import { apis } from "../properties";

function Packages() {
  const buyNow = (amount, role) => {
    window.location.href = `${apis.GET_PACKAGE_BEFORE}?amount=${amount}&pay_type=cleaninger`;
  };

  return (
    <>
      <div className="packages_row container">
        <div className="package_box">
          <div className="package_box_header">
            <p>Basic Plan</p>
            <h4>610</h4>
            <span>RUPEES PER YEAR</span>
          </div>
          <div className="package_box_body">
            <ul>
              <li>
                <CheckCircleOutlineIcon />
                <span>4 to 5 Meetings Per Month Or 30 Free Coins</span>
              </li>
              <li>
                <CheckCircleOutlineIcon />
                <span>Your Profile will not be made visible online</span>
              </li>
              <li>
                <CheckCircleOutlineIcon />
                <span>1 Year Validity</span>
              </li>
            </ul>
          </div>
          <div className="package_box_footer">
            <Button
              variant="contained"
              className="primary__btn"
              color="secondary"
              onClick={() => buyNow(610)}
            >
              BUY NOW
            </Button>
          </div>
        </div>
        <div className="package_box">
          <div className="package_box_header">
            <p>Standard Plan</p>
            <h4>3010</h4>
            <span>RUPEES PER YEAR</span>
          </div>
          <div className="package_box_body">
            <ul>
              <li>
                <CheckCircleOutlineIcon />
                <span>9 to 10 Meetings Per Month Or 100 Free Coins</span>
              </li>
              <li>
                <CheckCircleOutlineIcon />
                <span>Your Profile will not be made visible online</span>
              </li>
              <li>
                <CheckCircleOutlineIcon />
                <span>1 Year Validity</span>
              </li>
            </ul>
          </div>
          <div className="package_box_footer">
            <Button
              variant="contained"
              className="primary__btn"
              color="secondary"
              onClick={() => buyNow(3010)}
            >
              BUY NOW
            </Button>
          </div>
        </div>

        <div className="package_box">
          <div className="package_box_header">
            <p>Premium Plan</p>
            <h4>6010</h4>
            <span>RUPEES PER YEAR</span>
          </div>
          <div className="package_box_body">
            <ul>
              <li>
                <CheckCircleOutlineIcon />
                <span>14 to 15 Meetings Per Month Or 200 Free Coins</span>
              </li>
              <li>
                <CheckCircleOutlineIcon />
                <span>Your Profile will not be made visible online</span>
              </li>
              <li>
                <CheckCircleOutlineIcon />
                <span>1 Year Validity</span>
              </li>
            </ul>
          </div>
          <div className="package_box_footer">
            <Button
              variant="contained"
              className="primary__btn"
              color="secondary"
              onClick={() => buyNow(6010)}
            >
              BUY NOW
            </Button>
          </div>
        </div>
      </div>
      <div className="footer_text_section container">
        <FooterText />
      </div>
    </>
  );
}

export default Packages;
