export function PostData(url, userData) {

    return new Promise((resolve, reject) => {
         fetch(url, {
            method: 'POST',
            body: JSON.stringify(userData),
            headers: {
                'content-type': 'application/json',
            }
        })
        .then((response) => response.json())
        .then((responseJson) => {
            resolve(responseJson);
        })
        .catch((error) => {
            reject(error);
        });
    });
}

export function PostDataAuth(url, userData) {
    
    return new Promise((resolve, reject) => {
         fetch(url, {
            method: 'POST',
            body: JSON.stringify(userData),
            headers: {
                'Authorization' : "Bearer " +  JSON.parse(sessionStorage.getItem("userData")).access_token,
                'content-type': 'application/json',
            }
        })
        .then((response) => response.json())
        .then((responseJson) => {
            resolve(responseJson);
        })
        .catch((error) => {
            reject(error);
        });
    });
}