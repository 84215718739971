import React from "react";
import Helmet from "react-helmet";

function TermsConditions() {
  const seoData  = {
    title: "Terms and Condition",
    description: "Terms and Condition",
    url: window.location.href,
  };

  return (
    <div className="container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{seoData.title} | Cleaninger</title>
        <meta name="description" content={seoData.description} />
        <meta property="og:title" content={seoData.title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seoData.url} />
        <link rel="canonical" href={seoData.url} />
      </Helmet>
      <div className="term-page">
        <h2 className="term-title">TERMS OF USE</h2>
        <p className="term-sub">Last Updated February 2019</p>
        <p className="term-txt">
         Legal name : CLEANINGER PRIVATE LIMITED <br/>
          We / us : CLEANINGER PRIVATE LIMITED  <br/>
          These Terms of Use are current as at the date shown above. We update
          our Terms of Use from time to time, and we advise you to check any
          changes since the last time you used the services offered through the
          cleaninger.in site (the "Site").
        </p>
        <p className="term-txt">
          Users of the Site comprise Offeror Users and Recipient Users, as
          defined below (together "Users").
        </p>
        <p className="term-txt">
          Offeror Users create content displayed on the Site and are entitled to
          upload advertisements and create profiles in accordance with the Paid
          Services terms below. Recipient Users have access to content placed by
          Offeror Users and are given the opportunity to contact Offeror Users
          via the contact information uploaded by such Offeror Users.
        </p>
        <p className="term-txt">
          Users are advised that continuing to browse the Site signifies consent
          to the Terms of Use current at the time when you visit the Site and
          that if you do not wish to be bound by the Terms of Use you should
          close the Site and cease to use the service.
        </p>
        <p className="term-txt">
          Our use of your personal data is governed by our Privacy Policy which
          also sets out our use of cookies and similar technology. We only
          gather the minimum personal data, in compliance with the applicable
          laws which is necessary for us to provide the service in accordance
          with these Terms of Use, to comply with legal obligations on us, and
          to fulfil our legitimate interests with respect to improving the
          service, extending the geographic coverage of the service and
          promoting the safety and well-being of our Users.
        </p>
        <p className="term-txt">
          If you wish to become an Offeror User, you will not be allowed to
          create a profile or to place an advertisement unless you confirm you
          accept the Terms of Use including the Acceptable Use Policy and
          Summary and Guidelines set out below at the time of placing that
          advertisement or creating that profile.
        </p>
        <p className="term-txt">
          Please note that the Site is operated by Nettolemo LDA and acts as a
          platform provider only (“we” or “us”). We do not exercise editorial
          control or pre-vet advertisements placed with us or User profiles
          created on the Site, although we will take down advertisements or
          profiles which:
        </p>
        <ul>
          <li className="term-txt">contravene these Terms of Use; or</li>
          <li className="term-txt">
            contain any malware or any other material which risks interfering
            with or degrading the proper performance of the Site; or
          </li>
          <li className="term-txt">
            we consider in our absolute discretion to be prejudicial to the
            proper performance of the service; or
          </li>
          <li className="term-txt">
            we consider are unlawful in nature (for instance anything which is
            harassing in nature, defamatory, obscene, pornographic, paedophilic,
            libellous, invasive of another's privacy, harm minors in any way) or
            may be in breach of any applicable criminal laws or regulations
            binding on us; or
          </li>
          <li className="term-txt">
            constitute or may constitute harassment or bullying of any other
            User or other third party; or
          </li>
          <li className="term-txt">
            are threatening, abusive or discriminatory; or
          </li>
          <li className="term-txt">
            are required by a judicial order from a competent court or competent
            government agency.
          </li>
        </ul>
        <p className="term-txt">
          The sections marked "Acceptable Use Policy" and "Summary and
          Guidelines" set out in more detail material which is considered to
          contravene these Terms of Use, although it is for Users in each case
          to determine for themselves the laws applicable to any material
          contributed by them to the Site and such material's compliance with
          the Terms of Use.
        </p>
        <p className="term-txt">
          We reserve the right to ban Users for serious or repeated breaches of
          these Terms of Use. In the event of any take down of any advertisement
          or banning of any User we will not, in any circumstances, be
          responsible for any losses, claims, damages or expenses whatsoever
          arising out of such take down or ban, and no refunds for any monies
          paid to us will be granted save in the limited circumstances set out
          in the "Refunds Policy" below.
        </p>
        <p className="term-sub">OBLIGATIONS AND BEHAVIOUR OF THE USER</p>
        <p className="term-txt">
          The service is an adult service and, by using the service, Users
          represent and warrant that they are 18 or over. Any misrepresentation
          by any User under 18 that they are 18 or over, or any circumvention of
          any age verification measures put in place by us will be a breach of
          these Terms of Use.
        </p>
        <p className="term-txt">
          As stated above, Recipient Users should be aware that we do not
          exercise advance control over the content of the online ads placed by
          Offeror Users. The Site is not an intermediary or agent of any Users,
          and is not in any respect responsible for any risks, claims or
          liabilities arising out of any subsequent supply of services or other
          connection between any Offeror User(s) and any Recipient User(s).
        </p>
        <p className="term-txt">
          By using the service, Users assume the full and exclusive
          responsibility for their behaviour towards the Site and towards third
          parties including but not limited to other Users.
        </p>
        <p className="term-txt">
          We are not responsible for and do not guarantee the veracity of the
          contents of any advertisements placed by Offeror Users, or the
          successful outcome of any negotiations between any Offeror User(s) and
          any Recipient User(s).
        </p>
        <p className="term-sub">Limitation of Liability</p>
        <p className="term-txt">
          Nothing in these Terms of Use applies to limit or exclude any
          liability of any party for death or personal injury caused by that
          person's negligence or any person for whom they are responsible, or
          for fraud, or for any other liability which may not be excluded or
          limited by law.
        </p>
        <p className="term-txt">
          The Site and its affiliates, and its or their affiliates, managers,
          employees, officers or directors do not accept any liability for:
        </p>
        <ul>
          <li className="term-txt">
            any loss or damage to the extent it arises from circumstances
            outside their reasonable control;
          </li>
          <li className="term-txt">
            any loss or damage caused by any interruption in or non-availability
            of the Site or the service;
          </li>
          <li className="term-txt">
            any direct or indirect loss of profits, business, reputation,
            anticipated savings or turnover;
          </li>
          <li className="term-txt">any loss or corruption of data;</li>
          <li className="term-txt">
            any indirect, special, punitive, statutory or consequential losses
            whatsoever even if it has been advised of the possibility of such
            losses.
          </li>
        </ul>
        <p className="term-txt">
          The Site's and its affiliates', and its or their affiliates, managers,
          employees, officers or directors' liability to Users for any other
          losses and damages, whether arising in contract, tort, breach of
          statutory duty or under any legal theory whatsoever arising out of or
          in connection with the Site and the services shall (without prejudice
          to the Refunds Policy set out below), in the case of paid for
          services, be capped at an amount equivalent to the higher of the sums
          paid by any User in the twelve months immediately prior to the event
          giving rise to the claim or £500 and in the case of any other
          services, £150.
        </p>
        <p className="term-txt">
          Each of the above provisions and sub-provisions are separate and
          independent provisions and a finding of nullity or unenforceability in
          respect of one provision or sub-provision shall not affect the
          enforceability of the other provisions which will remain in full force
          and effect.
        </p>
        <p className="term-sub">Paid Services: Offeror Users</p>
        <p className="term-txt">
          By publishing an advertisement on the Site (which includes creating an
          online profile), each Offeror User is authorizing us to promote it for
          its greater visibility in our discretion and to operate the service so
          as to facilitate maximum effectiveness in relevant geographical
          markets. By creating an online profile or placing an advertisement on
          the Site, Offeror Users confirm they are of legal age in the relevant
          jurisdiction, that they are the person identified in that profile and
          that they have not been coerced in any way.
        </p>
        <p className="term-txt">
          An advertisement published by an Offeror User shall not in any manner
          be a violation of the applicable laws and regulations of the
          jurisdiction/country of residence of the Offeror User. In the event of
          a breach of these terms, or a violation of the applicable laws and
          regulations, we reserve the right to take down the advertisement
          without refund, to delete any applicable profile and to ban Users from
          creating any future profiles or placing any future advertisements.
        </p>
        <p className="term-sub">Acceptable Use Policy</p>
        <p className="term-txt">
          Users undertake not to use the service provided by the Site either
          improperly or contrary to the provisions of applicable law,
          regulation, rules of ethics and good conduct of the network services.
          Users, in particular, undertake not to transmit through the Site
          material of offensive and libellous nature, discriminatory, harassing,
          defamatory, pornographic, paedophile, vulgar, blasphemous or which is
          in some way contrary to the principles of public order and good
          morals.
        </p>
        <p className="term-txt">
          Offeror Users are responsible for complying with all applicable laws
          with respect to the content of their advertisement. In particular,
          Users must not advertise any services or publish any data/content
          which it is unlawful to perform/publish within their jurisdiction.
        </p>
        <p className="term-txt">
          Offeror Users warrant that they are the data subjects of or that they
          have the explicit and informed consent of all persons whose personal
          data is included in any advertisement placed by them and that they
          will not use any material in their advertisements which contravenes
          the copyright or other intellectual property rights of any third
          person without having the permission of that third person.
        </p>
        <p className="term-txt">
          Offeror Users are aware that by including an email address in
          advertisements hosted by the Site, we have no control over any
          materials which may be sent directly to that email address, whether by
          other Users or otherwise, and whether in answer to that advertisement.
        </p>
        <p className="term-sub">Summary and Guidelines</p>
        <p className="term-txt">
          Offeror Users have their specific attention drawn to the following
          summary of the above Terms of Use. For the avoidance of doubt, we
          offer this summary as a convenience to our Users and it should not be
          taken to constitute legal advice as to when material is lawful to
          include in advertisements and profiles.
        </p>
        <ul className="term-txt">
          <li className="term-txt">
            Advertisements that contain references to sexual services in
            exchange for money are not allowed;
          </li>
          <li className="term-txt">
            Inserting pornographic pictures that contain obscene images or
            explicit images of genitalia or which otherwise contain material
            which would be obscene in nature and contrary to the provisions of
            the applicable laws and regulations is not allowed;
          </li>
          <li className="term-txt">
            Any material which contravenes the rules on "extreme pornography"
            including erotic material which depicts acts which appear to be
            non-consensual, which appear to involve animals, which appear to
            involve sexual acts with dead bodies, or which depict acts which
            appear to threaten a person's life or acts which result, or are
            likely to result, in serious injury to a person's anus, breasts or
            genitals are not allowed;
          </li>
          <li className="term-txt">
            Any material which makes use without each participant's consent of
            erotic material provided on private occasions ("revenge porn") is
            not allowed;
          </li>
          <li className="term-txt">
            Any material which depicts, promotes, induces or offers sexual
            activity with minors or which otherwise promotes or encourages
            paedophilia ("paedophile material") is not allowed;
          </li>
          <li className="term-txt">
            The insertion into any advertisement or profile of paedophile
            material will be immediately reported to the competent authorities,
            including all the access and meta data. We also reserve the right to
            report material which contravenes our rules on extreme pornography
            or revenge pornography;
          </li>
          <li className="term-txt">
            By placing advertisements or creating profiles, Users certify that
            they have full legal rights to do so, and also declare and warrant
            that the material uploaded belongs to persons of legal age (over 18)
            who have given their free and informed consent to publish them on
            the Site;
          </li>
          <li className="term-txt">
            Prior to publishing an advertisement on the Site (which includes
            creating an online profile), each Offeror User will confirm by means
            of the [https://hi.cleaninger.in/?lightbox=true], that they are of
            legal age in the relevant jurisdiction, that they are the person
            identified in that profile and that they have not been coerced in
            any way. Unless the Offeror User gives such confirmation, they will
            not be able to publish their advertisement or make use of the
            service;
          </li>
          <li className="term-txt">
            It is permitted to publish the same advertisement in a maximum of
            three different cities;
          </li>
          <li className="term-txt">
            It is not permitted to repeat the same advertisement in the same
            city during the same time period; and
          </li>
          <li className="term-txt">
            It is not permitted to include hypertext links in any advertisement
            or profile.
          </li>
        </ul>
        <p className="term-txt">
          Subject to these terms and conditions, we will operate the service in
          our sole discretion but with the object of facilitating maximum
          effectiveness in relevant geographical markets.
        </p>
        <p className="term-txt">
          These Terms of Use are governed by and interpreted in accordance with
          English law and are subject to the exclusive jurisdiction of the
          English Courts.
        </p>
      </div>
    </div>
  );
}

export default TermsConditions;
