import React from "react";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";

function PaymentSuccess(props) {
  const res_type = new URLSearchParams(props.location.search).get("type");
  const seoData = {
    title: "Payment Success",
    description: "Payment Success",
    url: window.location.href,
  };

  return (
    <div className="container paymnet-responce">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{seoData.title} | Cleaninger</title>
        <meta name="description" content={seoData.description} />
        <meta property="og:title" content={seoData.title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seoData.url} />
        <link rel="canonical" href={seoData.url} />
      </Helmet>
      <div>
        <CheckCircleOutlineIcon className="done-icon" />
      </div>
      <div className="done-txt">
        <h1>Payment Success</h1>
      </div>
      <div className="done-desc">
        {res_type === "FOR_CREDITS" ? (
          <p>
            Your coins has been successfully bought. You can check your profile.
            Have Fun!
          </p>
        ) : (
          <p>
            Your ad is successfully published. You can check your profile. Have
            Fun!
          </p>
        )}
      </div>
      <div className="btn-don-row">
        <div>
          <Link className="link" to="/user-ads">
            <Button variant="contained " className="primary__btn done-btn">
              My Profile
            </Button>
          </Link>
        </div>
        {/* &nbsp;&nbsp;&nbsp;
          <div>
            <Link className="link" to="/">
              <Button
                variant="contained "
                className="primary__btn_outline done-btn"
              >
                View My Post
              </Button>
            </Link>
          </div> */}
      </div>
    </div>
  );
}

export default PaymentSuccess;
