import React from "react";
import Helmet from "react-helmet";

function RefundPolicy() {
  const seoData = {
    title: "Refund Policy",
    description: "Refund Policy",
    url: window.location.href,
  };

  return (
    <div className="container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{seoData.title} | Cleaninger</title>
        <meta name="description" content={seoData.description} />
        <meta property="og:title" content={seoData.title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seoData.url} />
        <link rel="canonical" href={seoData.url} />
      </Helmet>
      <div className="term-page">
        {/* <h2 className="term-title">Paid Services: Offeror Users</h2> */}
        {/* <p className="term-sub">Last Updated February 2019</p> */}
        {/* <br />
        <p className="term-txt">
          By publishing an advertisement on the Site (which includes creating an
          online profile), each Offeror User is authorising us to promote it for
          its greater visibility in our discretion and to operate the service so
          as to facilitate maximum effectiveness in relevant geographical
          markets. By creating an online profile or placing an advertisement on
          the Site, Offeror Users confirm they are of legal age in the relevant
          jurisdiction, that they are the person identified in that profile and
          that they have not been coerced in any way.
        </p> */}
{/* 
        <p className="term-txt">
          An advertisement published by an Offeror User shall not in any manner
          be a violation of the applicable laws and regulations of the
          jurisdiction/country of residence of the Offeror User. In the event of
          a breach of these terms, or a violation of the applicable laws and
          regulations, we reserve the right to take down the advertisement
          without refund, to delete any applicable profile and to ban Users from
          creating any future profiles or placing any future advertisements.
        </p> */}

        {/* <p className="term-txt">
          Please note that CLEANINGER does not interfere in the negotiation
          between the Users.
        </p>
        <br /> */}
        <h3 className="term-title">Refunds Policy</h3>
        <br />
        <p className="term-txt">
          We do not refund monies paid for Paid Services to Offeror Users but
          may exceptionally and at our sole discretion offer credit for monies
          paid and unused to be used against future Paid Services. In particular
          (but without limitation)
        </p>
        <ul>
          <li className="term-txt">
            We reserve the right to intervene via the Site or to make any
            modifications or changes (e.g. design, focus and/or functional
            content) therein without prior notification, or to limit the Offeror
            User’s access to the website due to maintenance or technical or
            other reasons for a necessary period of time without prior
            notification. In such cases, no Offeror User is entitled to claim
            any compensation or refund pursuant to this policy based on such
            modification, changes or non-availability.
          </li>
          <li className="term-txt">
            If our payment provider receives a request from a financial
            institution or a platform to refund any financial amount spent on
            purchases of Paid Services, we reserve the right to block the
            Offeror User’s Profile upon the receipt of such request until we are
            informed by the payment provider that the issue has been fully
            resolved to the satisfaction of the payment provider and/or the
            financial institution or platform.
          </li>
        </ul>
        <br />
        <h3 className="term-title">Refunds</h3>
        <br />
        <p className="term-txt">
          The policy states that amounts paid for Services, in general, do not
          include refunds to Users. This means that once you've paid for a
          service, you may not be entitled to a refund.
        </p>
        <br />
        <h3 className="term-title">Exceptional Credit</h3>
        <br />
        <p className="term-txt">
          The policy provides for some exceptions, in which the company may, at
          its sole discretion, offer additional credit. This credit can be used
          for future Paid Services. However, it's important to note that this is
          not an automatic right but a discretionary measure.
        </p>
        <br />
        <p>QUESTIONS</p>
        <p className="term-txt">
          If you have any questions concerning our refund policy, please contact
          us at:
        </p>
        <p>
          <a href="tel:+918310728524">+91 83107 28524</a>
        </p>
        <p>cleaningerprivatelimited@gmail.com</p>
      </div>
    </div>
  );
}

export default RefundPolicy;
