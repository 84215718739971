import React from "react";
import "../../Assets/css/Comman.css";
import "../../Assets/css/Profile.css";
import BuyCredit from "./BuyCredits";
import PurchaseRecord from "./PurchaseRecord";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import DashboardOption from "../../Components/DashboardOption";
import AirplayIcon from "@material-ui/icons/Airplay";
import FavoriteIcon from "@material-ui/icons/Favorite";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import SettingsIcon from "@material-ui/icons/Settings";
import Helmet from "react-helmet";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} className="box-tab">
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Credits() {
  const seoData = {
    title: "Profile Coins",
    description: "Profile Coins",
    url: window.location.href,
  };
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{seoData.title} | Cleaninger</title>
        <meta name="description" content={seoData.description} />
        <meta property="og:title" content={seoData.title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seoData.url} />
        <link rel="canonical" href={seoData.url} />
      </Helmet>
      <div className="dashboard_menuBar container">
        <DashboardOption
          Icon={AirplayIcon}
          title="Dashboard"
          url="/dashboard"
          action={false}
        />
        <DashboardOption
          Icon={FavoriteIcon}
          title="Your Ads"
          url="/user-ads"
          action={false}
        />
        <DashboardOption
          Icon={AccountBalanceWalletIcon}
          title="Coins"
          url="/user-credits"
          action={true}
        />
        <DashboardOption
          Icon={SettingsIcon}
          title="Setting"
          url="/user-profile"
          action={false}
        />
      </div>
      <div className="container">
        <AppBar position="static" className="tab-style">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab className="tab-btn" label="Buy Coins" {...a11yProps(0)} />
            <Tab
              className="tab-btn"
              label="Purchase Record"
              {...a11yProps(1)}
            />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <BuyCredit />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PurchaseRecord />
        </TabPanel>
      </div>
    </div>
  );
}

export default Credits;
