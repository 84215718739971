import React from "react";
import "./App.css";
import "./Assets/css/Responsive.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./User/Home";
import Login from "./User/Login";
import SignUp from "./User/SignUp";
import ForgotPassword from "./User/ForgotPassword";
import ResetPassword from "./User/ResetPassword";
import SearchPage from "./User/SearchPage";
import PostAdd from "./User/PostAds/PostAdd";
import EditPostAdd from "./User/PostAds/EditPostAds";
import PromotePost from "./User/PostAds/PromotePost";
import UpdatePromoteAds from "./User/PostAds/UpdatePromoteAds";
import PublishPost from "./User/PostAds/PublishPost";
import PublishPostUpdate from "./User/PostAds/PublishPostUpdate";
import Results from "./User/Results";
import SingleView from "./User/SingleView";
import Verification from "./User/Verification";
import Dashboard from "./User/Account/Dashboard";
import Products from "./User/Account/Products";

import PaymentSuccess from "./User/PaymentSuccess";
import PaymentFail from "./User/PaymentFail";
import TermsConditions from "./User/TermsConditions";
import PrivacyPolicy from "./User/PrivacyPolicy";
import ContactUs from "./User/ContactUs";
import { ProtectedRoute } from "./ProtectedRoute";
import ScrollToTop from "./Components/ScrollToTop";
import NotFount from "./404";
import "../node_modules/slick-carousel/slick/slick.css";
import "../node_modules/slick-carousel/slick/slick-theme.css";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import Packages from "./User/Packages";
import RefundPolicy from "./User/RefundPolicy";
import Progress from "react-progress-2";
import "react-progress-2/main.css";
// import PackagesTest from "./User/PackagesTest";
import Booking from "./User/Booking";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Progress.Component />
      <div className="app">
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />

          <Route exact path="/login" component={Login} />

          <Route exact path="/forgot-password" component={ForgotPassword} />

          <Route exact path="/reset-password/:id" component={ResetPassword} />

          <Route exact path="/filter" component={SearchPage} />

          <Route exact path="/register" component={SignUp} />

          <Route exact path="/post-ads" component={PostAdd} />

          <Route exact path="/packages" component={Packages} />

          {/* <Route exact path="/packages-test" component={PackagesTest} /> */}

          <ProtectedRoute
            exact
            path="/promote-post/:id"
            component={PromotePost}
          />

          <ProtectedRoute
            exact
            path="/publish-post/:id"
            component={PublishPost}
          />

          <ProtectedRoute exact path="/edit-ads" component={EditPostAdd} />

          <ProtectedRoute
            exact
            path="/update-promote-post/:id"
            component={UpdatePromoteAds}
          />

          <ProtectedRoute
            exact
            path="/update-publish-post/:id"
            component={PublishPostUpdate}
          />

          <Route exact path="/ads/:category" component={Results} />

          <Route exact path="/ads/:category/:region" component={Results} />

          <Route
            exact
            path="/ads/:category/:region/:city"
            component={Results}
          />

          <Route exact path="/verification" component={Verification} />

          <Route
            exact
            path="/ads/:category/:region/:city/:id/:title"
            component={SingleView}
          />

          <ProtectedRoute exact path="/dashboard" component={Dashboard} />

          <ProtectedRoute exact path="/user-ads" component={Dashboard} />

          <ProtectedRoute exact path="/user-credits" component={Dashboard} />

          <ProtectedRoute exact path="/user-products" component={Products} />

          <ProtectedRoute exact path="/user-profile" component={Dashboard} />

          <Route
            exact
            path="/payment-success"
            component={PaymentSuccess}
          />

          <Route exact path="/payment-fail" component={PaymentFail} />

          <Route
            exact
            path="/terms-and-conditions"
            component={TermsConditions}
          />

          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/contact-us" component={ContactUs} />
          <Route exact path="/refund-policy" component={RefundPolicy} />
          <Route exact path="/booking" component={Booking} />

          <Route path="*" component={NotFount} />
        </Switch>
        <a href={`https://wa.me/+918431842873?text=Hi%20I%20need%20customer%20support%0D%0A%0D%0A${window.location.href}`} target="_blank" rel="noreferrer" className="fixedbutton">
          <WhatsAppIcon />
        </a>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
