export function GetData(url) {

    return new Promise((resolve, reject) => {
         fetch(url, {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
            }
        })
        .then((response) => response.json())
        .then((responseJson) => {
            resolve(responseJson);
        })
        .catch((error) => {
            reject(error);
        });
    });
}

export function GetDataAuth(url) {

    return new Promise((resolve, reject) => {
         fetch(url, {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
                'Authorization' : "Bearer " +  JSON.parse(sessionStorage.getItem("userData")).access_token,
            }
        })
        .then((response) => response.json())
        .then((responseJson) => {
            resolve(responseJson);
        })
        .catch((error) => {
            reject(error);
        });
    });
}
