import React, { useState, useEffect } from "react";
import "../../Assets/css/Comman.css";
import "../../Assets/css/Profile.css";
import ActiveAds from "./ActiveAds";
import NotPublishAds from "./NotPublishAds";
import ExpiredAds from "./ExpiredAds";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { GetDataAuth } from "../../ApiService/GetData";
import { apis } from "../../properties";
import Badge from "@material-ui/core/Badge";
import { useToasts } from "react-toast-notifications";
import DashboardOption from "../../Components/DashboardOption";
import AirplayIcon from "@material-ui/icons/Airplay";
import FavoriteIcon from "@material-ui/icons/Favorite";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import SettingsIcon from "@material-ui/icons/Settings";
import Helmet from "react-helmet";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
 
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function Ads() {
  const { addToast } = useToasts();
  const seoData = {
    title: "Profile Ads",
    description: "Profile Ads",
    url: window.location.href,
  };
  const [value, setValue] = React.useState(0);
  const [countData, setCountData] = useState({});

  useEffect(() => {
    getAdsCounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAdsCounts = () => {
    GetDataAuth(apis.ADS_COUNT).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        setCountData(responseJson.data);
      } else {
        addToast(responseJson.message, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{seoData.title} | Cleaninger</title>
        <meta name="description" content={seoData.description} />
        <meta property="og:title" content={seoData.title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seoData.url} />
        <link rel="canonical" href={seoData.url} />
      </Helmet>
      <div className="dashboard_menuBar container">
        <DashboardOption
          Icon={AirplayIcon}
          title="Dashboard"
          url="/dashboard"
          action={false}
        />
        <DashboardOption
          Icon={FavoriteIcon}
          title="Your Ads"
          url="/user-ads"
          action={true}
        />
        <DashboardOption
          Icon={AccountBalanceWalletIcon}
          title="Coins"
          url="/user-credits"
          action={false}
        />
        <DashboardOption
          Icon={SettingsIcon}
          title="Setting"
          url="/user-profile"
          action={false}
        />
      </div>
      <div className="container">
        <AppBar position="static" className="tab-style">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab
              className="tab-btn"
              label={
                <Badge
                  color="secondary"
                  badgeContent={countData ? countData.checkActive : 0}
                  max={999}
                >
                  <p>ACTIVE</p>
                </Badge>
              }
              {...a11yProps(0)}
            />
            <Tab
              className="tab-btn"
              label={
                <Badge
                  color="secondary"
                  badgeContent={countData ? countData.checkPending : 0}
                  max={999}
                >
                  <p>Not Published</p>
                </Badge>
              }
              {...a11yProps(1)}
            />
            <Tab
              className="tab-btn"
              label={
                <Badge
                  color="secondary"
                  badgeContent={countData ? countData.checkExdata : 0}
                  max={999}
                >
                  <p>Expired</p>
                </Badge>
              }
              {...a11yProps(2)}
            />
            {/* <Tab
              className="tab-btn"
              label={
                <Badge
                  color="secondary"
                  badgeContent={countData ? countData.checkDelete : 0}
                  max={999}
                >
                  <p>DELETE</p>
                </Badge>
              }
              {...a11yProps(3)}
            /> */}
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <ActiveAds />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <NotPublishAds />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ExpiredAds />
        </TabPanel>
        {/* <TabPanel value={value} index={3}>
          <DeletedAds />
        </TabPanel> */}
      </div>
    </div>
  );
}

export default Ads;
