import React from "react";
import "./../Assets/css/Header.css";
import "./../Assets/css/Comman.css";
import logo from "./../Assets/img/Fex_lg.png";
import { Link } from "react-router-dom";
import Auth from "../Auth";
import Button from "@material-ui/core/Button";
import HeaderOptions from "../Components/HeaderOptions";
import HeaderOptionsTwo from "../Components/HeaderOptionsTwo";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import { useSettingStore } from "../store";

function Header() {
  const toggleDarkMode = useSettingStore((state) => state.toggleFilterBar);

  return (
    <div className="header__container container">
      <Link to="/" className="header__logo_sec">
        <div className="header__logo__img">
          <img src={logo} alt="logo" />
        </div>
      </Link>
      <div className="header__btns">
        <div className="header__btn__login mb-none">
          <Link className="link menu_item" to="/packages">
            Packages
          </Link>
        </div>
        <div className="header__btn__login mb-none">
          <Link className="link menu_item" to="/refund-policy">
            Refund Policy
          </Link>
        </div>
       
        <div className="header__btn__login mb-none">
          {Auth.isAuthenticated() ? (
            <div></div>
          ) : (
            <Link className="link menu_item" to="/login">
              Login
            </Link>
          )}
        </div>
        <div className="header__btn__signup mb-none">
          {Auth.isAuthenticated() ? (
            <HeaderOptions />
          ) : (
            <Link className="link menu_item" to="/register">
              Sign up
            </Link>
          )}
        </div>
        <div className="mb-none">
          <Link className="link" to="/booking">
            <Button
              variant="contained"
              className="secondary__btn topBar-btn2"
              color="secondary"
            >
              Book Now
            </Button>
          </Link>
        </div>
        <div className="mb-none">
          <Link className="link" to="/post-ads">
            <Button
              variant="contained"
              className="primaryblue__btn topBar-btn"
              color="secondary"
            >
              POST YOUR AD
            </Button>
          </Link>
        </div>
        <div className="d-none">
          <div>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={toggleDarkMode}
            >
              <SearchIcon />
            </IconButton>
          </div>
          {Auth.isAuthenticated() ? <HeaderOptions /> : <HeaderOptionsTwo />}
        </div>
      </div>
    </div>
  );
}

export default Header;
