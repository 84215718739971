import React from "react";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";

function PaymentFail(props) {
  const res_type = new URLSearchParams(props.location.search).get("type");
  const seoData = {
    title: "Payment Fail",
    description: "Payment Fail",
    url: window.location.href,
  }

  return (
    <div className="container paymnet-responce">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{seoData.title} | Cleaninger</title>
        <meta name="description" content={seoData.description} />
        <meta property="og:title" content={seoData.title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seoData.url} />
        <link rel="canonical" href={seoData.url} />
      </Helmet>
      <div>
        <HighlightOffRoundedIcon className="fail-icon" />
      </div>
      <div className="fail-txt">
        <h1>Payment Fail</h1>
      </div>
      <div className="done-desc">
        <p>Your payment was failed. Try again using another payment method.</p>
      </div>
      <div className="btn-don-row">
        {res_type !== "null" && (
          <div>
            <Link
              className="link"
              to={
                res_type === "FOR_CREDITS" ? "/user-credits" : "/publish-post"
              }
            >
              <Button className="primary__btn done-btn">Try Again</Button>
            </Link>
          </div>
        )}
        &nbsp;&nbsp;&nbsp;
        <div>
          <Link className="link" to="/">
            <Button className="primary__btn_outline done-btn">Cancel</Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default PaymentFail;
