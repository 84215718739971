/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from "react";
import { apis } from "../properties";
import { GetData } from "../ApiService/GetData";
import parse from "html-react-parser";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import HomeIcon from "@material-ui/icons/Home";
import { Link } from "react-router-dom";
import Auth from "../Auth";
import Spinner from "../Spinner";
import Helmet from "react-helmet";
import FooterText from "../Components/FooterText";

var userID = 0;
function SingleView(props) {
  let adsId = props.match.params.id;
  const [singleData, setSingleData] = useState({});
  const [singleImageList, setSingleImageList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [haveData, setHaveData] = useState(false);
  const [spinner, setSpinner] = useState(true);
  const [seoData, setSeoData] = useState({
    title: "",
    url: window.location.href,
  });

  useEffect(() => {
    if (Auth.isAuthenticated()) {
      userID = JSON.parse(sessionStorage.getItem("userDataSet")).id;
    }
    getResultData();
    setViewCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getResultData = () => {
    let getURL = `${apis.GET_SINGLE_PAGE_DATA}?adsId=${adsId}`;
    GetData(getURL).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        setSingleData(responseJson.data);
        setSingleImageList(responseJson.data.adaImageList);
        if (responseJson.data.adsData.service_list != null) {
          setServiceList(responseJson.data.adsData.service_list);
        }
        setSeoData({ ...seoData, title: responseJson.data.adsData.ad_title });
        setHaveData(true);
        setSpinner(false);
      } else {
      }
    });
  };

  const setViewCount = () => {
    let getURL = `${apis.SET_VIEW_COUNT}?adsId=${adsId}&userID=${userID}`;
    GetData(getURL).then((result) => {
      let responseJson = result;
      console.log(responseJson);
    });
  };
  function convertToSlug(Text) {
    return Text.toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  }

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{seoData.title} | Cleaninger</title>
        <meta name="description" content={seoData.title} />
        <meta property="og:title" content={seoData.title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seoData.url} />
        <link rel="canonical" href={seoData.url} />
      </Helmet>
      <Spinner spinner={spinner} />
      <div className="bread-rwo container">
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/" className="bread_tag">
            <HomeIcon className="bread_tag_icon" />
          </Link>
          {haveData && (
            <Link
              to={{
                pathname: `/ads/${convertToSlug(singleData.category)}`,
              }}
              className="bread_tag"
            >
              {singleData.category}
            </Link>
          )}
          {haveData && (
            <Link
              to={{
                pathname: `/ads/${convertToSlug(
                  singleData.category
                )}/${convertToSlug(singleData.regions)}`,
              }}
              className="bread_tag"
            >
              {singleData.regions}
            </Link>
          )}
          {haveData && (
            <Link
              to={{
                pathname: `/ads/${convertToSlug(
                  singleData.category
                )}/${convertToSlug(singleData.regions)}/${convertToSlug(
                  singleData.city
                )}`,
              }}
              className="bread_tag"
            >
              {singleData.city}
            </Link>
          )}
        </Breadcrumbs>
      </div>
      <div className="single_container container">
        <div className="single_left">
          <div className="home_bt_section2">
            <h3>{haveData && singleData.adsData.ad_title}</h3>
            <div className="single_disc">
              {haveData && parse(singleData.adsData.ad_discription)}
            </div>
          </div>
          <div className="home_bt_section2">
            <h3>Services offered</h3>
            {haveData && (
            <ul className="service_list_tag2">
              { serviceList.map((service) => (
                  <li>{service}</li>
                ))}
            </ul>
            )}
          </div>
          <div className="home_bt_section2">
            <h3>Contact Details</h3>
            <div className="single_disc">
              {haveData && (
                <ul className="ul_ff">
                  <li>
                    <span>
                      <b>Phone:</b>&nbsp;
                    </span>
                    +91&nbsp;{singleData.adsData.contact_no}
                  </li>

                  {singleData.adsData.whatsapp === 1 && (
                    <li>
                      <span>
                        <b>Whatsapp:</b>&nbsp;
                      </span>
                      +91&nbsp;{singleData.adsData.contact_no}
                    </li>
                  )}
                  {singleData.adsData.email && (
                    <li>
                      <span>
                        <b>Email:</b>&nbsp;
                      </span>
                      {singleData.adsData.email}
                    </li>
                  )}
                  {singleData.adsData.address && (
                    <li>
                      <span>
                        <b>Address:</b>&nbsp;
                      </span>
                      {singleData.adsData.address}
                    </li>
                  )}
                </ul>
              )}
            </div>
          </div>
        </div>
        {haveData && (
          <div className="single_right">
            <div className="mb-none sign_btn_side">
              <div>
                {singleData.adsData.whatsapp === 1 && (
                  <a
                    href={`https://wa.me/+91${singleData.adsData.contact_no}?text=Hi%0D%0A%0D%0AI+saw+your+ad+on+CLEANINGER%0D%0A%0D%0A${window.location.href}`}
                    target="_blank"
                    className="whatsUp__btn_result single-btn-setting"
                  >
                    <i className="fa fa-whatsapp icon-bt"></i>
                    &nbsp;&nbsp;WHATSAPP
                  </a>
                )}
              </div>
              <br />
              <div>
                {singleData.adsData.contact_no !== null && (
                  <a
                    href={`tel:+91${singleData.adsData.contact_no}`}
                    className="phone__btn_result single-btn-setting"
                  >
                    <i className="fa fa-phone"></i>&nbsp;&nbsp;TELEPHONE
                  </a>
                )}
              </div>
            </div>
            <div>
              <div className="mobileFixBar fixed-bottom container d-none">
                <div className="full-with">
                  {singleData.adsData.whatsapp === 1 && (
                    <a
                      href={`https://wa.me/+91${singleData.adsData.contact_no}?text=Hi%0D%0A%0D%0AI+saw+your+ad+on+CLEANINGER%0D%0A%0D%0A${window.location.href}`}
                      target="_blank"
                      className="whatsUp__btn_result single-btn-setting"
                    >
                      <i className="fa fa-whatsapp icon-bt"></i>
                      &nbsp;&nbsp;WHATSAPP
                    </a>
                  )}
                </div>
                &nbsp;&nbsp;
                <div className="full-with">
                  {singleData.adsData.contact_no !== null && (
                    <a
                      href={`tel:+91${singleData.adsData.contact_no}`}
                      className="phone__btn_result single-btn-setting"
                    >
                      <i className="fa fa-phone"></i>&nbsp;&nbsp;TELEPHONE
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {singleImageList.length > 0 && (
      <div className="grid_container container">
        <ul>
          {singleImageList.map((img) => (
            <li>
              <img src={img.src} />
            </li>
          ))}
        </ul>
      </div>
      )}
      <div className="contact_foot container">
        <h2>Contact me</h2>
        <div className="contact_foot_btnRow">
          <div className="with_ft_btn">
            {singleData.adsData && singleData.adsData.whatsapp === 1 && (
              <a
                href={`https://wa.me/+91${singleData.adsData.contact_no}?text=Hi%0D%0A%0D%0AI+saw+your+ad+on+CLEANINGER%0D%0A%0D%0A${window.location.href}`}
                target="_blank"
                className="whatsUp__btn_result single-btn-setting"
              >
                <i className="fa fa-whatsapp icon-bt"></i>
                &nbsp;&nbsp;WHATSAPP
              </a>
            )}
          </div>{" "}
          &nbsp;&nbsp; &nbsp;&nbsp;
          <div className="with_ft_btn">
            {singleData.adsData && singleData.adsData.contact_no !== null && (
              <a
                href={`tel:+91${singleData.adsData.contact_no}`}
                className="phone__btn_result single-btn-setting"
              >
                <i className="fa fa-phone"></i>&nbsp;&nbsp;TELEPHONE
              </a>
            )}
          </div>
        </div>
      </div>

      <div className="container">
        <div className="allLink_foot">
          {singleData.category && (
            <p>
              All Ads in{" "}
              <a
                href={`/ads/${convertToSlug(
                  singleData.category
                )}/${convertToSlug(singleData.regions)}/${convertToSlug(
                  singleData.city
                )}`}
              >
                {singleData.category} <br /> In {singleData.city}
              </a>
            </p>
          )}
        </div>
      </div>
      <div className="container">
        <div className="footer_text_section">
          <FooterText />
        </div>
      </div>
    </div>
  );
}

export default SingleView;
