import Swal from "sweetalert2";

export const BASE_MAIN_API_URL = "https://api.cleaninger.in/";
export const BASE_LOCAL_API_URL = "http://localhost:8000/";
export const BASE_S3_URL = "https://adsindian.s3.ap-south-1.amazonaws.com/";

export const BASE_API_URL = `${BASE_MAIN_API_URL}`;

export const apis = {
  USER_REGISTER: `${BASE_API_URL}api/register`,
  USER_LOGIN: `${BASE_API_URL}api/login`,
  VERIFICATION_CODE: `${BASE_API_URL}api/verification`,
  FORGET_PASSWORD: `${BASE_API_URL}api/forget-password`,
  RESET_PASSWORD: `${BASE_API_URL}api/reset-password`,
  GET_CATEGORY_LIST: `${BASE_API_URL}api/category`,
  GET_SUB_CATEGORY: `${BASE_API_URL}api/category-sub`,
  GET_REGIONS_LIST: `${BASE_API_URL}api/regions`,
  GET_CITIES_LIST: `${BASE_API_URL}api/cities`, // get all cities - pass the 0 && get region wise pass region id
  INSERT_POST: `${BASE_API_URL}api/ads-data-insert`,
  GET_PACKAGE_DATA: `${BASE_API_URL}api/package-list`,
  INSERT_PROMOTE_DATA: `${BASE_API_URL}api/promote-data-insert`,
  USER_ADS_LIST: `${BASE_API_URL}api/ads-user-data`, // get ads list pass 0 && get Single pass ads id
  // PAYMENT_URL: `${BASE_API_URL}api/payumoney`, // Payumoney for pay the publish ads
  // PAYMENT_URL_CREDITS: `${BASE_API_URL}api/payumoney-credits`, // Payumoney for buy credits
  USER_CREDITS: `${BASE_API_URL}api/get-user-credits`,
  ADS_COUNT: `${BASE_API_URL}api/ads-count`,
  PROFILE_UPDATE: `${BASE_API_URL}api/profile-update`,
  USER_DATA: `${BASE_API_URL}api/user-data`,
  PASSWORD_CHANGE: `${BASE_API_URL}api/password-change`,
  GET_CREDITS_PACKS: `${BASE_API_URL}api/credits-packs`,
  GET_PURCHASED_CREDITS: `${BASE_API_URL}api/credit-transactions`,
  PAY_USING_CREDITS: `${BASE_API_URL}api/pay-using-credits`,
  UPDATE_ADS: `${BASE_API_URL}api/ads-data-update`,
  UPDATE_ADS_IMAGES: `${BASE_API_URL}api/ads-images-update`,
  UPDATE_PROFILE_IMAGE: `${BASE_API_URL}api/profile-images-update`,
  ACTION_ADS: `${BASE_API_URL}api/action-ads`,
  UPDATE_PROMOTE: `${BASE_API_URL}api/promote-data-update`,
  GET_UPDATE_PROMOTE: `${BASE_API_URL}api/get-promote-data-update`,
  // PAYMENT_URL_UPDATE: `${BASE_API_URL}api/payumoney-update`, // Payumoney for pay the publish ads
  GET_RESULT_DATA: `${BASE_API_URL}api/get-result-data`,
  GET_SEARCH_DATA: `${BASE_API_URL}api/get-search-data`,
  GET_SINGLE_PAGE_DATA: `${BASE_API_URL}api/get-single-page-data`,
  SET_VIEW_COUNT: `${BASE_API_URL}api/view-count`,
  GET_CHART_DATA: `${BASE_API_URL}api/chart-view-count`,
  HOME_DATA: `${BASE_API_URL}api/home`,
  // RAZORPAY_PAYMENT: `${BASE_API_URL}api/razorpay-payment`,
  // RAZORPAY_PAYMENT_NOT_AUTH: `${BASE_API_URL}api/razorpay-payment-not-auth`,
  ALL_SET: `${BASE_API_URL}api/all-updates-onetime`,

  GET_PACKAGE_BEFORE: `${BASE_API_URL}user-data`,
};

export const S3config = {
  bucketName: "adsindian",
  dirName: "Cleaninger" /* optional */,
  region: "ap-south-1",
  accessKeyId: "AKIAUD2NEDNBHALOWRGG",
  secretAccessKey: "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",
  s3Url: "https://adsindian.s3.ap-south-1.amazonaws.com" /* optional */,
};

export const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});


export const sliderSettings = {
  className: "center",
  dots: true,
  infinite: true,
  speed: 4000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  fade: true,
};

export const site_settings = {
  LoaderColor: '#008464',
  spinner: true,
}