/* eslint-disable no-loop-func */
import React, { useEffect, useState } from "react";
import "./../../Assets/css/PostAds.css";
import "./../../Assets/css/Comman.css";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import StepsBar from "./StepsBar";
import Divider from "@material-ui/core/Divider";
import Topicon from "../../Assets/img/ctt_icon.png";
import TopiconPremium from "../../Assets/img/plus_top_premium.png";
import moment from "moment";
import ImageUploader from "./ImageUploaderApp";
import { apis, S3config } from "../../properties";
import { GetData } from "../../ApiService/GetData";
import { useToasts } from "react-toast-notifications";
import S3 from "react-aws-s3";
import { PostDataAuth } from "../../ApiService/PostData";
import ScaleLoader from "react-spinners/ScaleLoader";
import CurrencyFormat from "react-currency-format";
import { GetDataAuth } from "../../ApiService/GetData";
import Helmet from "react-helmet";

let timeId = 0;
let packId = 0;
let imgeList = [];
let upImgesList = [];
let user_credits = {};
function PromotePost(prams) {
  let adsID = prams.match.params.id;

  const history = useHistory();
  const { addToast } = useToasts();
  const ReactS3Client = new S3(S3config);

  const [loading, setLoading] = useState(false);
  const [timeShedule, setTimeShedule] = useState([]);
  const [timeShedulePack1] = useState([
    { id: 1, from: 9.0, to: 12.0 },
    { id: 2, from: 12.0, to: 15.0 },
    { id: 3, from: 15.0, to: 18.0 },
    { id: 4, from: 18.0, to: 21.0 },
    { id: 5, from: 21.0, to: 24.0 },
  ]);
  const [timeShedulePack2] = useState([{ id: 6, from: 24.0, to: 9.0 }]);
  const [selectedPack, setSelectedPack] = useState({});
  const [selectedTime, setSelectedTime] = useState({});
  const seoData = {
    title: "Promote Ads",
    description: "Promote Ads",
    url: window.location.href,
  };
  const [packageList, setPackageList] = useState([]);
  const [toalPrice, setToalPrice] = useState(0);
  const [toalCredits, setToalCredits] = useState(0);
  const [postDate] = useState({
    package_data: {},
    time_data: {},
    premium_price: toalPrice,
    images: [],
    post_id: "",
    credits_tot: toalCredits,
  });

  useEffect(() => {
    setPackageDataList();
    getUserCredits();

    // eslint-disable-next-line
  }, []);

  const getUserCredits = () => {
    GetDataAuth(apis.USER_CREDITS).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        user_credits = responseJson.data;
      }
    });
  };

  const setPackageDataList = () => {
    GetData(apis.GET_PACKAGE_DATA).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        setPackageList(responseJson.data);
      } else {
        addToast(responseJson.message, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    });
  };

  const selectPack = (data) => {
    packId = data.id;
    setToalPrice(data.price);
    setToalCredits(data.credits);
    postDate.credits_tot = data.credits;
    setSelectedPack(data);
    if (data.type !== "Day") {
      setTimeShedule(timeShedulePack2);
    } else {
      setTimeShedule(timeShedulePack1);
    }
  };

  const selectShedule = (data) => {
    timeId = data.id;
    setSelectedTime(data);
  };

  const nextStep = () => {
    postDate.post_id = adsID;
    if (!isEmpty(selectedPack)) {
      if (!isEmpty(selectedTime)) {
        if (imgeList.length !== 0) {
          if (imgeList.length <= 3) {
            setLoading(true);
            uploadImageS3();
          } else {
            addToast(
              "You can choose only a 3 presentation images. Please check again!",
              {
                appearance: "error",
                autoDismiss: true,
              }
            );
          }
        } else {
          addToast("You should choose your presentation images.", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      } else {
        addToast("Please select the time slot", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } else {
      addToast("Please select the package", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const promotesAds = () => {
    console.log(postDate);
    PostDataAuth(apis.INSERT_PROMOTE_DATA, postDate).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        imgeList = [];
        upImgesList = [];
        timeId = 0;
        packId = 0;
        history.push({
          pathname: `/publish-post/${adsID}`,
          state: {
            user_credits: user_credits,
          },
        });
        setLoading(false);
      } else {
        addToast(responseJson.message, {
          appearance: "error",
          autoDismiss: true,
        });
        setLoading(false);
      }
    });
  };

  const setImages = () => {
    postDate.package_data = selectedPack;
    postDate.time_data = selectedTime;
    postDate.images = upImgesList;
    setTimeout(() => {
      promotesAds();
      console.log("World!");
    }, 4000);
  };

  const uploadImageS3 = () => {
    for (let x = 0; x <= imgeList.length; x++) {
      console.log(imgeList);
      ReactS3Client.uploadFile(imgeList[x])
        .then((data) => {
          console.log("Done");
          upImgesList.push(data.key);
          if (x === imgeList.length - 1) {
            setImages();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  const upImageList = (e, response) => {
    imgeList = response;
  };

  return (
    <div className="container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{seoData.title} | Cleaninger</title>
        <meta name="description" content={seoData.description} />
        <meta property="og:title" content={seoData.title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seoData.url} />
        <link rel="canonical" href={seoData.url} />
      </Helmet>
      <div className="post-ads-section">
        <StepsBar step={1} />
        <div className="steps_one">
          <div className="steps_one_section">
            <p className="txt">PROMOTE YOUR AD!</p>
            <div>
              <p className="step2-subheader">1. SELECT THE OFFER</p>
            </div>

            <div className="topicon-bar">
              <div>
                <img src={Topicon} alt="img1" />
              </div>
              <div className="topicon-bar-text">
                <h2>CLIMB THE TOP</h2>
                <p>Make your ad climb to the top when you decide!</p>
              </div>
            </div>
            <div className="">
              <ul className="pack-section">
                {packageList.map(
                  (option) =>
                    option.type !== "Night" && (
                      <li
                        className={
                          packId === option.id
                            ? "box-pack active-style"
                            : "box-pack"
                        }
                        key={option.id}
                        onClick={() => selectPack(option)}
                      >
                        <div className="box_rowStyle">
                          {option.type === "Night" ? (
                            <div class="night_style">Night</div>
                          ) : (
                            ""
                          )}
                          <div class="cate_style">{option.txt_style}</div>
                        </div>
                        <p className="box-pack-dis">
                          <b>{option.description}</b>
                        </p>
                        <p className="box-pack-price">
                          <CurrencyFormat
                            value={option.price.toFixed(2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rs "}
                          />{" "}
                        </p>
                        <p className="box-pack-credit">
                          <i>({option.credits} Coins)</i>
                        </p>
                      </li>
                    )
                )}
              </ul>
              <ul className="pack-section">
                {packageList.map(
                  (option) =>
                    option.type === "Night" && (
                      <li
                        className={
                          packId === option.id
                            ? "box-pack active-style"
                            : "box-pack"
                        }
                        key={option.id}
                        onClick={() => selectPack(option)}
                      >
                        <div className="box_rowStyle">
                          {option.type === "Night" ? (
                            <div class="night_style">Night</div>
                          ) : (
                            ""
                          )}
                          <div class="cate_style">{option.txt_style}</div>
                        </div>
                        <p className="box-pack-dis">
                          <b>{option.description}</b>
                        </p>
                        <p className="box-pack-price">
                          <CurrencyFormat
                            value={option.price.toFixed(2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rs "}
                          />{" "}
                        </p>
                        <p className="box-pack-credit">
                          <i>({option.credits} Coins)</i>
                        </p>
                      </li>
                    )
                )}
              </ul>
              <Divider />
              {/* <Divider /> */}
            </div>
            <div id="schedule-time">
              <p>Select Schedule:</p>
              <ul className="schedule-box">
                {timeShedule.map((time) => (
                  <li
                    className={
                      timeId === time.id
                        ? "schedule-box-li active-style"
                        : "schedule-box-li"
                    }
                    key={time.id}
                    onClick={() => selectShedule(time)}
                  >
                    <p>
                      {moment(time.from, "hh").format("LT")} -{" "}
                      {moment(time.to, "hh").format("LT")}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
            {/* <Divider /> */}
            <div className="premium-bar">
              <div className="premium-bar-img">
                <img src={TopiconPremium} alt="img4" />
              </div>
              <div className="premium-bar-text">
                <p className="txt1">
                  INCREASE YOUR VISIBILITY. ACTIVATE PREMIUM AD.
                </p>
                <p className="txt2">
                  Up to 3 preview pictures and the phone number visible in the
                  ads list. Discover more
                </p>
              </div>
              <div className="premium-bar-btn">
                <p className="price">
                  <h2>Free</h2>
                </p>
              </div>
            </div>
            <br />
            <div>
              <p className="step2-subheader">
                1. . CHOOSE THE PRESENTATION IMAGE
              </p>
              <p className="step2-subheader2">
                Pictures are your best Business Card, the more beautiful and
                sexy they are, the more contacts your ad will receive.If you
                don't choose any preview photos, empty photo will be selected as
                default preview photo.
              </p>
            </div>
            <ImageUploader upImageList={upImageList} limit={3} />
            <br />
            <Divider />
            <br />
            <div>
              <p className="step2-subheader2">
                For more information or specific requests, please write us at:{" "}
                <a href="mailto:support@cleaninger.in" className="call-icon">
                  support@cleaninger.in
                </a>
              </p>
              <p className="step2-subheader2">
                <a href="tel:+91 000 800 9190417" className="call-icon">
                  <i className="fa fa-phone" aria-hidden="true"></i>
                  <span>+91 000 800 000000</span>
                </a>
                From Mon. to Fri. / From 02:00 PM to 10:00 PM
              </p>
            </div>
          </div>
          <div className="total-priceBar">
            <p>
              <CurrencyFormat
                value={toalPrice.toFixed(2)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"Rs "}
              />
              &nbsp;
              <span className="cctx">({toalCredits}&nbsp;Coins)</span>
            </p>
          </div>
          <div className="btn-line">
            <Button className="primary__btn" onClick={nextStep}>
              PROMOTE
            </Button>
            &emsp;
            <ScaleLoader height={30} color={"#ae3e64"} loading={loading} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PromotePost;
