import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { PostData } from "./../ApiService/PostData";
import { useToasts } from "react-toast-notifications";
import ScaleLoader from "react-spinners/ScaleLoader";
import { apis, site_settings } from "./../properties";
import Helmet from "react-helmet";

function Verification() {
  const history = useHistory();
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const seoData = {
    title: `Verification`,
    description: `Verification`,
    url: window.location.href,
  };

  const submitCode = (event) => {
    setLoading(true);
    if (code !== "") {
      PostData(apis.VERIFICATION_CODE, { code: code }).then((result) => {
        let responseJson = result;
        if (responseJson.errors) {
          let errorArr = responseJson.errors;
          for (let x = 0; x < errorArr.length; x++) {
            addToast(errorArr[x].detail, {
              appearance: "error",
              autoDismiss: true,
            });
          }
          setLoading(false);
        } else {
          if (responseJson.status === "success") {
            addToast(responseJson.message, {
              appearance: "success",
              autoDismiss: true,
            });
            history.push("/login");
            setLoading(false);
          } else if (responseJson.status === "error") {
            addToast(responseJson.message, {
              appearance: "error",
              autoDismiss: true,
            });
            setLoading(false);
          }
        }
      });
    } else {
      addToast("Please enter verification code", {
        appearance: "info",
        autoDismiss: true,
      });
      setLoading(false);
    }
  };
  return (
    <div className="login__container container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{seoData.title} | Cleaninger</title>
        <meta name="description" content={seoData.description} />
        <meta property="og:title" content={seoData.title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seoData.url} />
        <link rel="canonical" href={seoData.url} />
      </Helmet>
      <div className="login_box">
        <div className="login_title">
          <h2>Verification</h2>
        </div>
        <div className="login__fields">
          <TextField
            id="outlined-basic"
            label="Enter verification code"
            className="full-with"
            variant="outlined"
            value={code}
            onChange={(event) => setCode(event.target.value)}
          />
        </div>
        <ScaleLoader
          height={30}
          color={site_settings.LoaderColor}
          loading={loading}
        />
        <Button
          variant="contained"
          className="primary__btn lg-btn"
          color="secondary"
          onClick={submitCode}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}

export default Verification;
