import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import ScaleLoader from "react-spinners/ScaleLoader";
import { site_settings } from "../properties";
import emailjs from "@emailjs/browser";
import moment from "moment";
import { useToasts } from "react-toast-notifications";
import Alert from "@material-ui/lab/Alert";

const Booking = () => {
  const [postData, setPostData] = React.useState({});
  const [loading, setLoading] = useState(false);
  const [responseType, setResponseType] = useState("");
  const { addToast } = useToasts();

  const ReturnText = () => {
    if (responseType === "success") {
      return (
        <Alert severity="success">
          Thank you for booking with us. We will get back to you soon.
        </Alert>
      );
    } else if (responseType === "error") {
      return (
        <Alert severity="error">
          Something went wrong. Please try again later.
        </Alert>
      );
    } else {
      return null;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(postData);
    if (
      postData.name &&
      postData.email &&
      postData.contact &&
      postData.serviceCategory
    ) {
      emailjs
        .send(
          "service_zjk4tan",
          "template_84nrk1o",
          {
            ...postData,
            date: moment(postData.date).format("DD-MM-YYYY"),
            time: moment(postData.time).format("hh:mm A"),
          },
          "bwFA2qKuVRjpb7zNN"
        )
        .then(
          (result) => {
            setLoading(false);
            setResponseType("success");
          },
          (error) => {
            setLoading(false);
            setResponseType("error");
          }
        );
    } else {
      setLoading(false);
      addToast("Please fill all the required fields and try again.", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  return (
    <div className="container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Booking Now | Cleaninger</title>
        <meta name="description" content="Post Ads" />
        <meta property="og:title" content="Post Ads" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="booking_page">
        <div className="steps_one_section">
          <ReturnText />
          <p className="txt">Make Your Reservation</p>
          <form
            id="booking_form"
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <div className="field_sps">
              <TextField
                label="Name"
                id="thename"
                className="full-with cal-2-1-with"
                variant="outlined"
                value={postData.name}
                onChange={(event) =>
                  setPostData({ ...postData, name: event.target.value })
                }
              />
            </div>
            <div className="field_sps">
              <TextField
                label="Email"
                className="full-with cal-2-1-with"
                variant="outlined"
                value={postData.email}
                onChange={(event) =>
                  setPostData({ ...postData, email: event.target.value })
                }
              />
            </div>
            <div className="field_sps">
              <TextField
                label="Contact Number"
                className="full-with cal-2-1-with"
                variant="outlined"
                value={postData.contact}
                onChange={(event) =>
                  setPostData({ ...postData, contact: event.target.value })
                }
              />
            </div>
            <div className="field_sps">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Booking Date"
                  className="full-with cal-2-1-with"
                  inputFormat="MM/DD/YYYY"
                  value={postData.date}
                  onChange={(newValue) => {
                    setPostData({
                      ...postData,
                      date: newValue,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField variant="outlined" {...params} />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="field_sps">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <TimePicker
                  label="Booking Time"
                  className="full-with cal-2-1-with"
                  value={postData.time}
                  onChange={(newValue) => {
                    setPostData({
                      ...postData,
                      time: newValue,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField variant="outlined" {...params} />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="field_sps">
              <FormControl fullWidth variant="outlined">
                <InputLabel id="demo-simple-select-label">
                  Service Category
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={postData.serviceCategory}
                  label="Service Category"
                  onChange={(event) =>
                    setPostData({
                      ...postData,
                      serviceCategory: event.target.value,
                    })
                  }
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={"Residential Cleaning"}>
                    Residential Cleaning
                  </MenuItem>
                  <MenuItem value={"Commercial Cleaning"}>
                    Commercial Cleaning
                  </MenuItem>
                  <MenuItem value={"Pest Control"}>Pest Control</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="field_sps">
              <TextField
                label="Your message (optional)"
                multiline
                rows={4}
                className="full-with cal-2-1-with"
                variant="outlined"
                value={postData.message}
                onChange={(event) =>
                  setPostData({ ...postData, message: event.target.value })
                }
              />
            </div>
            <div className="btn-line">
              <Button className="primary__btn" type="submit">
                Send Request
              </Button>{" "}
              &emsp;
              <ScaleLoader
                height={30}
                color={site_settings.LoaderColor}
                loading={loading}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Booking;
