import React from "react";
import Helmet from "react-helmet";

function PrivacyPolicy() {
  const seoData = {
    title: "Privacy Policy",
    description: "Privacy Policy",
    url: window.location.href,
  };
  return (
    <div className="container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{seoData.title} | Cleaninger</title>
        <meta name="description" content={seoData.description} />
        <meta property="og:title" content={seoData.title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seoData.url} />
        <link rel="canonical" href={seoData.url} />
      </Helmet>
      <div className="term-page">
        <h2 className="term-title">PRIVACY POLICY</h2>
        <p className="term-sub">Last Updated February 2019</p>
        <p className="term-txt">
          Legal name : CLEANINGER PRIVATE LIMITED <br/>
          We / us : CLEANINGER PRIVATE LIMITED  <br/>
          The Site
          (as defined in the Terms of Use) ("we" or "us") is committed to
          protecting your privacy. Any personal information including sensitive
          personal data or information that we collect, receive, possess, store,
          deal or handle is in accordance with the relevant Data Protection
          Legislations.
        </p>
        <p className="term-txt">
          This Privacy Policy together with the Terms of Use at Terms and
          conditions and any other documents referred to in it set out the basis
          on which we will hold and process your data.
        </p>
        <p className="term-txt">
          Please note that when you contact any Offeror Users (as described in
          the Terms of Use) using the contact information set out in any
          material uploaded by the Offeror Users to the Site, we are not
          responsible for that data or how Offeror Users choose to process any
          personal information transferred to them. Offeror Users become the
          data controllers in respect of such data transfers and we are in no
          way able to control or be responsible for your personal information in
          their hands.
        </p>
        <h2 className="term-title">What Information We Collect</h2>
        <p className="term-txt">
          We collect information as submitted to us by (1) Offeror Users who
          wish to place advertisements or create profiles on the Site limited to
          the information specifically submitted by Offeror Users in the course
          of completing advertisement placement or profile creation; and (2)
          Recipient Users (as described in the Terms of Use) who wish to browse
          the Site and contact an Offeror User. We collect the e-mail addresses
          from the (1) Offeror Users who wish to place advertisements or create
          profiles on the Site; and (2) Recipient Users who wish to browse the
          Site and contact an Offeror User. The other information collected by
          us is limited to the information specifically submitted by and
          consented to by the Offeror Users in the course of completing
          advertisement placement or profile creation, and the Offeror Users may
          choose to not submit any information as well. We only collect
          information when:
        </p>
        <ul>
          <li className="term-txt">
            it is for a lawful purpose connected with a function or activity of
            the Site; or
          </li>
          <li className="term-txt">
            the collection of the sensitive personal data or information is
            considered necessary for the purpose for which it has been
            collected.
          </li>
        </ul>
        <p className="term-txt">
          In respect of Offeror Users, the legal basis for the collection of
          data by us is that it is necessary in order to perform a contract
          between the Site and the Offeror User and, in the case of optional
          information submitted by the Offeror User, the choice of the Offeror
          User to make such information public. In respect of Recipient Users,
          the legal basis for the collection of data is the consent of the
          Recipient User indicated as set out below. Furthermore, the Site has a
          legitimate interest in collecting and processing data, having due
          regard to the rights and freedoms of the data subjects, for the
          purposes of business administration and fraud prevention and
          compliance.
        </p>
        <h2 className="term-title">How We Collect and Use Information</h2>
        <h3 className="term-title">Log Files</h3>
        <p className="term-txt">
          Any time you visit the Site, we gather certain information about your
          use of the Site to analyze trends, administer the Site, track visitor
          movement in the aggregate, and gather broad demographic information
          for aggregate use. We do not link any of the information in our log
          files to any other information that would enable us to determine your
          actual identity. This means that a user's session will be tracked, but
          the user will be anonymous. We do not share your log files externally.
        </p>
        <h3 className="term-title">Cookies</h3>
        <p className="term-txt">
          We use "cookies" to keep track of some types of information while you
          are visiting the Site or using our services. Cookies are very small
          files placed on your computer, and they allow us to count the number
          of visitors to the Site and distinguish repeat visitors from new
          visitors. They also allow us to save user preferences and track use so
          that we can continue to develop and improve our service offering.
        </p>
        <h3 className="term-title">Session and Persistent Cookies</h3>
        <p className="term-txt">
          Session cookies are temporary and will be erased after you close your
          browser. Persistent cookies remain on your hard drive unless you
          manually delete them or until expiration after a set period of time.
          The primary purpose of persistent cookies are to make logging in to
          your account an easier process and for performance analytics by us or
          a trusted partner. First and third-party cookies may be session or
          persistent.
        </p>
        <h3 className="term-title">First-Party Cookies</h3>
        <p className="term-txt">
          First-party cookies are placed by us on your computer for a variety of
          purposes including website functionality, tracking length of visitor
          stay and number of unique visitors to the Site, and evaluating the
          popularity of specific site offerings. cleaninger.in first-party
          cookies include: PHPSESSID Session Cookie; Last_Vhost last city
          visited; adult_cookie over age consent; Logged_cookie when you do log
          in; and privacy_cookie agreed cookies on website.
        </p>
        <h3 className="term-title">Web Beacons</h3>
        <p className="term-txt">
          "Web beacons" (also known as "clear gifs" and "pixel tags") are small
          transparent graphic images that are often used in conjunction with
          cookies in order to further personalize the Site for our users and to
          collect a limited set of information about our visitors. We may also
          use web beacons in email communications in order to understand the
          behaviour of our customers. We do not link the web beacons to any
          information that would enable us to determine the actual identity of
          any individual.
        </p>
        <h3 className="term-title">Third-Party Cookies</h3>
        <p className="term-txt">
          We only use third-party statistic cookies. Any information that these
          third parties collect via cookies and beacons is anonymous and does
          not enable them to determine your actual identity. cleaninger.in
          third-party cookies include Google Analytics and Hotjar.
        </p>
        <h3 className="term-title">Your Cookie Choices</h3>
        <p className="term-txt">
          When you first log in to the Site, we ask for your consent to use
          cookies and other technologies. If you do not agree with the use of
          cookies and other technologies you can opt-out by choosing to accept
          or reject cookies by changing your internet browser settings. However,
          we rely on cookies for the proper operation of the Site and if your
          browser is set to reject all cookies, the Site will not function
          properly. Users who refuse cookies assume all responsibility for any
          resulting loss of functionality. Click the "help" menu in your
          browser's toolbar for information on how to change your settings. For
          additional information about managing cookies, please visit
          https://www.allaboutcookies.org/. Keep in mind that cookies are unique
          to each computer. In order to opt-out of data collection by the use of
          cookies on any of your other devices, you will be required to repeat
          this process on each device.
        </p>
        <h3 className="term-title">Other Information We Collect</h3>
        <h3 className="term-title">E-mails and Telephone Calls.</h3>
        <p className="term-txt">
          We require an e-mail address from you when you register for our
          services as an Offeror User. We do not record any telephone calls.
        </p>
        <h3 className="term-title">Mobile Device Additional Terms.</h3>
        <p className="term-txt">
          Mobile Device: If you use a mobile device to access the Site or
          download any of our applications, we may collect device information
          (such as your mobile device ID, model and manufacturer), operating
          system and version information, and IP address;
        </p>
        <p className="term-txt">
          Geo-Location Information: We do not ask you for, access or track any
          location-based information from your mobile device at any time while
          downloading or using our mobile apps or services.
        </p>
        <h3 className="term-title">
          Disclosure of Your Information to Third Parties
        </h3>
        <h3 className="term-title">Disclosure By Law.</h3>
        <p className="term-txt">
          You acknowledge and agree that we may disclose information you provide
          if the disclosure is necessary for compliance of a legal obligation.
          Provided that any information you may provide may be shared with
          Government agencies to: obtain information including sensitive
          personal data or information for the purpose of verification of
          identity; for prevention, detection, investigation including cyber
          incidents, prosecution, and punishment of offences; or in compliance
          of any order under the law for the time being in force.
        </p>
        <p className="term-txt">
          Any information disclosed to a third party will not be disclosed or
          published further by that third party.
        </p>
        <h3 className="term-title">Transfer of Information</h3>
        <p className="term-txt">
          We may transfer your sensitive personal data or information including
          any information to any third party, including any part located in any
          other country, but only if you have expressly consented to such data
          transfer.
        </p>
        <h3 className="term-title">
          Withdrawal of Consent to Provide Information
        </h3>
        <p className="term-txt">
          You can choose to not provide the data or information sought to be
          collected by not consenting to this Privacy Policy. You shall also, at
          any time while availing the services or otherwise, have an option to
          withdraw consent given earlier to us by withdrawing your consent in
          writing, by sending us an email at privacy.ii@cleaninger.in. In the
          case of not providing consent or later on withdrawing consent, we
          shall have the option not to provide goods or services for which the
          said information was sought.
        </p>
        <h3 className="term-title">Rectification of Information</h3>
        <p className="term-txt">
          You may review the information you have provided to us and correct or
          amend any personal information or sensitive personal data or
          information found to be inaccurate or deficient by sending us an email
          at privacy.ii@cleaninger.in.
        </p>
        <h3 className="term-title">Redressal of Grievances</h3>
        <p className="term-txt">
          Iris Brahja will act as Grievance Officer for the purpose of the Data
          Rules and can be contacted on grievances@cleaninger.in. The Grievance
          Officer shall respond to any grievance within one calendar month of
          the date of receipt of the grievance.
        </p>
        <h3 className="term-title">Disclosure to Protect Abuse Victims</h3>
        <p className="term-txt">
          Notwithstanding any other provision of this privacy statement or our
          Terms and Conditions of Service, we reserve the right, but have no
          obligation to disclose any information that you submit to the
          services, if in our sole opinion, we suspect or have reason to suspect
          that the information involves a party who may be the victim of abuse
          in any form. Abuse may include, without limitation, trafficking or
          coercion. Information may be disclosed to authorities that we, in our
          sole discretion, deem appropriate to handle such disclosure.
          Appropriate authorities may include, without limitation, law
          enforcement agencies, child protection agencies, or court officials.
        </p>
        <h3 className="term-title">
          Information Transferred As a Result of Sale of Business
        </h3>
        <p className="term-txt">
          As we develop our business, we may buy or sell assets and, depending
          on the transaction, your personally identifiable information (“PII”)
          may be one of the transferred assets. In the event that we are
          acquired by another company, your PII may be part of the assets
          transferred to the acquiring party. If this occurs, you will be
          notified if there are any material changes to the way your PII is
          collected or used.
        </p>
        <h3 className="term-title">Information Transferred Overseas</h3>
        <p className="term-txt">
          We process all information, including your PII, via our servers and
          networks located in the European Union. By using our services and
          expressly assenting to this Privacy Policy, you have consented to the
          overseas transfer of your PII solely for the purpose of providing you
          the services for which you have registered or to which you have
          subscribed. We reserve the right to transfer your PII back overseas.
          We take all reasonable steps to ensure that all overseas recipients
          will not hold, use or disclose your PII inconsistently with the
          National Privacy Principles.
        </p>
        <p className="term-txt">
          We will only share your information with third parties in the ways
          defined in this privacy statement.
        </p>
        <h3 className="term-title">Age Restrictions</h3>
        <p className="term-txt">
          This is an adult services site as set out in the Terms of Use.
        </p>
        <h3 className="term-title">Security</h3>
        <p className="term-txt">
          We have extensive security measures in place to protect the loss,
          misuse and alteration of the information stored in our database. These
          measures include the use of Secure Socket Layer (SSL) and/or strong
          encryption (3DES) technology during credit card transactions and
          administrative access to site data, as well as other proprietary
          security measures which are applied to all repositories and transfers
          of user information. We will exercise reasonable care in providing
          secure transmission of information between your computer and our
          servers, but given that no information transmitted over the Internet
          can be guaranteed 100% secure, we cannot ensure or warrant the
          security of any information transmitted to us over the Internet and
          hence accept no liability for any unintentional disclosure.
        </p>
        <h3 className="term-title">Links to or Access from Other Sites</h3>
        <p className="term-txt">
          Our Privacy Policy does not apply to any links, products or services
          provided by third parties. We are not in any manner associated with or
          responsible for these third-party links, products and services. You
          may receive links of websites, content, products and services from
          third parties when you use or access the Site. Please exercise caution
          and carefully read and review the privacy policies/statements of third
          parties before you submit any data and information. We are not
          responsible for the privacy practices or the content of other websites
          that may be linked to the Site or of any third party advertisers
          including those of Offeror Users.
        </p>
        <h3 className="term-title">Retention of your Data</h3>
        <p className="term-txt">
          We generally retain your sensitive personal data only for so long as
          is necessary for the purpose for which it is used, or required by law.
        </p>
        <h3 className="term-title">Access to Information</h3>
        <p className="term-txt">
          The Data Protection Legislation gives you the right to access
          information held about you. Your right of access can be exercised in
          accordance with the Data Protection Legislation. We will respond to
          all access requests within 30 days. Please use the contact details
          below.
        </p>
        <h3 className="term-title">Contacting the Website</h3>
        <p className="term-txt">
          If you have any questions about this privacy statement, the practices
          of the Site, or your dealings with the Site, you can contact:
          privacy.ii@cleaninger.in.
        </p>
        <h3 className="term-title">Dispute Resolution</h3>
        <p className="term-txt">
          You agree that any claim, cause of action or dispute you have against
          us that arises out of or relates to any access or use of the Site must
          be resolved exclusively in the United Kingdom and you submit to the
          personal jurisdiction of either of these courts for the purpose of
          litigating any such claim and that the laws of the United Kingdom will
          govern these terms and any such claim, without regard to conflict of
          law provisions.
        </p>
        <h3 className="term-title">Revision of the Privacy Policy</h3>
        <p className="term-txt">
          Any changes we may make to our Privacy Policy in the future will be
          posted on this page. Your continued use of any section of the Site
          following posting of the updated Privacy Policy will constitute your
          acceptance of the Privacy Policy as amended. We will also notify you
          of the changes to our Privacy Policy by email.
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
