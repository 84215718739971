import React, { useState } from "react";
import Divider from "@material-ui/core/Divider";

function StepsBar(props) {
  const [steps] = useState({ step1: false, step2: false, step3: false });
  if (props.step === 1) {
    steps.step1 = true;
    steps.step2 = false;
    steps.step3 = false;
  } else if (props.step === 2) {
    steps.step1 = true;
    steps.step2 = true;
    steps.step3 = false;
  } else if (props.step === 3) {
    steps.step1 = true;
    steps.step2 = true;
    steps.step3 = true;
  } else {
    steps.step1 = false;
    steps.step2 = false;
    steps.step3 = false;
  }
  return (
    <div className="steps-bar">
      <div className="steps">
        <div className={steps.step1 ? "stpe-box-active" : "stpe-box "}>
          {steps.step1 ? (
            <p>
              <i class="fa fa-check" aria-hidden="true"></i>
            </p>
          ) : (
            <p>01</p>
          )}
        </div>
        <span>Insert</span>
      </div>
      <div className="steps">
        <div className={steps.step2 ? "stpe-box-active" : "stpe-box "}>
          {steps.step2 ? (
            <p>
              <i class="fa fa-check" aria-hidden="true"></i>
            </p>
          ) : (
            <p>02</p>
          )}
        </div>
        <span>Promote</span>
      </div>
      <div className="steps">
        <div className={steps.step3 ? "stpe-box-active" : "stpe-box "}>
          {steps.step3 ? (
            <p>
              <i class="fa fa-check" aria-hidden="true"></i>
            </p>
          ) : (
            <p>03</p>
          )}
        </div>
        <span>Publish</span>
      </div>
      <Divider className="steps-line" />
    </div>
  );
}

export default StepsBar;
