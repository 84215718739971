import React, { useState, useEffect } from "react";
// import { useToasts } from "react-toast-notifications";
import { GetDataAuth } from "../../ApiService/GetData";
import { apis, BASE_S3_URL, sliderSettings } from "../../properties";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import UpdateIcon from "@material-ui/icons/Update";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Swal from "sweetalert2";
import Slider from "react-slick";
import Pagination from "react-js-pagination";
import InboxIcon from "@material-ui/icons/Inbox";

let user_credits = {};
let pagi_status = false;
let currentDate = moment().format();
const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

function NotPublishAds() {
  const history = useHistory();
  // const { addToast } = useToasts();

  const [adsList, setAdsList] = useState([]);
  const [emptyMSG, setEmptyMSG] = useState(true);
  const [pagination, setPagination] = useState({});

  useEffect(() => {
    getAdsDataList();
    getUserCredits();
  }, []);

  const getUserCredits = () => {
    GetDataAuth(apis.USER_CREDITS).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        user_credits = responseJson.data;
      } else {
        user_credits = { amount: 0 };
      }
    });
  };

  const getAdsDataList = (pageNumber) => {
    let getURL = "";
    if (pageNumber) {
      getURL = `${apis.USER_ADS_LIST}/0?status=UNPUBLISH&page=${pageNumber}`;
    } else {
      getURL = `${apis.USER_ADS_LIST}/0?status=UNPUBLISH`;
    }
    GetDataAuth(getURL).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        pagi_status = true;
        setAdsList(responseJson.data.DataSet);
        setPagination(responseJson.data.Pagi);
        if (responseJson.data.DataSet.length === 0) {
          pagi_status = false;
          setAdsList([]);
          setEmptyMSG(true);
        } else {
          setEmptyMSG(false);
        }
      } else {
        // eslint-disable-next-line no-unused-vars
        pagi_status = false;
        setAdsList([]);
        setEmptyMSG(true);
      }
    });
  };

  const deleteAds = (adsID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "If you delete this ad, it does not appear on our platform anymore. You want to delete this ad",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#be206b",
      cancelButtonColor: "#666666",
      cancelButtonText: "No, Cancel",
      confirmButtonText: "Yes, Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        deletAdsDBUpdate(adsID);
        Swal.fire("Deleted!", "Your ab have been deleted.", "success");
      }
    });
  };

  const editAds = (data) => {
    history.push({
      pathname: `/edit-ads`,
      state: {
        adsData: data,
      },
    });
  };

  const publishAds = (adsID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "If you publish this ad, it appear on our platform again. You want to publish this ad.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#be206b",
      cancelButtonColor: "#666666",
      cancelButtonText: "No, Cancel",
      confirmButtonText: "Yes, Publish!",
    }).then((result) => {
      if (result.isConfirmed) {
        publishDBUpdate(adsID);
        Swal.fire("Publish!", "Your ab have been Publish.", "success");
      }
    });
  };

  const publishDBUpdate = (adsID) => {
    GetDataAuth(`${apis.ACTION_ADS}/publish/${adsID}`).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        getAdsDataList();
      } else {
        Swal.fire(
          "Fail!",
          "This ad not paid yet, Press the Pay & publish button.",
          "error"
        );
      }
    });
  };

  const deletAdsDBUpdate = (adsID) => {
    GetDataAuth(`${apis.ACTION_ADS}/delete/${adsID}`).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        getAdsDataList();
      } else {
        Swal.fire("Fail!", "Try Again.", "error");
      }
    });
  };

  const payAndPublish = (adsID) => {
    history.push({
      pathname: `/publish-post/${adsID}`,
      state: {
        user_credits: user_credits,
      },
    });
  };

  const handlePageChange = (pageNumber) => {
    window.scrollTo(0, 0);
    getAdsDataList(pageNumber);
  };

  function convertToSlug(Text) {
    return Text.toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  }

  return (
    <div>
      {emptyMSG && (
        <div className="empty_area">
          <InboxIcon />
          <p>- No data -</p>
        </div>
      )}
      {adsList.map((ads) => (
        <div className="pro-result_row">
          <div className="pro-result_img">
            <Link
              className="link"
              to={{
                pathname: `/ads/${ads.categorySlug}/${ads.regionsSlug}/${
                  ads.citySlug
                }/${ads.adsData.id}/${convertToSlug(ads.adsData.ad_title)}`,
              }}
            >
              <Slider {...sliderSettings}>
                {ads.adaImageListTOP.map((image) => (
                  <div>
                    <img src={`${BASE_S3_URL}${image.url}`} alt="Image1" />
                  </div>
                ))}
              </Slider>
            </Link>
          </div>
          <div className="pro-result_content">
            <div className="pro-result_data">
              <Link
                className="link"
                to={{
                  pathname: `/ads/${ads.categorySlug}/${ads.regionsSlug}/${
                    ads.citySlug
                  }/${ads.adsData.id}/${convertToSlug(ads.adsData.ad_title)}`,
                }}
              >
                <div className="pro-titledata">{ads.adsData.ad_title}</div>
              </Link>
              {/* <div className="pro-discription">
                {ads.adsData.ad_discription.replace(regex, "")}
              </div> */}
              <div className="pro-exdate">
                <p>
                  Ads created date:{" "}
                  {moment(ads.adsData.created_date).format("DD-MM-YYYY")}
                </p>
              </div>
              <div className="pro-exdate">
                <p>
                  Ad expiring date:{" "}
                  {moment(ads.adsData.ex_date).format("DD-MM-YYYY")}
                  &nbsp; (
                  {moment
                    .duration(
                      moment(ads.adsData.ex_date, "YYYY-MM-DD").diff(
                        moment(currentDate, "YYYY-MM-DD")
                      )
                    )
                    .asDays()}{" "}
                  More)
                </p>
              </div>
              {ads.adsData.payment_status === "LIVE" ? (
                <div className="pro-exdate">
                  <p
                    className={
                      ads.promo_ex_status
                        ? "promo time-Inactive"
                        : "promo time-active"
                    }
                  >
                    <UpdateIcon className="time-icon" /> PROMOTION EXPIRY DATE :{" "}
                    {moment(ads.promoteData.ex_date).format("DD-MM-YYYY")}
                  </p>
                </div>
              ) : (
                <br />
              )}

              {/* <div className="pro-tags">
                {ads.adsData.age} years | {ads.category} |{" "}
                {ads.regions} | {ads.city}
              </div> */}
              <div
                className={
                  ads.adsData.payment_status !== "LIVE"
                    ? "pro-btnss"
                    : "pro-btnss2"
                }
              >
                {ads.adsData.payment_status !== "LIVE" ? (
                  <div className="pro-btnss-left">
                    <Button
                      onClick={() => {
                        payAndPublish(ads.adsData.id);
                      }}
                      className="primary__btn"
                    >
                      Pay & publish
                    </Button>
                  </div>
                ) : (
                  ""
                )}
                <div className="pro-btnss-right">
                  <BootstrapTooltip title="Edit">
                    <IconButton
                      color="primary"
                      aria-label="edit"
                      className="ads-edit"
                      onClick={() => {
                        editAds(ads);
                      }}
                    >
                      <CreateIcon fontSize="small" />
                    </IconButton>
                  </BootstrapTooltip>
                  &nbsp;&nbsp;
                  <BootstrapTooltip title="Publish">
                    <IconButton
                      color="primary"
                      aria-label="unbublish"
                      className="ads-edit"
                      onClick={() => {
                        publishAds(ads.adsData.id);
                      }}
                    >
                      <VisibilityIcon fontSize="small" />
                    </IconButton>
                  </BootstrapTooltip>
                  &nbsp;&nbsp;
                  <BootstrapTooltip title="Delete">
                    <IconButton
                      color="primary"
                      aria-label="delete"
                      className="ads-edit"
                      onClick={() => {
                        deleteAds(ads.adsData.id);
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </BootstrapTooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      {/* <!-- ======= Pagination Request ======= --> */}
      <div className={pagination.total > 0 ? "paginate_row" : "paginate_empty"}>
        <Pagination
          activePage={pagination.current_page}
          itemsCountPerPage={pagination.per_page}
          totalItemsCount={pagination.total}
          pageRangeDisplayed={5}
          onChange={handlePageChange.bind(this)}
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>
    </div>
  );
}

export default NotPublishAds;
