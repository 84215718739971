import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import React, { useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import Helmet from "react-helmet";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { GetDataAuth } from "../../ApiService/GetData";
import cart_icon from "../../Assets/img/cart.png";
import credite_icon from "../../Assets/img/crediti.png";
import Topicon from "../../Assets/img/ctt_icon.png";
import TopiconPremium from "../../Assets/img/plus_top_premium01.png";
import { apis } from "../../properties";
import "./../../Assets/css/Comman.css";
import "./../../Assets/css/PostAds.css";
import StepsBar from "./StepsBar";

function PublishPostUpdate(prams) {
  let adsID = prams.match.params.id;
  let user_credits = prams.location.state.user_credits;
  let page_type = prams.location.state.page_type;

  const history = useHistory();
  const { addToast } = useToasts();
  const seoData = {
    title: "Publish Ads Update",
    description: "Publish Ads Update",
    url: window.location.href,
  };
  const [adsDataSet, setAdsDataSet] = useState("");
  const [value, setValue] = React.useState("optionOne");
  const [premiumPack] = useState({
    price: 0,
    crdits: 0,
  });

  const buyCredit = () => {
    history.push("/user-credits");
  };

  const handleChange = (event) => {
    setValue(event.target.value);
    console.log(event.target.value);
  };

  useEffect(() => {
    getAdsItemList();
    if (page_type === "RENEW") {
      premiumPack.price = 50;
      premiumPack.crdits = 2;
    }
    // eslint-disable-next-line
  }, []);

  const getAdsItemList = () => {
    GetDataAuth(`${apis.GET_UPDATE_PROMOTE}/${adsID}?status=UPDATED`).then(
      (result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          setAdsDataSet(responseJson.data);
        } else {
          addToast(responseJson.message, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      }
    );
  };

  const payOrPublish = () => {
    if (value === "optionOne") {
      let totCredits = adsDataSet.packageData.credits + premiumPack.crdits;
      if (user_credits.amount >= totCredits) {
        GetDataAuth(
          `${apis.PAY_USING_CREDITS}/${adsDataSet.promoteData.id}/${page_type}`
        ).then((result) => {
          let responseJson = result;
          if (responseJson.status === "success") {
            history.push("/payment-success?type=FOR_ADS");
          } else {
            history.push("/payment-fail?type=FOR_ADS");
          }
        });
      } else {
        addToast(
          "Your Coins balance is not a match. Buy more Coins and try again!",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }
    } else {
      window.location.href = `${apis.GET_PACKAGE_BEFORE}?amount=${adsDataSet.packageData.price}&pay_type=cleaninger&adsID=${adsID}&adsType=CHANGE`;
    }
  };

  const [optionOne] = useState(
    <div>
      <div className="buy-credits-row">
        <div className="imgc-icon">
          <img src={credite_icon} alt="dbicon" />
        </div>
        <div className="txt-credit">
          <p className="txt1">COINS</p>
          <p className="txt2">Availble : {user_credits.amount}</p>
        </div>
        <div className="btn-buy">
          <Button onClick={buyCredit} className="primary__btn btn-mg-remove">
            Buy
          </Button>
        </div>
      </div>
    </div>
  );

  const [optionTwo] = useState(
    <div>
      <div className="buy-credits-row">
        <div className="card-icon">
          <img src="/Card/logo_visa.png" alt="dbicon" />
        </div>
        <div className="card-icon">
          <img src="/Card/logo_mastercard.png" alt="dbicon" />
        </div>
        <div className="card-icon">
          <img src="/Card/AmexBusiness.svg" alt="dbicon" />
        </div>
      </div>
    </div>
  );

  return (
    <div className="container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{seoData.title} | Cleaninger</title>
        <meta name="description" content={seoData.description} />
        <meta property="og:title" content={seoData.title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seoData.url} />
        <link rel="canonical" href={seoData.url} />
      </Helmet>
      <div className="post-ads-section">
        <StepsBar step={2} />
        <div className="steps_one">
          <div className="steps_one_section">
            <div className="basket-box">
              <div className="basket-title">
                <div className="cart-icon">
                  <img src={cart_icon} alt="cart-icon" />
                </div>
                <div className="basket-txt">
                  <p>PRODUCT IN THE BASKET</p>
                </div>
              </div>
              <div className="basket-list">
                <div className="packge-item">
                  <div className="top-icon">
                    <img src={Topicon} alt="cart-icon" />
                  </div>
                  <div className="item-description">
                    <p>
                      {adsDataSet !== ""
                        ? adsDataSet.packageData.description
                        : ""}
                    </p>
                    <span>Make your ad climb to the top when you decide!</span>
                  </div>
                  <div className="item-price">
                    <p>
                      {adsDataSet !== "" ? (
                        <CurrencyFormat
                          value={adsDataSet.packageData.price.toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                        />
                      ) : (
                        ""
                      )}
                      &nbsp;(
                      {adsDataSet !== ""
                        ? adsDataSet.packageData.credits
                        : ""}{" "}
                      Coins)
                    </p>
                  </div>
                </div>
                {page_type === "RENEW" ? (
                  <div className="packge-item">
                    <div className="top-icon">
                      <img src={TopiconPremium} alt="cart-icon" />
                    </div>
                    <div className="item-description">
                      <p>INCREASE YOUR VISIBILITY</p>
                      <span>
                        Up to 3 preview pictures and the phone number visible in
                        the ads list.
                      </span>
                    </div>
                    <div className="item-price">
                      <p>
                        <CurrencyFormat
                          value={premiumPack.price.toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                        />
                        &nbsp;({premiumPack.crdits} Coins)
                      </p>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="tot-item">
                  <div className="tot-description">
                    <p>Total Amount</p>
                  </div>
                  <div className="tot-price">
                    <p>
                      {adsDataSet !== "" ? (
                        <CurrencyFormat
                          value={(
                            premiumPack.price + adsDataSet.packageData.price
                          ).toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                        />
                      ) : (
                        ""
                      )}
                      &nbsp;(
                      {adsDataSet !== ""
                        ? adsDataSet.packageData.credits + premiumPack.crdits
                        : ""}{" "}
                      Coins)
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="basket-box">
              <div className="basket-title">
                <div className="basket-txt">
                  <p>HOW DO YOU LIKE TO PAY?</p>
                </div>
              </div>
              <div className="option-row">
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="optionOne"
                    control={<Radio />}
                    label={optionOne}
                  />
                  <FormControlLabel
                    value="optionTwo"
                    control={<Radio />}
                    label={optionTwo}
                  />
                </RadioGroup>
              </div>
            </div>
          </div>
          <div>
            <Button className="primary__btn" onClick={payOrPublish}>
              PAY & PUBLISH
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PublishPostUpdate;
