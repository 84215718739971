import React from "react";
import "./../Assets/css/Comman.css";
import "./../Assets/css/Footer.css";
import { Link } from "react-router-dom";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import logo from "./../Assets/img/Fex_lg.png";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import LocationOnIcon from "@material-ui/icons/LocationOn";

function Footer() {
  return (
    <div>
      <div className="Footer__container">
        <div className="Footer__bar container">
          <div className="Footer__longText">
            <img src={logo} alt="logo" />
            {/* <p>
              Welcome to Cleaninger, #1 Adult Classifieds in India. In Cleaninger
              you will find massages, sexual meetings, escorts service,
              independent escorts... Browse among all categories. Independent
              Girls, Call boys, Transsexual, Gay escorts, swinger meetings and
              the new one Adult Meetings.
            </p> */}
          </div>
          <div className="Footer__menu">
            <p className="foot_title">Services</p>
            <ul>
              <li>
                <a className="link" href="/ads/resedential-cleaning">
                  <ArrowRightIcon />
                  Resedential Cleaning
                </a>
              </li>
              <li>
                <a className="link" href="/ads/commercial-cleaning">
                  <ArrowRightIcon />
                  Commercial Cleaning
                </a>
              </li>
              <li>
                <a className="link" href="/ads/pest-control">
                  <ArrowRightIcon />
                  Pest control
                </a>
              </li>
            </ul>
          </div>
          <div className="Footer__menu">
            <p className="foot_title">Useful Links</p>
            <ul>
              <li>
                <Link className="link" to="/terms-and-conditions">
                  <ArrowRightIcon />
                  Terms and conditions
                </Link>{" "}
              </li>
              <li>
                <Link className="link" to="/privacy-policy">
                  <ArrowRightIcon />
                  Privacy policy
                </Link>{" "}
              </li>
              <li>
                <Link className="link" to="/refund-policy">
                  <ArrowRightIcon />
                  Refund Policy
                </Link>
              </li>
              <li>
                <Link className="link" to="/packages">
                  <ArrowRightIcon />
                  Packages
                </Link>
              </li>
            </ul>
          </div>
          <div className="Footer__social">
            <p className="foot_title">Contact us</p>
            <div className="contact_item">
              <EmailIcon />
              <a href="mailto:Cleaningerprivatelimited@gmail.com">
                Cleaningerprivatelimited@gmail.com
              </a>
            </div>
            <div className="contact_item">
              <PhoneIcon />
              <a href="tel:+918431842873">+91 843 184 2873</a>
            </div>

            <div className="contact_item">
              <LocationOnIcon />
              <p>
                DOOR NO : 34/1A2 , BEHIND SEASONS HOTEL , KONAPPANA AGRAHARA ,
                ELECTRONIC CITY PHASE-2 , BANGALORE-560100
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="Footer__container_sub">
        <div className="Footer__subBar container">
          <p>
            © {new Date().getFullYear()} cleaninger.in. All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
