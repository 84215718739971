import React, { useEffect, useState } from "react";
import "../../Assets/css/Comman.css";
import "../../Assets/css/Profile.css";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { PostDataAuth } from "../../ApiService/PostData";
import { apis, BASE_S3_URL, S3config } from "../../properties";
import { useToasts } from "react-toast-notifications";
import ScaleLoader from "react-spinners/ScaleLoader";
import { GetDataAuth } from "../../ApiService/GetData";
import DashboardOption from "../../Components/DashboardOption";
import AirplayIcon from "@material-ui/icons/Airplay";
import FavoriteIcon from "@material-ui/icons/Favorite";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import SettingsIcon from "@material-ui/icons/Settings";
import ImageUploader from "../PostAds/ImageUploaderApp";
import S3 from "react-aws-s3";
import Helmet from "react-helmet";

let imageList = [];
let upImagesList = [];
function Profile() {
  const ReactS3Client = new S3(S3config);
  const { addToast } = useToasts();
  const seoData = {
    title: "Setting",
    description: "Setting",
    url: window.location.href,
  };
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [removeProImage, setRemoveProImage] = useState(false);
  const [loadingTwo, setLoadingTwo] = useState(false);
  const [userData, setUserData] = useState({
    user_name: "",
    tell_number: "",
    email: "",
  });
  const [passwordData, setPasswordData] = useState({
    password: "",
    password_confirmation: "",
  });

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    GetDataAuth(apis.USER_DATA).then((result) => {
      let responseJson = result;
      setUserData(responseJson);
    });
  };

  const updateUser = (event) => {
    event.preventDefault();
    setLoading(true);
    PostDataAuth(apis.PROFILE_UPDATE, userData).then((result) => {
      let responseJson = result;
      if (responseJson.errors) {
        let errorArr = responseJson.errors;
        for (let x = 0; x < errorArr.length; x++) {
          addToast(errorArr[x].detail, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      } else {
        if (responseJson.status === "success") {
          addToast("User Update Successfully", {
            appearance: "success",
            autoDismiss: true,
          });
          window.location.reload();
        } else if (responseJson.status === "error") {
          addToast(responseJson.message, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      }
      setLoading(false);
    });
  };

  const updatePassword = (event) => {
    event.preventDefault();
    setLoadingTwo(true);
    PostDataAuth(apis.PASSWORD_CHANGE, passwordData).then((result) => {
      let responseJson = result;
      if (responseJson.errors) {
        let errorArr = responseJson.errors;
        for (let x = 0; x < errorArr.length; x++) {
          addToast(errorArr[x].detail, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      } else {
        if (responseJson.status === "success") {
          passwordData.password = "";
          passwordData.password_confirmation = "";
          addToast("Password update Successfully", {
            appearance: "success",
            autoDismiss: true,
          });
        } else if (responseJson.status === "error") {
          addToast(responseJson.message, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      }
      setLoadingTwo(false);
    });
  };

  const upImageList = (e, response) => {
    imageList = response;
  };

  const removeProfileImage = () => {
    setRemoveProImage(true);
  };

  const uploadImagesNow = () => {
    if (imageList.length > 0) {
      if (imageList.length <= 1) {
        setLoading1(true);
        uploadImageS3(imageList[0]);
      } else {
        addToast("You can choose only a 1 images. Please check again!", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } else {
      addToast(
        "There is no image to upload. Please select the images and try again",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  };

  const uploadImageS3 = (file) => {
    ReactS3Client.uploadFile(file)
      .then((data) => {
        upImagesList.push(data.key);
        setTimeout(() => {
          updateProImage();
          console.log("Cool!");
        }, 5000);
      })
      .catch((err) => {
        addToast("Image Upload error", {
          appearance: "error",
          autoDismiss: true,
        });
        console.log(err);
      });
  };

  const updateProImage = () => {
    PostDataAuth(apis.UPDATE_PROFILE_IMAGE, { image: upImagesList }).then(
      (result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          upImagesList = [];
          setLoading1(false);
          addToast("Successfully updated", {
            appearance: "success",
            autoDismiss: true,
          });
          updateSession({ img_url: responseJson.data.img_url });
          window.location.reload();
        } else {
          addToast(responseJson.message, {
            appearance: "error",
            autoDismiss: true,
          });
          setLoading1(false);
        }
      }
    );
  };

  function updateSession(value) {
    let prevData = JSON.parse(sessionStorage.getItem("userDataSet"));
    Object.keys(value).forEach(function (val, key) {
      prevData[val] = value[val];
    });
    sessionStorage.setItem("userDataSet", JSON.stringify(prevData));
  }

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{seoData.title} | Cleaninger</title>
        <meta name="description" content={seoData.description} />
        <meta property="og:title" content={seoData.title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seoData.url} />
        <link rel="canonical" href={seoData.url} />
      </Helmet>
      <div className="dashboard_menuBar container">
        <DashboardOption
          Icon={AirplayIcon}
          title="Dashboard"
          url="/dashboard"
          action={false}
        />
        <DashboardOption
          Icon={FavoriteIcon}
          title="Your Ads"
          url="/user-ads"
          action={false}
        />
        <DashboardOption
          Icon={AccountBalanceWalletIcon}
          title="Coins"
          url="/user-credits"
          action={false}
        />
        <DashboardOption
          Icon={SettingsIcon}
          title="Setting"
          url="/user-profile"
          action={true}
        />
      </div>
      <div className="container">
        <div className="profile-section">
          <div className="profile-edit-box">
            <div className="pro-title">
              <p>PROFILE IMAGE</p>
            </div>
            <div className="pro-body">
              <div className="pro-data-edit">
                {removeProImage ? (
                  <div>
                    <ImageUploader upImageList={upImageList} limit={1} />
                    <div className="up-image-dis">
                      <p>Please press the upload button.</p>
                      <div className="dis_fl">
                        <ScaleLoader
                          height={30}
                          color={"#ae3e64"}
                          loading={loading1}
                        />
                        &emsp;
                        <Button
                          className="primary__btn"
                          onClick={uploadImagesNow}
                        >
                          UPLOAD
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="pro_imgBox">
                    <div className="pro_imgPlace">
                      <img
                        src={`${BASE_S3_URL}${userData.img_url}`}
                        alt="img1"
                      />
                    </div>
                    <Button
                      className="remove__btn"
                      onClick={removeProfileImage}
                    >
                      Remove
                    </Button>
                    <p>
                      If you want to change the profile image <br /> press
                      remove button{" "}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="profile-edit-box">
            <div className="pro-title">
              <p>PROFILE CHANGE</p>
            </div>
            <div className="pro-body">
              <div className="pro-data-edit">
                <div className="profile__filds">
                  <TextField
                    label="User Name"
                    className="login__filds_sp"
                    variant="outlined"
                    value={userData.user_name}
                    onChange={(event) =>
                      setUserData({
                        ...userData,
                        user_name: event.target.value,
                      })
                    }
                  />
                </div>
                <div className="profile__filds">
                  <TextField
                    label="Contact Number"
                    className="login__filds_sp"
                    variant="outlined"
                    value={userData.tell_number}
                    onChange={(event) =>
                      setUserData({
                        ...userData,
                        tell_number: event.target.value,
                      })
                    }
                  />
                </div>
                <div className="profile__filds">
                  <TextField
                    label="Email Address"
                    className="login__filds_sp"
                    variant="outlined"
                    disabled
                    value={userData.email}
                    onChange={(event) =>
                      setUserData({ ...userData, email: event.target.value })
                    }
                  />
                </div>
                <div className="profile__btn">
                  <ScaleLoader
                    height={30}
                    color={"#ae3e64"}
                    loading={loading}
                  />{" "}
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    variant="contained"
                    className="primary__btn"
                    color="secondary"
                    onClick={updateUser}
                  >
                    CHANGE
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="profile-section">
          <div className="profile-edit-box">
            <div className="pro-title">
              <p>PASSWORD CHANGE</p>
            </div>
            <div className="pro-body">
              <div className="pro-data-edit">
                <div className="profile__filds">
                  <TextField
                    label="New Password"
                    className="login__filds_sp"
                    variant="outlined"
                    type="password"
                    value={passwordData.password}
                    onChange={(event) =>
                      setPasswordData({
                        ...passwordData,
                        password: event.target.value,
                      })
                    }
                  />
                </div>
                <div className="profile__filds">
                  <TextField
                    label="Confirm Password"
                    className="login__filds_sp"
                    variant="outlined"
                    type="password"
                    value={passwordData.password_confirmation}
                    onChange={(event) =>
                      setPasswordData({
                        ...passwordData,
                        password_confirmation: event.target.value,
                      })
                    }
                  />
                </div>
                <div className="profile__btn">
                  <ScaleLoader
                    height={30}
                    color={"#ae3e64"}
                    loading={loadingTwo}
                  />{" "}
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    variant="contained"
                    className="primary__btn"
                    color="secondary"
                    onClick={updatePassword}
                  >
                    CHANGE
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
